import React from 'react'


import blogImg from '../../assets/images/blog1.jpeg'


let data = [
  {
    image:blogImg,
    title:"Fashion Magazine",
    decrip:"What are the benefits of buying used clothing?"
},
  {
    image:blogImg,
    title:"Fashion Magazine",
    decrip:"What are the benefits of buying used clothing?"
},
  {
    image:blogImg,
    title:"Fashion Magazine",
    decrip:"What are the benefits of buying used clothing?"
},
  {
    image:blogImg,
    title:"Fashion Magazine",
    decrip:"What are the benefits of buying used clothing?"
},

]
const BlogCards = () => {
  return (
    <>
      {/* ======= BLogs Starts ======= */}
  <section>
    <div className="container">
      <div className="row loaction-card-wrap mt-2 mb-5">
        {/* <div className="row m-auto"> */}
        <div className="px-4">
          <h3 className="home_heading pt-3">Brand Awareness</h3>
          <hr />
        </div>
        {data.map((value,index)=>(
          <div className="col-md-3 col-sm-6 mb-3" key={index}>
          <div className="wrapper">
            <div className="card">
              <div className="card__img">
                <img src={value.image} className="w-100" />
              </div>
              <div className="card__content">
                <h6 className="card__title mt-3">{value.title}</h6>
                <div className="card__excerpt">
                  <h5>
                    {value.decrip}
                    <p />
                  </h5>
                </div>
              </div>
              <div className="card__link">
                <a className="button">
                  <i className="fa-regular fa-newspaper" /> Read More
                </a>
              </div>
            </div>
          </div>
        </div>
        ))}

        <div className='text-center'>
          <button className='all_blogs_btn'>All Blogs</button>
        </div>
        


      </div>
      {/* </div> */}
    </div>
  </section>
  {/* ======= BLogs Ends ======= */}

    </>
  )
}

export default BlogCards