import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Breadcrumb, BreadcrumbItem, Spinner } from "reactstrap";
import { baseURL } from "../config/apiUrl";
import { resetPasswordSchema } from "../schemas";

const SetPassword = () => {
  const { token } = useSelector((state) => state.auth);

const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const initialValues = {
    old_password: "",
    password: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
  useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, action) => {
      var data = new FormData();
      data.append("old_password", values.old_password);
      data.append("password", values.password);
      try {
        // const headers = { headers: {'Authorization': `Bearer ${token}`}};
        // const response = await axios.post(baseURL + "/changePassword",data,{headers})
        // const { message,data } = response.data;
        // toast.success(message);
        var config = {
          method: 'post',
          url: baseURL + '/changePassword',
          headers: { 
            'Authorization': `Bearer ${token}`, 
          },
          data : data
        };
        setLoading(true)
       await axios(config)
        .then(function (response) {
           toast.success(response.data.message);
        setLoading(false)
           navigate("/")
        })
      } catch (error) {
        toast.error(error.response.data.message);
        setLoading(false)
      }
    },
  });

  return (
    <>
      <section className="container-fluid products_main_banner">
        <div className="container">
          <div className="banner_content">
            <h4>Change Password</h4>
            <div>
              <Breadcrumb>
                <BreadcrumbItem>
                  <a href="#">Home</a>
                </BreadcrumbItem>
                <BreadcrumbItem active>My Account</BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </section>
      <section className="container mt-5">
          <form onSubmit={handleSubmit}>
        <div className="col-md-5 m-auto text-center">
          <p className="mb-3">Enter a new password below.</p>
          <div className="form-floating">
            <input
              type="password"
              className="form-control mb-4"
              id="old_password"
              name="old_password"
              value={values.old_password}
              onChange={handleChange}
              onBlur={handleBlur}
              // placeholder="Old New old_Password"
            />
             {errors.old_password && touched.old_password ? (
                <p className="form-error mb-2">{errors.old_password}</p>
              ) : null}
            <label for="floatingPassworda">Old Password</label>
          </div>
          <div className="form-floating">
            <input
              type="password"
              className="form-control mb-4"
              id="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              // placeholder="New Password"
            />
             {errors.password && touched.password ? (
                <p className="form-error mb-2">{errors.password}</p>
              ) : null}
            <label for="floatingPassword">New Password</label>
          </div>
          <button className="set-pass-save" type="submit" disabled={loading?true:false}>
                        {loading?  <Spinner color="white"/> : " Save"}  
                        </button>
          {/* <button type="submit" className="set-pass-save">Save</button> */}
        </div>
        </form>
      </section>
    </>
  );
};

export default SetPassword;
