import { createSlice } from '@reduxjs/toolkit'

const initialStateValue = {
  currency_id:"",
  currency_name:""
}

export const currencySlice = createSlice({
 name: 'currency',
 initialState: initialStateValue,
 reducers: {
      getCurrency: (state,action) =>{
          state.currency_id = action.payload.id;
          state.currency_name = action.payload.abbreviation;
          // state.address = action.payload.address;
      },

    //   logout:(state) =>{
    //     state.currency_id = "";
    //     state.currency_id = "";
    //     // state.address = "";
    //   },
    //   isAdminn: (state, action) => {
    //       state.admin = action.payload
    //    },
    //    userDetail: (state, action) => {
    //     state.user = action.payload
    //  },
    },
})


// Action creators are generated for each case reducer function
export const { getCurrency } = currencySlice.actions

export default currencySlice.reducer

