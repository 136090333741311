import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import haji_logo from "../assets/images/haji-logo-modal.jpg";
import { baseURL } from "../config/apiUrl";
import { login } from "../redux/features/auth/authSlice";
import { signUpSchema } from "../schemas";

const LoginModal = () => {
  
  const [signUpToggle, setsignUpToggle] = useState("");
const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
const navigate = useNavigate()

  const submitForm = async (e) => {
    e.preventDefault();
    var userData = new FormData();
    userData.append("email", email);
    setLoading(true);
    try {
      const response = await axios.post(baseURL + "/register",userData)
      const { message,data } = response.data;
      dispatch(login(data));
      toast.success(message);
      setLoading(false);
      document.getElementById('closeButton').click()
      navigate("/")
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };
  const initialValues = {
    email: "",
    password: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
  useFormik({
    initialValues,
    validationSchema: signUpSchema,
    onSubmit: async (values, action) => {
      var userData = new FormData();
      userData.append("email", values.email);
      userData.append("password", values.password);

      setLoading(true);
      try {
        const response = await axios.post(baseURL + "/login",userData)
        const { message,data } = response.data;
        dispatch(login(data));
        toast.success(message);
        setLoading(false);
        document.getElementById('closeButton').click()
      } catch (error) {
        toast.error(error.response.data.message);
        setLoading(false);
      }
    },
  });
  return (
    <div>
      <div>
        {/* Modal */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="w-100 text-center position-relative">
                  <img src={haji_logo} height={45} width={60} alt="" />
                  <button
                    type="button"
                    className="btn-close modal_close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="closeButton"
                    // onClick={() => {closeRef.current.click()}}
                  />
                </div>
              </div>
              <div className="modal-body">
                <div className="login__maiN_">
                  <div className={`form-section ${signUpToggle}`}>
                    <div className="login-box">
                      <form onSubmit={handleSubmit}>
                      <div className=" login__">
                        <h4 className="text-center mb-3">
                          Great to have you back!
                        </h4>
                        <div className="mb-3">
                          <label
                            htmlFor="email"
                            className="form-label"
                          >
                            Username or email{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="name@example.com"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                                  {errors.email && touched.email ? (
                              <p className="form-error">{errors.email}</p>
                            ) : null}
                        </div>
                        <div className="mb-3">
                          <div className="d-flex justify-content-between">
                            <label
                              htmlFor="exampleFormControlInput2"
                              className="form-label"
                            >
                              Password <span style={{ color: "red" }}>*</span>
                            </label>
                            <label
                              htmlFor="password"
                              className="form-label"
                            >
                              Lost?
                            </label>
                          </div>
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="*****"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.password && touched.password ? (
                              <p className="form-error">{errors.password}</p>
                            ) : null}
                        </div>
                        <button className="sign_blue_btn" type="submit" disabled={loading?true:false}>
                        {loading?  <Spinner color="white"/> : " SIGN IN TO YOUR ACCOUNT"}  
                        </button>
                        <div className="text-center mt-3">
                          <p>
                            Not a member?{" "}
                            <span
                              onClick={() => {
                                setsignUpToggle("form-section-move");
                              }}
                            >
                              <a href="#">Create an account</a>
                            </span>
                          </p>
                        </div>
                      </div>
                      </form>
                    </div>
                    <div className="signup-box">
                      <form onSubmit={submitForm}>
                      <div className=" sign_up_">
                        <h4 className="text-center mb-3">
                          Great to have you back!
                        </h4>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlInput3"
                            className="form-label"
                          >
                            Email address{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="name@example.com"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                        <p className="text-center mb-2">
                          A password will be sent to your email address.
                        </p>
                        <p className="text-center mb-3">
                          Your personal data will be used to support your
                          experience throughout this website, to manage access
                          to your account, and for other purposes described in
                          our privacy policy.
                        </p>
                        <button className="sign_blue_btn" type="submit" disabled={loading?true:false}>
                          {loading?  <Spinner color="white"/> : "SETUP YOUR ACCOUNT"}  
                        </button>
                        <div className="text-center mt-3">
                          <p>
                            Already got an account?{" "}
                            <span
                              onClick={() => {
                                setsignUpToggle("");
                              }}
                            >
                              <a href="#">Sign in here</a>
                            </span>
                          </p>
                        </div>
                      </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
