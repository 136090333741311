import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, getTotals } from "../../redux/features/slices/cartSlice";
import { imageUrl } from "../../config/apiUrl";
import { Link } from "react-router-dom";
import { addToWishlist } from "../../redux/features/wishlist/wishlistSlice";
import { addToCompareCart } from "../../redux/features/compare/compareProductSlice";
import { addToView } from "../../redux/features/detailView/detailViewSlice.js";
import ProductView from "../ProductView";
import CartToggle from "../CartToggle";

const ProductCard = ({ products }) => {
  const { currency_name } = useSelector((state) => state.currency);
  const dispatch = useDispatch();
  const [cartShow, setcartShow] = useState("sidebar__Product_view_act");
  const [closeBtnStyle, setcloseBtnStyle] = useState("close_btn_tra");
  const [overlayS, setoverlayS] = useState("overlay_slider_a");

  const [cartShowToggle, setcartShowToggle] = useState("sidebar__act");


  const ProductQuickView = () => {
    if (cartShow === "sidebar__Product_view_act") {
      setcartShow("sidebar__Product_view");
      setcloseBtnStyle("close_btn");
      setoverlayS("overlay_slider");
    } else {
      setcartShow("sidebar__Product_view_act");
      setcloseBtnStyle("close_btn_tra");
      setoverlayS("overlay_slider_a");
    }
  };

  const cartOpen = () => {
    if (cartShowToggle === "sidebar__act") {
      setcartShowToggle("sidebar__");
      setcloseBtnStyle("close_btn");
      setoverlayS("overlay_slider");
    } else {
      setcartShowToggle("sidebar__act");
      setcloseBtnStyle("close_btn_tra");
      setoverlayS("overlay_slider_a");
    }
  };



  let handleAddToCart = (product) => {
    let data = {
      ...product,
    };
    dispatch(addToCart(data));
    dispatch(getTotals());
    cartOpen()
  };

  let handleAddToWishlist = (product) => {
    let data = {
      ...product,
    };
    dispatch(addToWishlist(data));
  };

  let handleAddToCompare = (product) => {
    let data = {
      ...product,
    };
    dispatch(addToCompareCart(data));
  };
  let handleAddToView = (product) => {
    let data = {
      ...product,
    };
    dispatch(addToView(data));
    ProductQuickView();
  };
  const { wishItems } = useSelector((state) => state.wishlist);
  return (
    <>
      {products &&
        products.map((value, index) => {
          const discount = ((value.price - value.discount_price) / value.price) * 100;
          const amount =
            value.discount_price === ""
              ? parseFloat(value.price).toFixed(2)
              : parseFloat(value.discount_price).toFixed(2);
          return (
            <div className="col-xs-6 col-sm-4 col-6 col-lg-3 hgh mb-2" key={index}>
              <figure className="prod-box">
                <i
                  className={
                    wishItems.some((item) => item.id === value.id) ? "fa-solid fa-heart heart_active  wish_list_heart" : "fa-regular fa-heart wish_list_heart"
                  }
                  onClick={() => handleAddToWishlist(value)}
                />
                <div className="badges-wrap">
                {value.is_new == 0 && <span className="new_badge">NEW</span> }
                {value.is_featured === "1" && <span className="new_badge">FEATURED</span> }
                {value.stock == 0 && <span className="new_badge out_stock_badge">SOLD OUT</span> }
                {value.discount_price !== "" && <span className="new_badge">{parseInt(discount)} %</span> }
                </div>
                <Link
                  to={`/shop/${value.main_cat_slug}/${value.cat_slug}/${value.slug}`}
                  state={{ data: value }}
                >
                  <div className="img-holder">
                    <img
                     src={imageUrl + `/${value.image && value.image[0].image}`}
                      // src="https://hajiukayukay.com/wp-content/uploads/2022/05/ADULT-SWEATSHIRT-HOODED-450x575.jpg"
                      className="item-img-1"
                      alt="product-image"
                    />
                    <img
                       src={imageUrl + `/${value.image[1]?value.image[1].image:value.image[0].image}`}
                      // src="https://static-01.daraz.pk/p/936d97a99de4ef01dc41bfb95335fbca.jpg"
                      className="item-img-2"
                      alt="product-image2"
                    />
                  </div>
                </Link>

                {/* img-holder// */}

                <figcaption className="anons">
                  <Link
                    to={`/shop/${value.main_cat_slug}/${value.cat_slug}/${value.slug}`}
                    state={{ data: value }}
                  >
                    <h5>{value.name}</h5>
                  </Link>

                  <p>
                    {value.discount_price !== "" && (
                      <del className="me-3">
                        {currency_name} {value.price}
                      </del>
                    )}
                    {currency_name} {amount}
                  </p>
                </figcaption>

                <div className="eye_compare">
                  <div>
                    <div>
                      <i
                        className="fa-solid fa-eye eye__Prod mb-2"
                        onClick={() => handleAddToView(value)}
                      ></i>
                      <i
                        className="fa-solid fa-arrows-rotate eye__Prod"
                        onClick={() => handleAddToCompare(value)}
                      ></i>
                    </div>
                  </div>
                </div>
                <div className="cart__">
                  {/* <i className='fa-solid fa-bag-shopping'  onClick={() =>
                                   handleAddToCart(value)}></i> */}
                  <Link
                    onClick={() => handleAddToCart(value)}
                    className="a_main__"
                  >
                    <span className="add_to_cart_span">ADD TO CART</span>
                    <i className="fa-solid fa-bag-shopping shop__bag__pro"></i>
                  </Link>
                </div>
              </figure>
            </div>
          );
        })}

       <ProductView
        cartShow={cartShow}
        ProductQuickView={ProductQuickView}
        closeBtnStyle={closeBtnStyle}
        overlayS={overlayS}
      />

        {/* -------Card_Slider--Start---- */}
        <CartToggle
        cartShow={cartShowToggle}
        cartOpen={cartOpen}
        closeBtnStyle={closeBtnStyle}
        overlayS={overlayS}
      />
      {/* -------Card_Slider--end---- */}

    </>
  );
};

export default ProductCard;
