import React from "react";
import DashbordSidebar from "../../components/DashbordSidebar";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";

const Downloads = () => {
  return (
    <section>
      <section className="container-fluid products_main_banner">
        <div className="container">
          <div className="banner_content">
            <h4>Downloads</h4>
            <div>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/my-account">My account</Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-4 mb-4">
        <div className="container">
          <div className="row">
            <DashbordSidebar />
            <div className="col-md-9">
              <div className="billing_shipping_main ">
                <div className="d-flex align-items-center">
                  <button className="saveChanges brow__">Browse products</button>
                  <p>No downloads available yet.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Downloads;
