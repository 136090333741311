import React, { useEffect, useState } from "react";
import DashbordSidebar from "../../components/DashbordSidebar";
import { Breadcrumb, BreadcrumbItem, Spinner } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../config/apiUrl";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { editBillingSchema } from "../../schemas";

const EditAddressBilling = () => {
  const { token, user_id, name } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const navigate = useNavigate();
  const [getState, setGetState] = useState([]);

  const [CurrencyOptions, setCurrencyOptions] = useState([]);
  const fetchCurrency = async () => {
    try {
      const response = await axios.get(baseURL + "/allCurrency");
      const { data } = response.data;
      setCurrencyOptions(data);
    } catch (error) {}
  };

  const fetchState = async () => {
    try {
      const response = await axios.get(
        baseURL + `/getStatesByCurrencyId/${values.country_id}`
      );
      const { data } = response.data;
      setGetState(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchCurrency();
  }, []);


  const fetchBillingData = async () => {
    try {
      var config = {
        method: "get",
        url: baseURL + `/getbillingaddress/${user_id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios(config).then(function(response) {
        const { data } = response.data;
        setData(data);
      });
    } catch (error) {}
  };
  useEffect(() => {
    fetchBillingData();
  }, []);

  const initialValues = {
    firstname: (data && data.firstname) || "",
    lastname: (data && data.lastname) || "",
    phone: (data && data.phone) || "",
    email: (data && data.email) || "",
    country_id: (data && data.country_id) || "",
    street_address: (data && data.street_address) || "",
    apartment: (data && data.apartment) || "",
    state_id: (data && data.state_id) || "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: editBillingSchema ,
    onSubmit: async (values, action) => {
      var data = new FormData();
      data.append("firstname", values.firstname);
      data.append("lastname", values.lastname);
      data.append("phone", values.phone);

      data.append("email", values.email);
      data.append("country_id", values.country_id);
      data.append("street_address", values.street_address);
      data.append("apartment", values.apartment);
      data.append("state_id", values.state_id);

      setLoading(true);
      try {
        var config = {
          method: "post",
          url: baseURL + `/storebillingaddress/${user_id}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: data,
        };
        await axios(config).then(function(response) {
          const { data, message } = response.data;
          toast.success(message);
          setTimeout(() => {
            navigate("/addresses");
            setLoading(false);
          }, 2000);
        });
      } catch (error) {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (values.country_id) {
      fetchState();
    }
  }, [values.country_id]);
  return (
    <section>
      <section className="container-fluid products_main_banner">
        <div className="container">
          <div className="banner_content">
            <h4>Addresses</h4>
            <div>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/my-account">My account</Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-4 mb-4">
        <div className="container">
          <div className="row">
            <DashbordSidebar />
            <div className="col-md-9">
              <div>
                <div className="billing_shipping_main">
                  <h4 className="mb-2">Billing address</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="name@example.com"
                            id="firstname"
                            name="firstname"
                            value={values.firstname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label for="firstname">First Name*</label>
                          {errors.firstname && touched.firstname ? (
                          <p className="form-error">{errors.firstname}</p>
                        ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="name@example.com"
                            id="lastname"
                            name="lastname"
                            value={values.lastname}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label for="lastname">Last Name*</label>
                          {errors.lastname && touched.lastname ? (
                          <p className="form-error">{errors.lastname}</p>
                        ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="phone"
                            id="phone"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label htmlFor="phone">Phone*</label>
                          {errors.phone && touched.phone ? (
                          <p className="form-error">{errors.phone}</p>
                        ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="name@example.com"
                            id="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label for="email">Email Address*</label>
                          {errors.email && touched.email ? (
                          <p className="form-error">{errors.email}</p>
                        ) : null}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-floating mb-3 position-relative">
                          <select
                            className="form-select"
                            id="country_id"
                            name="country_id"
                            aria-label="Floating label select example"
                            value={values.country_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                             <option value={0} selected>
                              Choose Country
                            </option>
                            {CurrencyOptions.map((val) => (
                              <option value={val.id}>{val.country}</option>
                            ))}
                          </select>
                          <label for="country_id">Country/Region*</label>
                          {errors.country_id && touched.country_id ? (
                          <p className="form-error">{errors.country_id}</p>
                        ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            id="street_address"
                            name="street_address"
                            value={values.street_address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label for="street_address">
                            House number and street name *
                          </label>
                          {errors.street_address && touched.street_address ? (
                          <p className="form-error">{errors.street_address}</p>
                        ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="appartment"
                            id="apartment"
                            name="apartment"
                            value={values.apartment}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label htmlFor="apartment">
                            Apartment, suite, unit, etc. (optional)*
                          </label>
                          {/* {errors.apartment && touched.apartment ? (
                          <p className="form-error">{errors.apartment}</p>
                        ) : null} */}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-floating mb-3 position-relative">
                          <select
                            className="form-select"
                            id="state_id"
                            aria-label="Floating label select example"
                            name="state_id"
                            value={values.state_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value={0} selected>
                              choose state
                            </option>
                            {getState.map((val) => (
                              <option value={val.id}>{val.name}</option>
                            ))}
                          </select>
                          <label htmlFor="state_id">State / County</label>
                          {errors.apartment && touched.apartment ? (
                          <p className="form-error">{errors.apartment}</p>
                        ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div>
                          <button
                            className="saveChanges brow__"
                            type="submit"
                            disabled={loading ? true : false}
                          >
                            {loading ? (
                              <Spinner color="white" />
                            ) : (
                              "Save address"
                            )}
                          </button>
                        </div>
                      </div>
                      {/* <button type="submit">submit</button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default EditAddressBilling;
