import React from "react";

const Refund = () => {
  return (
    <>
      <section>
        <div className="container mt-3">
          <div style={{fontSize:"14px"}}>
            <h3>RETURN POLICY</h3>
            <h4 className="mb-3">Return</h4>
            <p>Thanks for shopping at Haji Ukay Ukay.</p>
            <p className="mb-3">
              If you’re not completely satisfied with your purchase, we’re here
              to help.
            </p>
            <p className="mb-3">
              You can return your items within three (3) days of receipt of
              shipment; else, we can’t offer refunds or exchanges anymore. Once
              your return request has been confirmed, the courier will pick up
              the items within five working days.
            </p>
            <p className="mb-3">
              Return items must conform to the following requirements:
            </p>
            <p className="mb-3">
              The item must remain its weight or pieces as-is. A reduction in
              the weight of the bundle or pieces will result in disapproval of
              your return, and nothing should be taken. We do cover the delivery
              charges for returns. The item must be wrapped in a sack/box and
              include the label (Color Green Haji sticker).
            </p>
            <h4 className="mb-3">Return Request</h4>
            <p className="mb-3">
              You can send a request through email or via Whatsapp. The return
              request must specify the conditions of the bundle and include a
              picture of the label (Color Green Haji sticker) or short video
              clips.
            </p>
            <h4 className="mb-3">Refund</h4>
            <p className="mb-3">
              Once we receive your returned item, it will undergo a quality
              check and notify you via email that we’ve received your returned
              item. Shipping fees are non-refundable and will only refund the
              item amount.
            </p>
            <p className="mb-3">
              If the return is approved, these options are available for a
              refund; credit/debit card, bank transfer, and cash collection from
              our office. A credit/debit card may take 14 to 30 working days to
              be credited to your account.
            </p>

            <h4 className="mb-3">Items on Sale</h4>
            <p className="mb-3">
              Unfortunately, we won’t be able to offer refunds for sale items.
            </p>

            <h4 className="mb-3">Exchanges</h4>
            <p className="mb-3">
              If the delivered items are wrong, we can replace the items free of
              charge. Please get in touch with us within three (3) days of
              receipt of the items.
            </p>
            <p className="mb-3">
              If you have any questions regarding returns, exchanges, or
              refunds, please feel free to reach out to us at:
            </p>
            <p className="mb-3">
              Email: support@hajiukayukay.com <br/>Phone: +971 65461180
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Refund;
