import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Bori from "../assets/images/bori.jpg";
import { removeFromWishlistCart } from '../redux/features/wishlist/wishlistSlice';
import { addToCart, getTotals } from '../redux/features/slices/cartSlice';
import { imageUrl } from '../config/apiUrl';

const WishlistToggle = ({wishListOpen,closeBtnwStyle,overlayS,wishlistOpen}) => {
  const { currency_id, currency_name } = useSelector((state) => state.currency);
    const { wishItems, wisthTotalQuantity } = useSelector((state) => state.wishlist);

    const dispatch = useDispatch();
    const handleRemoveFromCart = (product) => {
      dispatch(removeFromWishlistCart(product));
    };
    let handleAddToCart = (product) => {
      let data = {
        ...product,
      };
      dispatch(addToCart(data));
      dispatch(getTotals());
    };
  return (
    <>
      <section>
        <div className={wishListOpen}>
          <div className="container">
            <div className={closeBtnwStyle} onClick={wishlistOpen}>
              <i className="fa-solid fa-chevron-right"></i>
            </div>
            <h3 className="text-center mt-4">Wishlist</h3>
            <div className="px-4 ">
              <div className="wishlist__main_scr">
                {wishItems.length === 0 && (
                  <div className="text-center mt-5">
                    <i className="fa-regular fa-heart no_cart_bag" />
                    <p className="no_pro__p my-3">
                      No products in the wishlist.
                    </p>
                    <button className="check_out_btn" onClick={wishlistOpen}>
                      RETURN TO SHOP
                    </button>
                  </div>
                )}

                {wishItems.map((value, index) => {
                  const amount =
                    value.discount_price === ""
                      ? parseInt(value.price)
                      : parseInt(value.discount_price);
                  return (
                    <div
                    className="cart_data__ mb-3 d-flex justify-content-around"
                    key={index}
                  >
                    <img src={imageUrl + `/${value.image[0].image}`} />
                    
                    <span className="shop_del_item shop__del_two"  onClick={() => handleRemoveFromCart(value)}>
                      <i
                        className="fa-solid fa-xmark"
                       
                      ></i>
                    </span>
                    <div>
                      <p className="ms-2 mb-2">{value.name}</p>

                      <p className="ms-2">{currency_name} {amount} —— {value.stock} in stock</p>
                      <button className="ms-2 wish_add_cart_btn"  onClick={() => handleAddToCart(value)}>ADD TO CART</button>
                    </div>
                  </div>
                  );
                })}
                    
              </div>
            </div>
          </div>
        </div>
        <div className={overlayS}></div>
      </section>
    </>
  )
}

export default WishlistToggle