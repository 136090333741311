import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Progress } from "reactstrap";
import bori from "../assets/images/bori.jpg";
import { addToCart, getTotals } from "../redux/features/slices/cartSlice";
import USLogo from "./../assets/images//us.png";
import { baseURL, imageUrl } from "../config/apiUrl";
import axios from "axios";
import Loader from "../components/Loader";
import ProductCard from "../components/cards/ProductCard";

const ProductDetails = () => {
  const { state } = useLocation();
  const { currency_name, currency_id } = useSelector((state) => state.currency);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(state.data);
  const [products, setProducts] = useState([]);
  const [activeLink, setActiveLink] = useState(null);
  let [num, setNum] = useState(1);
  
  const handleLinkClick = (linkId) => {
    setActiveLink(linkId);
  }

  const dispatch = useDispatch();

  const handleIncrement = () => {
    if (num < 20) {
      setNum(Number(num) + 1);
    }
  };
  const handleDecrement = () => {
    if (num > 1) {
      setNum(Number(num) - 1);
    }
  };

  let handleChange = (e) => {
    setNum(e.target.value);
  };
  let handleAddToCart = (product, num) => {
    let data = {
      ...product,
      num,
    };
    dispatch(addToCart(data));
    dispatch(getTotals());
    setNum(1);
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        baseURL + `/getRelatedProducts/${currency_id}/${product.id}`
      );
      const { data } = response.data;
      setProducts(data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchProducts();
  }, [state, currency_id]);
  useEffect(() => {
    setProduct(state.data);
  }, [state]);

  const navigate = useNavigate()
  let handleBuyNow = (product,num) => {
    let data = {
      ...product,
      num,
    };
    dispatch(addToCart(data));
    dispatch(getTotals());
    setNum(1);
    navigate("/checkout")
  };

  const tags = product.tags;

  const words = tags.split(',').map(tag => tag.trim());
  
  return (
    <>
      <section className="container-fluid products_main_banner">
        <div className="container">
          <div className="banner_content">
            <h4>{product.name}</h4>
            <div>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                {/* <BreadcrumbItem>
                  <a href="#">Shop</a>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <a href="#">Women</a>
                </BreadcrumbItem>
                <BreadcrumbItem active>Women Bottoms</BreadcrumbItem> */}
              </Breadcrumb>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid">
        <div>
          <div className="row">
            <div className="col-md-6">
              <div className="product_details_img">
                <img src={imageUrl + `/${product.image[0].image}`} className="w-100" height={450} alt="bori" />
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <h2 className="mt-4">{product.name}</h2>
                {/* <div className="d-flex align-items-center col-md-4">
                  <img
                    className="me-2"
                    src={USLogo}
                    height={20}
                    width={30}
                    alt=""
                  />
                  <select
                    className="form-select form-select-sm"
                    aria-label=".form-select-sm example"
                    style={{ border: "none" }}
                  >
                    <option selected="">United State</option>
                    <option value={1}>One</option>
                    <option value={2}>Two</option>
                    <option value={3}>Three</option>
                  </select>
                </div> */}
                  {product.discount_price !== "" && (
                <h3 className="mt-3">
                    <del className="me-3">
                      {currency_name} {product.price}
                    </del>
                  
                  {currency_name} {product.discount_price}
                </h3>
                  )}
                  {product.discount_price === "" && (
                <h3 className="mt-3">
                  {currency_name} {product.price}
                </h3>
                  )}
                <div className="product__details_cont">
                  <p>
                    <span className="pro_det_spa">QUALITY:</span>{" "}
                    {product.quality}
                  </p>
                  <p>
                    <span className="pro_det_spa">WEIGHT:</span>{" "}
                    {product.weight}
                  </p>
                  <p>
                    <span className="pro_det_spa">SIZE:</span> {product.size}
                  </p>
                  <p>
                    <span className="pro_det_spa">STICKER NAME:</span>{" "}
                    {product.sticker_name}
                  </p>
                  <p>APPROX {product.pieces} PCS INSIDE THE BUNDLE</p>
                </div>
                <div>
                  <p className="mb-1">
                    Only {product.stock} item(s) left in stock.
                  </p>
                  <Progress value={product.stock} className="progre" />
                </div>
                <div className="add_to_cart_main">
                  <div className="quantity">
                    <input
                      disabled
                      value={num}
                      onChange={handleChange}
                      type="text"
                    />
                    <a className="plus" onClick={handleIncrement}>
                      +
                    </a>
                    <a className="minus" onClick={handleDecrement}>
                      -
                    </a>
                  </div>
                  <button
                    className="add_to_cart"
                    onClick={() => handleAddToCart(product, num)}
                  >
                    ADD TO CART
                  </button>
                  <button className="buy_now" onClick={() => handleBuyNow(product, num)}>BUY NOW</button>
                </div>
                <div className="my-3">
                  <div className="size__del flex-wrap">
                    <p className="me-md-5 me-2">
                      <span className="me-3">
                        <i className="fa-solid fa-book"></i>
                      </span>
                      <span
                      
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal3"
                        
                      >
                        Size Guide
                      </span>
                    </p>
                    <p className="me-md-5 me-2">
                      <span className="me-3">
                        <i className="fa-solid fa-rotate-left"></i>
                      </span>
                      <span
                      
                        data-bs-toggle="modal"
                        data-bs-target="#delivery_return"
                        
                      >
                        Delivery & Return
                      </span>
                    </p>
                    <p className="">
                      <span className="me-3">
                        <i className="fa-regular fa-circle-question"></i>
                      </span>
                      Ask a Question
                    </p>
                  </div>
                </div>
                <div className="d-flex">
                  <p>
                    <i className="fa-solid fa-share-nodes me-2"></i>Share
                  </p>
                  <div className="ms-5">
                    <i className="fa-brands fa-twitter me-4"></i>
                    <i className="fa-brands fa-facebook me-4"></i>
                    <i className="fa-brands fa-pinterest me-4"></i>
                    <i className="fa-regular fa-envelope"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container my-5">
        <div className="d-flex justify-content-center">
          <h2 className="me-4">Description</h2>
          {/* <h2>Reviews (0)</h2> */}
        </div>
        <p className="mb-1 fw-bold">{product.name}</p>
        <p className="mb-2 pro__description___">{product.description}</p>
        <div>
          <iframe
            width="100%"
            height="675"
            src={product.video_link.startsWith("https")?product.video_link:"https://www.youtube.com/embed/fy5HzrZnYD8"}
            title="Haji Brand"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          {/* <iframe
            loading="lazy"
            title="Haji Brand Ladies Night Pajama/Pyjama/Pants"
            width="1200"
            height="675"
            src={product.video_link}
            // src="https://www.youtube.com/watch?v=fUGVQteFUUo"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen=""
          ></iframe> */}
        </div>
      </section>
      <section className=" container sku_and_categories_main mb-3">
        <div>
          <p>
            <span className="fw-bold">SKU: {product.sku}</span> /{" "}
            <span className="fw-bold">Categories: &nbsp;</span>
            {words.map((value,index)=>{
              // const routeLink =value.trim().split(' ')[0].toLowerCase();
              return(
            <span className="orderNo" key={index}>{value} ,</span>
            )})}
            {/* <span className="orderNo"> New Items</span> */}
          </p>
        </div>
      </section>
      <section className="container">
        <div className="d-flex justify-content-center">
          <h2>Related Products</h2>
        </div>
        <div className="row">
          {products.length === 0 && (
            <h4 className="py-5 text-center">No Related Product Found</h4>
          )}
          <ProductCard products={products} />
        </div>
      </section>
      {/* -------Size-Guide-Modal-Start------------ */}
      <div className="super_main_guide">
        {/* Modal */}
        <div
          className="modal fade"
          id="exampleModal3"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg ">
            <div className="modal-content">
              <div className="modal-header">
                {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div>
                  <h3 className="text-center mb-3">Size Guide</h3>
                  <div>
                    {/* ---tab-S-- */}
                    <div>
                      <ul className="size_guide_ul" id="myTab" role="tablist">
                        <li role="presentation">
                          <span
                          
                            className="active"
                            id="dresses-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#dresses"
                            type="button"
                            role="tab"
                            aria-controls="dresses"
                            aria-selected="true"
                          
                          >
                            DRESSES
                          </span>
                        </li>
                        <li role="presentation">
                          <span

                            id="tshirt-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#tshirt"
                            type="button"
                            role="tab"
                            aria-controls="tshirt"
                            aria-selected="false"
                     
                          >
                            T-SHIRT
                          </span>
                        </li>
                        <li role="presentation">
                          <span
                        
                            id="bottoms-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#bottoms"
                            type="button"
                            role="tab"
                            aria-controls="bottoms"
                            aria-selected="false"
                        

                          >
                            BOTTOMS
                          </span>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="dresses"
                          role="tabpanel"
                          aria-labelledby="dresses-tab"
                        >
                          <div>
                            <table className="table table_main__">
                              <thead>
                                <tr>
                                  <th>SIZE</th>
                                  <th>CHEST</th>
                                  <th>WAIST</th>
                                  <th>HIPS</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>XS</td>
                                  <td>34</td>
                                  <td>28</td>
                                  <td>34</td>
                                </tr>
                                <tr>
                                  <td>S</td>
                                  <td>36</td>
                                  <td>30</td>
                                  <td>36</td>
                                </tr>
                                <tr>
                                  <td>M</td>
                                  <td>38</td>
                                  <td>32</td>
                                  <td>38</td>
                                </tr>
                                <tr>
                                  <td>L</td>
                                  <td>40</td>
                                  <td>34</td>
                                  <td>40</td>
                                </tr>
                                <tr>
                                  <td>XL</td>
                                  <td>42</td>
                                  <td>36</td>
                                  <td>42</td>
                                </tr>
                                <tr>
                                  <td>2XL</td>
                                  <td>44</td>
                                  <td>38</td>
                                  <td>44</td>
                                </tr>
                                <tr>
                                  <td colSpan={4} className="last_td_main">
                                    <div>
                                      <p className="measure">
                                        All measurements are in INCHES
                                      </p>
                                      <p className="measure">
                                        and may vary a half inch in either
                                        direction.
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="tshirt"
                          role="tabpanel"
                          aria-labelledby="tshirt-tab"
                        >
                          <div>
                            <table className="table table_main__">
                              <thead>
                                <tr>
                                  <th>SIZE</th>
                                  <th>CHEST</th>
                                  <th>WAIST</th>
                                  <th>HIPS</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>2XS</td>
                                  <td>32</td>
                                  <td>26</td>
                                  <td>32</td>
                                </tr>
                                <tr>
                                  <td>XS</td>
                                  <td>34</td>
                                  <td>28</td>
                                  <td>34</td>
                                </tr>
                                <tr>
                                  <td>S</td>
                                  <td>36</td>
                                  <td>30</td>
                                  <td>36</td>
                                </tr>
                                <tr>
                                  <td>M</td>
                                  <td>38</td>
                                  <td>32</td>
                                  <td>38</td>
                                </tr>
                                <tr>
                                  <td>L</td>
                                  <td>40</td>
                                  <td>34</td>
                                  <td>40</td>
                                </tr>
                                <tr>
                                  <td>XL</td>
                                  <td>42</td>
                                  <td>36</td>
                                  <td>42</td>
                                </tr>
                                <tr>
                                  <td colSpan={4} className="last_td_main">
                                    <div>
                                      <p className="measure">
                                        All measurements are in INCHES
                                      </p>
                                      <p className="measure">
                                        and may vary a half inch in either
                                        direction.
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="bottoms"
                          role="tabpanel"
                          aria-labelledby="bottoms-tab"
                        >
                          <div>
                            <table className="table table_main__">
                              <thead>
                                <tr>
                                  <th>SIZE</th>
                                  <th>CHEST</th>
                                  <th>WAIST</th>
                                  <th>HIPS</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>XS</td>
                                  <td>34</td>
                                  <td>28</td>
                                  <td>34</td>
                                </tr>
                                <tr>
                                  <td>S</td>
                                  <td>36</td>
                                  <td>30</td>
                                  <td>36</td>
                                </tr>
                                <tr>
                                  <td>M</td>
                                  <td>38</td>
                                  <td>32</td>
                                  <td>38</td>
                                </tr>
                                <tr>
                                  <td>L</td>
                                  <td>40</td>
                                  <td>34</td>
                                  <td>40</td>
                                </tr>
                                <tr>
                                  <td>XL</td>
                                  <td>42</td>
                                  <td>36</td>
                                  <td>42</td>
                                </tr>
                                <tr>
                                  <td>2XL</td>
                                  <td>44</td>
                                  <td>38</td>
                                  <td>44</td>
                                </tr>
                                <tr>
                                  <td colSpan={4} className="last_td_main">
                                    <div>
                                      <p className="measure">
                                        All measurements are in INCHES
                                      </p>
                                      <p className="measure">
                                        and may vary a half inch in either
                                        direction.
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -------Size-Guide-Modal-Start------------ */}

      {/* -------Delivery-Return-Modal-Start------------ */}
      <div className="super_main_guide">
        {/* Modal */}
        <div
          className="modal fade"
          id="delivery_return"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg ">
            <div className="modal-content">
              <div className="modal-header">
                {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="px-5">
                  <h3>Delivery</h3>
                  <p className="delivery_pss mb-2">
                    We deliver within the UAE with the exception of the far
                    region, which is beyond the boundaries of the courier
                    delivery zone. The delivery cost is 15 AED incl of VAT per
                    bundle. Expected delivery date or time shown on our website
                    is based on an estimate or inaccurate. This is due to a
                    third-party factor that may cause delays. It is unlikely
                    that the packaging of the delivered items will be damaged
                    and this may occur during transit. Shipping fee to Bahrain,
                    Oman and Philippines are subject to delivery rates. Please
                    refer to T&C.
                  </p>
                  <h3>Return</h3>
                  <p className="delivery_pss mb-2">
                    If you are dissatisfied with the order, you may return the
                    items within 3 days after delivery. Return items must
                    conform to the following requirements: The item must remain
                    its weight or pieces as-is. A reduction in weight of the
                    bundle or pieces of items inside the bundle will result in
                    disapproval of your return, nothing should be taken. We do
                    cover the delivery charges for returns. The item must be
                    wrapped in a sack/box and include the label (Color Green
                    Haji sticker). Please refer to our Refund and Returns.
                  </p>
                  <h3>Refund</h3>
                  <p className="delivery_pss mb-2">
                    Once we have received the returned items, it will be subject
                    to a quality check before the refund is processed. It will
                    take 14 to 30 working days to be credited to your wallet or
                    account depending on which refund option you choose.
                  </p>
                  <h3>Help</h3>
                  <p className="delivery_pss mb-2">
                    For any other questions and/or concerns. Email:
                    Support@hajiukayukay.com Phone: +971 6 546 1180
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -------Delivery-Return-Modal-end------------ */}
    </>
  );
};

export default ProductDetails;
