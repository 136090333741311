import React from 'react'
import Bori from "../assets/images/bori.jpg";
import { Progress } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, getTotals } from '../redux/features/slices/cartSlice';
import { useNavigate } from 'react-router-dom';
import { imageUrl } from '../config/apiUrl';

const ProductView = ({cartShow,ProductQuickView,closeBtnStyle,overlayS}) => {
  const { detailcartItems } = useSelector((state) => state.quickView);
  const { currency_name } = useSelector((state) => state.currency);

  const dispatch = useDispatch()
  const navigate = useNavigate()
  let handleAddToCart = (product) => {
    let data = {
      ...product,
    };
    dispatch(addToCart(data));
    dispatch(getTotals());
  };
  let handleBuyNow = (product) => {
    handleAddToCart(product)
    navigate("/checkout")
  };
  return (
    <>
    <section>
        <div className={cartShow}>
          <div className="container">
            <div className={closeBtnStyle} onClick={ProductQuickView}>
              <i className="fa-solid fa-chevron-right"></i>
            </div>
            {detailcartItems.map((value,index)=>{
              const amount =
              value.discount_price === ""
                ? parseFloat(value.price).toFixed(2)
                : parseFloat(value.discount_price).toFixed(2);;
              return(
                <div className='main__product_view' key={index}>
                <div>
                    <img className='w-100' height={760} src={imageUrl + `/${value.image[0].image}`} />
                </div>
                <div>
                <h2 className="mt-4">{value.name}</h2>
                <h5>{currency_name} {amount}</h5>
                <div className="product__details_cont">
                  <p>
                    <span className="pro_det_spa">QUALITY:</span>{" "}
                   {value.quality}
                  </p>
                  <p>
                    <span className="pro_det_spa">WEIGHT:</span>{" "}
                   {value.weight}
                  </p>
                  <p>
                    <span className="pro_det_spa">SIZE:</span> {value.size}
                  </p>
                  <p>
                    <span className="pro_det_spa">STICKER NAME:</span>{" "}
                   {value.sticker_name}
                  </p>
                  <p>APPROX {value.pieces} PCS INSIDE THE BUNDLE</p>
                </div>
                <div>
                  <p className="mb-1">Only {value.stock} item(s) left in stock.</p>
                  <Progress value={value.stock} className="progre progre_PV" />
                </div>
                <div className="d-flex justify-content-between my-4">
                  <button
                    className="add_to_cart_PV"
                    onClick={() => handleAddToCart(value)}
                  >
                    ADD TO CART
                  </button>
                  <button className="buy_now_PV"  onClick={() => handleBuyNow(value)}>BUY NOW</button>
                </div>
                <div>
                    <p><span className='fw-bold'>SKU:</span> {value.sku}</p>
                    <p><span className='fw-bold'>Categories: </span>Summer Collection, Men, Men Tops</p>
                </div> 
                <div className="d-flex mt-3">
                  <p>
                    <i className="fa-solid fa-share-nodes me-2"></i>Share
                  </p>
                  <div className="ms-5">
                    <i className="fa-brands fa-twitter me-4"></i>
                    <i className="fa-brands fa-facebook me-4"></i>
                    <i className="fa-brands fa-pinterest me-4"></i>
                    <i className="fa-regular fa-envelope"></i>
                  </div>
                </div>
                </div>
            </div>
              )
            })}

          </div>
        </div>
        <div onClick={ProductQuickView} className={overlayS}></div>
      </section>
    </>
  )
}

export default ProductView