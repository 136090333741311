import React from 'react'
import { clearCompare } from '../redux/features/compare/compareProductSlice';
import { useDispatch, useSelector } from 'react-redux';
import compare_Empty from "../assets/images/compare_empty_img.png";
import { Link } from 'react-router-dom';
import Bori from "../assets/images/bori.jpg";
import { imageUrl } from '../config/apiUrl';

const CompareToggle = ({compareShow,comparehandle,overlayS}) => {

  const { compareItems, compareItemsTotalQuantity } = useSelector((state) => state.compareProduct);
  const dispatch = useDispatch();

    const handleClearCompare = () => {
        dispatch(clearCompare());
        comparehandle()
      };
  return (
    <>
     <section>
        <div className={compareShow}>
          <div className="p-4 position-relative">
            <div onClick={comparehandle} className="close_compare">
              <i className="fa-solid fa-xmark"></i>
            </div>
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="text-center">
                  <h4>Compare Products</h4>
                  <h5 className="ms-4 fw-bold mt-1">({compareItems.length} Products)</h5>
                </div>
              </div>
              <div className="col-md-5">
                <div className="compare_img_div_main">
                  <div className="compare_img_div">
                    <img src={compareItems[0]?imageUrl + `/${compareItems[0].image[0].image}` :compare_Empty} width={65} height={65} alt="" />
                    {/* <img src={compareItems[0]?Bori :compare_Empty} width={65} height={65} alt="" /> */}
                  </div>
                  <div className="compare_img_div">
                    <img src={compareItems[1]?imageUrl + `/${compareItems[1].image[0].image}` :compare_Empty} width={65} height={65} alt="" />
                    {/* <img src={compareItems[1]?Bori :compare_Empty} width={65} height={65} alt="" /> */}
                  </div>
                  <div className="compare_img_div">
                    <img src={compareItems[2]?imageUrl + `/${compareItems[2].image[0].image}` :compare_Empty} width={65} height={65} alt="" />
                    {/* <img src={compareItems[2]?Bori :compare_Empty} width={65} height={65} alt="" /> */}
                  </div>
                  <div className="compare_img_div">
                    <img src={compareItems[3]?imageUrl + `/${compareItems[3].image[0].image}` :compare_Empty} width={65} height={65} alt="" />
                    {/* <img src={compareItems[3]?Bori :compare_Empty} width={65} height={65} alt="" /> */}
                  </div>
                </div>
              </div>
              {compareItems.length>0 && 
              <div className="col-md-3">
                <div className="d-flex align-items-center">
                  <p className="compare_clear_all me-1" onClick={() => handleClearCompare()}>Clear All</p>
                  <Link to="/compare">
                  <button className="lets_comapare_btn"  onClick={comparehandle}>LET'S COMPARE!</button>
                  </Link>
                </div>
              </div>
}
            </div>
          </div>
        </div>
        <div className={overlayS}></div>
      </section>
    </>
  )
}

export default CompareToggle