import React from 'react'
import { Link } from 'react-router-dom'


function NotFound() {
  return (
    <section className='not_found_wrapper'>
      <div class="container">
        <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8">
                <div class="col-lg-12 text-404">
                    <b>404</b>
                </div>
                <div class="col-lg-12 text">
                    <b> PAGE NOT FOUND </b>
                </div>
                <div class="col-lg-12 text-btn">
                    <Link to="/"><button name="login" class="btn btn-outline-primary">BACK TO HOME</button></Link>
                </div>
            </div>
            <div class="col-lg-2"></div>
        </div>
    </div>

    </section>

  )
}

export default NotFound
