import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { logout } from "../redux/features/auth/authSlice";

const DashbordSidebar = () => {
  const { name } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };
  return (
    <>
      <div className="col-md-3 mb-2">
        <div className="userName_Log_div">
          <p className="fw-bold text-uppercase">{name}</p>
          <p className="logout___ orderNo" onClick={handleLogout}>LOGOUT</p>
        </div>
        <div>
          <nav className="MyAccount_navigation">
            <ul>
              <li>
                <NavLink to="/my-account" className="naV__link__item naV__tOp">
                <p >
                  <i className="fa-regular fa-id-card f__light"></i> Dashboard
                </p>
                </NavLink>
              </li>
              <li>
                <NavLink to="/orders" className="naV__link__item">
                <p >
                  <i className="fa-solid fa-clipboard-list f__light"></i> Orders
                </p>
                </NavLink>
              </li>
              <li>
                <NavLink to="/downloads" className="naV__link__item">
                <p>
                  <i className="fa-solid fa-download f__light"></i> Downloads
                </p>
                </NavLink>
              </li>
              <li>
                <NavLink to="/addresses" className="naV__link__item">
                <p>
                  <i className="fa-solid fa-location-dot f__light"></i> Addresses
                </p>
                </NavLink>
              </li>
              <li>
                <NavLink to="/edit-account" className="naV__link__item">
                <p>
                  <i className="fa-solid fa-circle-user f__light"></i> Account
                  details
                </p>
                </NavLink>
              </li>
              <li>
                <p className="naV__link__item">
                <p onClick={handleLogout}>
                  <i className="fa-solid fa-reply f__light"></i> Logout
                </p>
                </p>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

export default DashbordSidebar;
