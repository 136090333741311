import React from "react";
import { NavLink } from "react-router-dom";

const CheckoutNav = ({ currentStep, handleBack }) => {
  return (
    <>
      <div className="d-flex align-items-center flex-wrap py-4">
        <NavLink to="/shopping-cart">
          <p className="check_nav_items">CART</p>
        </NavLink>
        <p className="check_nav_items_arrow">
          <i className="fa-solid fa-chevron-right"></i>
        </p>
        <p
          className={
            currentStep === 1 ? "check_nav_items active" : "check_nav_items"
          }
          onClick={handleBack}
        >
          INFORMATION
        </p>
        <p className="check_nav_items_arrow">
          <i className="fa-solid fa-chevron-right"></i>
        </p>
        <p
          className={
            currentStep === 2 ? "check_nav_items active" : "check_nav_items"
          }
          onClick={ currentStep === 3 ? handleBack : ""}
        >
          SHIPPING
        </p>
        <p className="check_nav_items_arrow">
          <i className="fa-solid fa-chevron-right"></i>
        </p>
        <p className={currentStep === 3 ? "check_nav_items active" : "check_nav_items"}  >
          PAYMENT
        </p>
      </div>
    </>
  );
};

export default CheckoutNav;
