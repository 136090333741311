import React, { useEffect, useState } from "react";
import DashbordSidebar from "../../components/DashbordSidebar";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";

const OrderView = () => {
  const {state} = useLocation()
  const [order, setOrder] = useState(state.data)
  useEffect(() => {
    setOrder(state.data)
  }, [state])

  const orderItems = JSON.parse(order.order_items)


  
  return (
    <section>
      <section className="container-fluid products_main_banner">
        <div className="container">
          <div className="banner_content">
            <h4>Order #{order.id}</h4>
            <div>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/my-account">My account</Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-4 mb-4">
        <div className="container">
          <div className="row">
            <DashbordSidebar />
            <div className="col-md-9">
              <div>
                <div>
                  <p>Order #<mark>{order.id}</mark> was placed on <mark> {moment(order.created_at).format("MMMM DD, YYYY" )}</mark> and is currently <mark>{order.status}</mark>.</p>
                </div>
              <div className="mt-3">
                <h3>Order Details</h3>
                <div className="mt-3">
                  <table className="table table_main_bor">
                    <tbody>
                      {orderItems.map((value,index)=>{

                        return(
                          <tr key={index}>
                          <th>
                            <p className="order__item_pad">
                             {value.name} × {value.cartQuantity}
                            </p>
                          </th>
                          <th>
                            <p className="order__item_pad order_detail_sec_th">
                              {order.currency_abbr} {value.price}
                            </p>
                          </th>
                        </tr>
                        )
                      })}
       
                    </tbody>
                    <tfoot className="t_foo_bg">
                      <tr>
                        <th className="td_border_n">
                          <p className="order_det_amounts_D pt-2">Subtotal:</p>
                        </th>
                        <th className="td_border_n">
                          <p className="order_detail_sec_th pt-2">{order.currency_abbr}  {(order.total_price - order.shipping_charges).toFixed(2)}</p>
                        </th>
                      </tr>
                      <tr>
                        <th className="td_border_n">
                          <p className="order_det_amounts_D">Shipping:</p>
                        </th>
                        <th className="td_border_n">
                          <p className="order_detail_sec_th">
                            {order.currency_abbr}  {order.shipping_charges} 
                            <span className="via_del_fs">
                              {/* via Delivery within UAE Charges per Bundle */}
                               via Delivery  Shipping charges
                              {/* Shipping charges included */}
                            </span>
                          </p>
                        </th>
                      </tr>
                      <tr>
                        <th>
                          <p className="order_det_amounts_D mb-2">
                            Payment method:
                          </p>
                        </th>
                        <th>
                          <p className="order_detail_sec_th mb-2">
                            {order.payment_type  ==="COD" ? "COD / COC" : order.payment_type }
                            {/* Direct bank transfer (Credited within 2-3 Working
                            Days) */}
                          </p>
                        </th>
                      </tr>
                      <tr>
                        <th className="td_border_n">
                          <p className="tot_Order_head">TOTAL:</p>
                        </th>
                        <th className="td_border_n">
                          <p className="order_detail_sec_th tot_Order_head total_am_color">
                          {order.currency_abbr}  {order.total_price}
                          </p>
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div>
                <div className="row mt-4">
                  <div className="col-md-6">
                    <div>
                      <h4 className="mb-2">Billing Address</h4>
                        <div>
                          <p>
                          {order.user_details.first_name} {order.user_details.last_name}
                            <br />
                            {order.user_details.address} 
                            <br />
                            {order.user_details.country}  Bahrain
                            <br />
                            {order.user_details.phone_number}
                            <br />
                            {order.user_details.email}
                          </p>
                        </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                  <div>
                      <h4 className="mb-2">Shipping Address</h4>
                        <div>
                          <p>
                          {order.user_details.first_name} {order.user_details.last_name}
                            <br />
                            {order.user_details.address}
                            <br />
                            {order.user_details.country}
                          </p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default OrderView;
