import React from 'react'

const ProductLoader = () => {
  return (
    <div className="loader">
    <div></div>
    <div></div>
    <div></div>
  </div>
  )
}

export default ProductLoader