import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import aboutus from "../assets/images/about_us.png";

const AboutUs = () => {
  return (
    <>
      <section className="container-fluid products_main_banner">
        <div className="container">
          <div className="banner_content">
            <h4>About Us</h4>
            <div>
              <Breadcrumb>
                <BreadcrumbItem active>Home</BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </section>
      <section className="container mt-4">
        <div>
          <div className="row">
            <div className="col-md-4">
              <h3>Our Story</h3>
              <p className="mb-3">
                We are in the used clothing business since 1940 and have been
                providing the best quality of used clothing all over the world.
                Our business venture exceeds various countries like Kenya,
                Tanzania, Zambia, Uganda, Rwanda, Congo Kinshasa, Congo Goma,
                Congo Bukavu, Congo Point Noire, Congo Lubumbashi, Mozambique,
                Ghana, Nigeria, Gabon, Philippines, Malaysia, Thailand,
                Cambodia, Jordan, Syria, Egypt, Oman, Bahrain, Qatar, Kuwait,
                and all across the Emirate of UAE.
              </p>
              <p>
                High-Quality Products and Satisfied customers are the backbones
                of our continued success in this particular industry, over
                numerous decades.
              </p>
            </div>
            <div className="col-md-8">
              <div>
                <img className="w-100" src={aboutus} alt="aboutus_img" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mt-4">
        <div>
          <div className="row">
            <div className="col-md-8">
              <h4 className="mb-3">What can we do for you ?</h4>
              <div>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item mb-3">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Mission
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          To provide a quality product, cost-effective prices,
                          and captivate customers with a proven value.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item mb-3">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Vision
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          To create an opportunity for everyone who wants to
                          start a used clothing business with less hassle.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item mb-3">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Quality of Goods Provide
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <h5 className="mb-2">Super Cream Quality</h5>
                        <p className="mb-3">
                          Super cream quality is the topmost, good as new, and
                          98-100% no damage. It is in demand due to its premium
                          quality and has almost 10% of famous clothing brands.
                          It is more expensive than the lower quality and has a
                          standard packaging weight of 25kg.
                        </p>
                        <h5 className="mb-2">Cream Quality</h5>
                        <p className="mb-3">
                          Cream quality is the lower grade than super cream, and
                          98-100% no damage. It has almost 10% of famous
                          clothing brands. It is more expensive than the lower
                          quality and has a standard packaging weight of 25kg.
                        </p>
                        <h5 className="mb-2">Class A or Number 1 Quality</h5>
                        <p className="mb-3">
                          Class A quality is in demand by most customers, lower
                          than cream quality, and the price corresponds to the
                          budget with the standard packaging of 45 KGS. You may
                          find 5 to 15% defects such as running colors, size
                          issues, small/large holes, tears, stains, fluff, and
                          loose seams, often overlooked by our sorter. Each
                          bundle can contain 10% of the most well-known apparel
                          brands.
                        </p>
                        <h5 className="mb-2">Class B or Number 2 Quality</h5>
                        <p className="mb-3">
                          Please expect items to have a few general signs of
                          wear compared to the latter quality or grade. It will
                          contain one of the following, bobbling, discoloration,
                          visible stains, or a small/big flaw. Some flaws are
                          either irreparable or washable but are not perfect in
                          appearance and are less expensive than Class A
                          quality. Each bundle can contain 10% of the most
                          well-known apparel brands.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item mb-3">
                    <h2 className="accordion-header" id="headingFpur">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        Customer Care
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFpur"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          Landline: +971 6 546 1180 <br />
                          Email: Hajiukayukaystore@gmail.com
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div>
                <h5>Hour Of Operation</h5>
                <div>
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          Monday: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8 AM
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          TO
                        </td>
                        <td>5:30 PM</td>
                      </tr>
                      <tr>
                        <td>
                          Tuesday: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; 8 AM
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          TO
                        </td>
                        <td>5:30 PM</td>
                      </tr>
                      <tr>
                        <td>
                          Wednesday: &nbsp; &nbsp; &nbsp;8 AM
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          TO
                        </td>
                        <td>5:30 PM</td>
                      </tr>
                      <tr>
                        <td>
                          Thursday: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8 AM
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          TO
                        </td>
                        <td>5:30 PM</td>
                      </tr>
                      <tr>
                        <td>
                          Friday: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                          &nbsp; 8 AM
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          TO
                        </td>
                        <td>5:30 PM</td>
                      </tr>
                      <tr>
                        <td>
                          Saturday: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 8 AM
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          TO
                        </td>
                        <td>5:30 PM</td>
                      </tr>
                      <tr>
                        <td>
                          Sunday: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; WE
                          ARE CLOSED
                        </td>
                        <td>TODAY</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <h5>Careers</h5>
                <p className="mb-3">
                  If you’re interested in employment opportunities at Haji Ukay
                  Ukay
                </p>
                <p>
                  Please E-mail Us: <br /> Support@hajiukayukay.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
