import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  wishItems:  [],
  wisthTotalQuantity: 1,
  // cartTotalAmount: 0,
};

const wishlistSlice = createSlice({
  name: "wishlistCart",
  initialState,
  reducers: {
    addToWishlist(state, action) {
      const existingIndex = state.wishItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (existingIndex >= 0) {
        state.wishItems[existingIndex] = {
          ...state.wishItems[existingIndex],
          // cartQuantity: state.wishItems[existingIndex].cartQuantity  + 1,
          // topping: state.wishItems[existingIndex].toppingsArray,
        };
        toast.info("Already Present In whislist", {
          position: "bottom-right",
        });
      } else {
        let tempProductItem = { ...action.payload, cartQuantity: action.payload.num || + 1};
        state.wishItems.push(tempProductItem);
        toast.success("Product added to whislist", {
          position: "bottom-right",
        });
      }
      // localStorage.setItem("wishItems", JSON.stringify(state.wishItems));
    },
    removeFromWishlistCart(state, action) {
      state.wishItems.map((cartItem) => {
        if (cartItem.id === action.payload.id) {
          const nextwishItems = state.wishItems.filter(
            (item) => item.id !== cartItem.id
          );

          state.wishItems = nextwishItems;

          toast.error("Product removed from cart", {
            position: "bottom-right",
          });
        }
        // localStorage.setItem("wishItems", JSON.stringify(state.wishItems));
        return state;
      });
    },

    getTotalsWishlist(state, action) {

      let { total, quantity } = state.wishItems.reduce(
        (cartTotal, wishItems) => {
          const { price, cartQuantity } = wishItems;

        const itemTotal = parseInt(price) * cartQuantity;

        cartTotal.total += itemTotal;
        cartTotal.quantity += cartQuantity;

        return cartTotal;
      },
      {
        total: 0,
        quantity: 0,
      }
    );

    total = parseFloat(total.toFixed(2));
    state.wisthTotalQuantity = quantity;
    state.cartTotalAmount = total;
  },
    clearWhislist(state, action) {
      state.wishItems = [];
      // localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      toast.error("Remove all products", { position: "bottom-right" });
    },
    clearWhislistAll(state, action) {
      state.wishItems = [];
    },

  },
});

export const { addToWishlist, removeFromWishlistCart,clearWhislist,clearWhislistAll ,getTotalsWishlist} =
  wishlistSlice.actions;

export default wishlistSlice.reducer;

