import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from 'react-toastify';
import PageLayout from './layout/PageLayout';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import Product from './pages/Product';
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";
import ProductDetails from './pages/ProductDetails';
import SetPassword from './pages/SetPassword';
import ContactUs from './pages/ContactUs';
import ShoppingCart from './pages/ShoppingCart';
import Checkout from './pages/Checkout';
import SubcatProducts from './pages/SubcatProducts';
import Shippng from './pages/Shipping';
import Payment from './pages/Payment';
import Dashboard from './pages/MyAccount/Dashboard';
import Orders from './pages/MyAccount/Orders';
import Addresses from './pages/MyAccount/Addresses';
import EditAccount from './pages/MyAccount/EditAccount';
import Downloads from './pages/MyAccount/Downloads';
import OrderReceived from './pages/OrderReceived';
import ScrollToTop from './components/ScrollToTop';
import CheckoutNew from './pages/CheckoutNew';
import Compare from './pages/Compare';
import Refund from './pages/Refund';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermCondition from './pages/TermCondition';
import AboutUs from './pages/AboutUs';
import EditAddressBilling from './pages/MyAccount/EditAddressBilling';
import EditAddressShipping from './pages/MyAccount/EditAddressShipping';
import SearchProducts from './pages/SearchProducts';
import OrderView from './pages/MyAccount/OrderView';
import NotFound from './pages/NotFound';

function App() {
  return (
    <>
       <ToastContainer />
        <ScrollToTop/>
      <Routes>
        <Route element={<PageLayout />}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/product-category/:id/:id" element={<Product />} />
          <Route exact path="/product-category/:id" element={<SubcatProducts />} />
          <Route exact path="/product-category" element={<SubcatProducts />} />
          <Route exact path="/product-category/search" element={<SearchProducts />} />
          <Route exact path="/product" element={<Product />} />
          <Route exact path="/shop/:id/:id/:id" element={<ProductDetails />} />
          <Route exact path="/reset-password" element={<SetPassword />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/shopping-cart" element={<ShoppingCart />} />
          <Route exact path="/checkout/order-received/:id" element={<OrderReceived />} />
          <Route exact path="/compare" element={<Compare />} />
          <Route exact path="/refund-and-returns" element={<Refund />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/term-condition" element={<TermCondition />} />
          {/* ---------My---Account------------- */}
          <Route exact path="/my-account" element={<Dashboard />} />
          <Route exact path="/orders" element={<Orders />} />
          <Route exact path="/addresses" element={<Addresses />} />
          <Route exact path="/edit-account" element={<EditAccount />} />
          <Route exact path="/downloads" element={<Downloads />} />
          <Route exact path="/orders/view-order" element={<OrderView />} />
          <Route exact path="/addresses/edit-address/billing" element={<EditAddressBilling />} />
          <Route exact path="/addresses/edit-address/shipping" element={<EditAddressShipping />} />
        </Route>

        {/* <Route exact path="/checkout" element={<Checkout />} /> */}
        <Route exact path="/checkout" element={<CheckoutNew />} />
        <Route exact path="/shipping" element={<Shippng />} />
        <Route exact path="/payment" element={<Payment />} />

        <Route exact path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
