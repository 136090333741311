import React from "react";
import DashbordSidebar from "../../components/DashbordSidebar";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/features/auth/authSlice";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const Dashboard = () => {
  const { name } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };
  return (
    <section>
      <section className="container-fluid products_main_banner">
        <div className="container">
          <div className="banner_content">
            <h4>My account</h4>
            <div>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-4 mb-4">
        <div className="container">
          <div className="row">
            <DashbordSidebar />
            <div className="col-md-9">
              <div>
                <p className="mb-3">
                  Hello <span className="fw-bold">{name}</span> (not {name}?{" "}
                  <span className="terms__con" onClick={handleLogout}>
                    Log out
                  </span>
                  )
                </p>
                <p>
                  From your account dashboard you can view your{" "}
                  <NavLink to="/orders">
                    <span className="terms__con">recent orders</span>
                  </NavLink>
                  , manage your{" "}
                  <NavLink to="/addresses">
                    <span className="terms__con">
                      shipping and billing addresses
                    </span>
                  </NavLink>
                  , and{" "}
                  <NavLink to="/edit-account">
                    <span className="terms__con">
                      edit your password and account details
                    </span>
                  </NavLink>
                  .
                </p>
                <div className="mt-4">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <NavLink to="/orders">
                        <div className="dasH_cardss">
                          <p>
                            <i className="fa-solid fa-clipboard-list dash__iconSs"></i>
                          </p>
                          <p>Orders</p>
                        </div>
                      </NavLink>
                    </div>
                    <div className="col-md-4 mb-3">
                      <NavLink to="/downloads">
                        <div className="dasH_cardss">
                          <p>
                            <i className="fa-solid fa-download dash__iconSs"></i>
                          </p>
                          <p>Downloads</p>
                        </div>
                      </NavLink>
                    </div>
                    <div className="col-md-4 mb-3">
                      <NavLink to="/addresses">
                        <div className="dasH_cardss">
                          <p>
                            <i className="fa-solid fa-location-dot dash__iconSs"></i>
                          </p>
                          <p>Addresses</p>
                        </div>
                      </NavLink>
                    </div>
                    <div className="col-md-4 mb-3">
                      <NavLink to="/edit-account">
                        <div className="dasH_cardss">
                          <p>
                            <i className="fa-solid fa-circle-user dash__iconSs"></i>
                          </p>
                          <p>Account details</p>
                        </div>
                      </NavLink>
                    </div>
                    <div className="col-md-4 mb-3">
                      <NavLink to="/re">
                        <div className="dasH_cardss">
                          <p>
                            <i className="fa-solid fa-reply  dash__iconSs"></i>
                          </p>
                          <p>Logout</p>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Dashboard;
