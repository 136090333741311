import React from 'react'
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { imageUrl } from '../../config/apiUrl';


const FeatureCategories = ({featureCatalog}) => {
  var settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <section className="container my-4">
    <div className='mx-4'>
      <h3 className="home_heading pb-3">Feature Categories</h3>
      <div>
      <Slider {...settings}>
      {featureCatalog.map((value,index)=>{
        const name = `${value.sub_cat_name.split(" ")[0]} ${value.name}`
        return(
        <div className="p-2" key={index}>
            <Link to={`/product-category/${value.main_cat_slug}/${value.slug}`} 
            state={{
              id:value.sub_cat_id,
              name:name
              }}
            >
          <div className="text-center feature_card feature_card">
            <img
              className="feature_cat_img"
              src={imageUrl + `/${value.image}`}
              alt=""
            />
            <p className='text-dark'>{name}</p>
          </div>
        </Link>
        </div>
             )})}
        </Slider>
      </div>
    </div>
  </section>
    </>
  )
}

export default FeatureCategories