import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { baseURL } from "../config/apiUrl";
import LeftSideCategory from "../components/LeftSideCategory";
import ProductCard from "../components/cards/ProductCard";
import { useSelector } from "react-redux";
import ProductLoader from "../components/ProductLoader";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import FilterSliderMob from "../components/FilterSliderMob";

const SubcatProducts = (props) => {
  const [filDisplay, setfilDisplay] = useState("block");
  const [rowStyle, setrowStyle] = useState("9");
  const [filterIcon, setfilterIcon] = useState("minus");
  // const [open, setOpen] = useState("1");
  const [loading, setLoading] = useState(false);
  const { categories } = useSelector((state) => state.category);

  // const toggle = (id) => {
  //   if (open === id) {
  //     setOpen();
  //   } else {
  //     setOpen(id);
  //   }
  // };

  const filt = () => {
    if (filDisplay === "none") {
      setfilDisplay("block");
      setrowStyle("9");
      setfilterIcon("minus");
    } else {
      setfilDisplay("none");
      setrowStyle("12");
      setfilterIcon("plus");
    }
  }
  const {state,search} = useLocation()
  const {currency_id} = useSelector((state) => state.currency);

  const [products, setProducts] = useState([]);
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        baseURL + `/getProductsBySubCatId/${state.id}/${currency_id}`
      );
      const { data } = response.data;
      setProducts(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const location = useLocation()
  useEffect(() => {
    if(search ===""){
      
      fetchProducts();

    }
  }, [state]);
  useEffect(() => {
 if(search !==""){
      setProducts(state.product)
    }

  }, [state]);
  const {id} = useParams()

  const fetchNewItems = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(baseURL + `/getNewItems/${currency_id}`);
      const { data } = response.data;
      setProducts(data);
      setLoading(false);

      // navigate(`/product-category/${id}` , {state:{products:data}})
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchSummerItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        baseURL + `/getSpecialCategoryProducts/${currency_id}/summer`
      );
      const { data } = response.data;
      setProducts(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchExclusiveItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        baseURL + `/getSpecialCategoryProducts/${currency_id}/exclusive`
      );
      const { data } = response.data;
      setProducts(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchWinterItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        baseURL + `/getSpecialCategoryProducts/${currency_id}/winter`
      );
      const { data } = response.data;
      setProducts(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchStockItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(baseURL + `/getOnStock/${currency_id}`);
      const { data } = response.data;
      setProducts(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchBackItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(baseURL + `/getOnBack/${currency_id}`);
      const { data } = response.data;
      setProducts(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchSaleItems = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        baseURL + `/getDiscountedItem/${currency_id}`
      );
      const { data } = response.data;
      setProducts(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchFeaturedItems = async () => {
    setLoading(true)
    try {
      const response = await axios.get(baseURL + `/getAllFeaturedProducts/${currency_id}`);
      const { data } = response.data;
      setProducts(data);
       setLoading(false)
    } catch (error) {
      setLoading(false)

    }
  };
  const fetchHouseHoldItems = async () => {
    setLoading(true)
    try {
      const response = await axios.get(baseURL + `/gethouseholdproducts/${currency_id}`);
      const { data } = response.data;
      setProducts(data);
       setLoading(false)
    } catch (error) {
      setLoading(false)

    }
  };
  useEffect(() => {
    if (id == "new-items") {
      fetchNewItems();
    } else if (id == "summer-collections") {
      fetchSummerItems();
    } else if (id == "winter-collections") {
      fetchWinterItems();
    } else if (id == "exclusive-offer") {
      fetchExclusiveItems();
    } else if (id == "in-stock") {
      fetchStockItems();
    } else if (id == "on-backorder") {
      fetchBackItems();
    } else if (id == "on-sale") {
      fetchSaleItems();
    }
    else if(id=="summer-collection"){
      fetchSummerItems()
    }
    else if(id=="winter-collection"){
      fetchWinterItems()
    }
    else if(id=="exclusive-offer"){
      fetchExclusiveItems()
    }
    else if(id=="in-stock"){
      fetchStockItems()
    }
    else if(id=="on-backorder"){
      fetchBackItems()
    }
    else if(id=="on-sale"){
      fetchSaleItems()
    }
    else if(id=="featured"){
      fetchFeaturedItems()
    }
    else if(id=="house-hold"){
      fetchHouseHoldItems()
    }

  }, [id])
  

  const [mobNavShow, setmobNavShow] = useState("sidebar__act__navMob");
  const [closebtnmobNav, setclosebtnmobNav] = useState("close_btn_MobNav_tra");
  const [overlayS, setoverlayS] = useState("overlay_slider_a");

  const mobnav_handler = () => {
    if (mobNavShow === "sidebar__act__navMob") {
      setmobNavShow("sidebar__navMob");
      setclosebtnmobNav("close_btn_MobNav");
      setoverlayS("overlay_slider");
    } else {
      setmobNavShow("sidebar__act__navMob");
      setclosebtnmobNav("close_btn_MobNav_tra");
      setoverlayS("overlay_slider_a");
    }
  };



  return (
    <>
      <div>
        <section className="container-fluid products_main_banner">
          <div className="container">
            <div className="banner_content">
              <h4 className="text-capitalize">
                {id=="new-items" && "New Items"}
                {id=="winter-collection" && "Winter Collection"}
                {id=="summer-collection" && "Summer Collection"}
                {id=="exclusive-offer" && "Exclusive Offer"}
                {id=="on-sale" && "Sale"}
                {id=="featured" && "Featured"}
                {id=="on-backorder" && "On Back Order"}
                {id=="in-stock" && "Stock"}
                {id=="house-hold" && "House Hold"}
                {location.search !=="" && "Products"}
                {state && state.name}
              </h4>
              <div>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to="/">Home</Link>
                  </BreadcrumbItem>
                  {/* <BreadcrumbItem active>Men</BreadcrumbItem> */}
                </Breadcrumb>
              </div>
            </div>
          </div>
        </section>
        <section className="container mt-3">
          <div className="row align-items-center">
          <div className="col-md-4">
              <div>
              <span onClick={mobnav_handler} className="filters_mob">
              <i className="fa-solid fa-filter"></i> &nbsp;Filters 
                </span>
                <span onClick={filt} className="filters">
                  Filters &nbsp;<i className={`fa-solid fa-${filterIcon}`}></i>
                </span>
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="sorting__filters">
                <p>Sort by: </p>
                <select
                  style={{ border: "none" }}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Default sorting</option>
                  <option value={1}>Popularity</option>
                  <option value={2}>Average rating</option>
                  <option value={3}>Price: Ascending</option>
                  <option value={4}>Price: Decrease</option>
                </select>
              </div>
            </div> */}
          </div>
        </section>
        <section className="container mt-3">
          <div>
            <div className="row">
              <LeftSideCategory filDisplay={filDisplay} catalog={categories}/>

              <div className={`col-md-${rowStyle}`}>
                <div className="row hgh">
                  {loading && <ProductLoader />}
                  {!loading && products.length === 0 && (
                    <div className="text-center mt-5">
                      <i className="fa-solid fa-bag-shopping no_cart_bag" />
                      <p className="no_pro__p my-3">
                        No products were found matching your selection.
                      </p>
                    </div>
                  )}
                  <ProductCard products={products} />
                </div>
              </div>
            </div>
          </div>
        </section>
                      {/* -------Mob-Nav--Start---- */}
                      <FilterSliderMob
        mobNavShow={mobNavShow}
        mobnav_handler={mobnav_handler}
        closebtnmobNav={closebtnmobNav}
        overlayS={overlayS}
        catalog={categories}
      />
      {/* -------Mob-Nav--end---- */}
      </div>
    </>
  );
};

export default SubcatProducts;
