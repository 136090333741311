// const  stagingURL="";
// const localURL="";
// const baseURL = "";
// const imageUrl = "http://127.0.0.1:8000";
// const baseURL = "http://127.0.0.1:8000/api";

const baseURL = "https://api.albarakalds.com/api";
const imageUrl = "https://api.albarakalds.com";
export {
    baseURL,
    imageUrl
}