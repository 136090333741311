import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
// import {
//   Accordion,
//   AccordionBody,
//   AccordionHeader,
//   AccordionItem,
//   Breadcrumb,
//   BreadcrumbItem,
// } from "reactstrap";
// import Bori from '../assets/images/bori.jpg'
// import MinimumDistanceSlider from "../components/MinimumDistanceSlider";
import { baseURL, imageUrl } from "../config/apiUrl";
import LeftSideCategory from "../components/LeftSideCategory";
import ProductCard from "../components/cards/ProductCard";
import { useSelector } from "react-redux";
import ProductLoader from "../components/ProductLoader";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const SearchProducts = (props) => {
  const [filDisplay, setfilDisplay] = useState("block");
  const [rowStyle, setrowStyle] = useState("9");
  const [filterIcon, setfilterIcon] = useState("minus");
//   const [open, setOpen] = useState("1");
  // const [loading, setLoading] = useState(false);


  const filt = () => {
    if (filDisplay === "none") {
      setfilDisplay("block");
      setrowStyle("9");
      setfilterIcon("minus");
    } else {
      setfilDisplay("none");
      setrowStyle("12");
      setfilterIcon("plus");
    }
  }
  const {state,search} = useLocation()
  const {currency_id} = useSelector((state) => state.currency);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    setProducts(state.data)
  }, [state]);

  const {id} = useParams()

  return (
    <>
      <div>
        <section className="container-fluid products_main_banner">
          <div className="container">
            <div className="banner_content">
              <h4 className="text-capitalize">

              Search results for  "{state && state.value}"
              </h4>
              <div>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to="/">Home</Link>
                  </BreadcrumbItem>
                  {/* <BreadcrumbItem active>Men</BreadcrumbItem> */}
                </Breadcrumb>
              </div>
            </div>
          </div>
        </section>
        <section className="container mt-3">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div>
                <span onClick={filt} className="filters">
                  Filters &nbsp;<i className={`fa-solid fa-${filterIcon}`}></i>
                </span>
              </div>
            </div>
            <div className="col-md-5"></div>
            {/* <div className="col-md-3">
              <div className="sorting__filters">
                <p>Sort by: </p>
                <select
                  style={{ border: "none" }}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Default sorting</option>
                  <option value={1}>Popularity</option>
                  <option value={2}>Average rating</option>
                  <option value={3}>Price: Ascending</option>
                  <option value={4}>Price: Decrease</option>
                </select>
              </div>
            </div> */}
          </div>
        </section>
        <section className="container mt-3">
          <div>
            <div className="row">
              <LeftSideCategory filDisplay={filDisplay} />

              <div className={`col-md-${rowStyle}`}>
                <div className="row hgh">
                  {/* {loading && <ProductLoader />} */}
                  {/* {!loading && products.length === 0 && ( */}
                  { products.length === 0 && (
                    <div className="text-center mt-5">
                      <i className="fa-solid fa-bag-shopping no_cart_bag" />
                      <p className="no_pro__p my-3">
                        No products were found matching your selection.
                      </p>
                    </div>
                  )}
                  <ProductCard products={products} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SearchProducts;
