import React from "react";
import { imageUrl } from "../config/apiUrl";

const Slider = ({banner}) => {

  return (
    <div className="mt-4">
      <section className="container">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            {banner &&
              banner.map((value, index) => (
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={index}
                  className={index === 0 ? "active" : ""}
                  aria-current="true"
                  aria-label={index}
                  key={index}
                />
              ))}
          </div>
          <div className="carousel-inner">
            {banner &&
              banner.map((value, index) => (
                <div
                  className={
                    index === 0 ? "carousel-item active" : "carousel-item "
                  }
                  key={index}
                >
                  {/* <Link to={token ? "" : value.link}> */}
                  <img
                    src={imageUrl + `/${value.image}`}
                    className="d-block w-100 carousel_height"
                    // height={497}
                    alt="..."
                    // onError={(e) => {
                    //   e.target.onerror = null;
                    //   e.target.src = bannerImg;
                    // }}
                  />
                  {/* </Link> */}
                </div>
              ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>
    </div>
  );
};

export default Slider;
