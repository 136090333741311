import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Breadcrumb, BreadcrumbItem, Spinner } from "reactstrap";
import { contactUsSchema } from "../schemas";
import { baseURL } from "../config/apiUrl";

const ContactUs = () => {
  const [loading, setLoading] = useState(false)

  const initialValues = {
    name: "",
    email: "",
    message: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
  useFormik({
    initialValues,
    validationSchema: contactUsSchema,
    onSubmit: async (values, action) => {
      var userData = new FormData();
      userData.append("name", values.name);
      userData.append("email", values.email);
      userData.append("message", values.message);

      setLoading(true);
      try {
        const response = await axios.post(baseURL + "/postContactUs",userData)
        const { message,data } = response.data;
        toast.success("Thank you for your message. It has been sent.");
        // Thank you for your message. It has been sent.
      action.resetForm()
        setLoading(false);
      } catch (error) {
        toast.error("Went something worng! Try again ");
        setLoading(false);
      }
    },

  });


  return (
    <>
      <section className="container-fluid products_main_banner">
        <div className="container">
          <div className="banner_content">
            <h4>Contact Us</h4>
            <div>
              <Breadcrumb>
                <BreadcrumbItem active>Home</BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-4 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div>
                <iframe
                  className="w-100"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3606.6351428247353!2d55.429585415011836!3d25.316459683840886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x72345bfaa2430f9d!2zMjXCsDE4JzU5LjMiTiA1NcKwMjUnNTQuNCJF!5e0!3m2!1sen!2s!4v1653427316525!5m2!1sen!2s"
                  height={450}
                  style={{ border: "0px", pointerEvents: "none" }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
            <div className="col-md-7">
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <p className="mb-3">Al Khat Al Abyadh Gen Tr Co LLC</p>
                    <p>
                      Shed No 9 and 11 , Behind Bmw Showroom Industrial Area 12
                      Sharjah, United Arab Emirates
                    </p>
                  </div>
                  <div className="col-md-6">
                    <p>Landline: +971 6 546 1180</p>
                    <p>Whatsapp: +971 6 546 1180</p>
                    <p>Hotline: +971 6 546 1180</p>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <p>info@hajiukayukay.com</p>
                    <p>Support@hajiukayukay.com</p>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <h4>Social</h4>
                      <div className="contact__social_icons">
                        <i className="fa-brands fa-facebook me-3"></i>
                        <i className="fa-brands fa-instagram me-3"></i>
                        <i className="fa-brands fa-youtube me-3"></i>
                        <i className="fa-brands fa-whatsapp me-3"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <p style={{fontSize:"14px"}}>
                    If you have any questions, please fell free to get in touch
                    with us.
                    <br />
                    We will reply to you as soon as possible. Thank you!
                  </p>
                </div>
                <div className="mt-3">
                  <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label for="name" className="form-label">
                          Your Name (required)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Your Name"
                          id="name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                          {errors.name && touched.name ? (
                              <p className="form-error">{errors.name}</p>
                            ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label for="email" className="form-label">
                          Your Email (required)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Your Email"
                          id="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                          {errors.email && touched.email ? (
                              <p className="form-error">{errors.email}</p>
                            ) : null}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label for="YourMessage" className="form-label">
                          Your Message
                        </label>
                        <textarea
                          className="form-control"
                          rows={2}
                          placeholder="Your Message"
                          id="message"
                          name="message"
                          value={values.message}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        ></textarea>
                      </div>
                    </div>
                    <div>
                        <button className="contactUs_Submit_btn" type="submit" disabled={loading?true:false}>
                        {loading?  <Spinner color="white"/> : "Submit"}  
                        </button>

                    </div>
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
