import axios from "axios";
import React, {  useState } from "react";
import MinimumDistanceSlider from "../components/MinimumDistanceSlider";
import { NavLink, useNavigate } from "react-router-dom";
import {  useSelector } from "react-redux";

const FilterSliderMob = ({
  mobNavShow,
  mobnav_handler,
  closebtnmobNav,
  overlayS,
  catalog
}) => {

    const navigate = useNavigate()

    const { currency_id } = useSelector((state) => state.currency);
    const [selected, setSelected] = useState("");
  
    function handleRadioChange(event) {
      setSelected(event.target.value);
      if(event.target.value === "on-backorder"){
        navigate("/product-category/on-backorder")
      }
      else if(event.target.value === "in-stock"){
        navigate("/product-category/in-stock")
      }
      else if(event.target.value === "on-sale"){
        navigate("/product-category/on-sale")
      }
      else if(event.target.value === "featured"){
        navigate("/product-category/featured")
      }
      // Call your API function here
    }



  return (
    <>
      <section>
        <div className={mobNavShow}>
          <div className="">
            <div className={closebtnmobNav} onClick={mobnav_handler}>
              <i className="fa-solid fa-chevron-left"></i>
            </div>
            <h4 className="mt-4 ms-3 pb-4">Categories</h4>
            <div className="container filterMain_height">
          <div className="categories__Main">
            <ul className="ul_style">
              {/* <li>
                <p className="d-flex justify-content-between mb-2">
                  <span className={active} onClick={()=>fetchNewItems("new-items")}>New Item</span>
                </p>
              </li> */}
              <NavLink to="/product-category/new-items">
              <li>
                <p className="d-flex justify-content-between mb-2">
                  <span className='active_link'>New Item</span>
                </p>
              </li>
              </NavLink>
              <NavLink to="/product-category/summer-collection">
              <li>
                <p className="d-flex justify-content-between mb-2">
                  <span>Summer Collection</span>
                </p>
              </li>
              </NavLink>
              <NavLink to="/product-category/winter-collection">
              <li>
                <p className="d-flex justify-content-between mb-2">
                  <span>Winter Collection</span>
                </p>
              </li>
              </NavLink>
              <NavLink to="/product-category/exclusive-offer">
              <li>
                <p className="d-flex justify-content-between mb-2">
                  <span>Exclusive Offer</span>
                </p>
              </li>
              </NavLink>

              {catalog &&
                catalog.map((value, index) => {
                  const { sub_categories } = value;
                  const main_cat_name = value.slug;
                  if (sub_categories.length === 0) {
                    // if there are no sub-categories, skip rendering the collapse element
                    return (
                      <li key={index}>
                        <NavLink
                          to={`/product-category/${main_cat_name}`}
                          state={{
                            id: value.id,
                            name: value.name,
                          }}
                        >
                          <p className="d-flex justify-content-between mb-2">
                            <span>{value.name}</span>
                          </p>
                        </NavLink>
                      </li>
                    );
                  } else {
                    // if there are sub-categories, render the collapse element and sub-categories
                    return (
                      <li key={index}>
                        <p
                          className="collapsed d-flex justify-content-between mb-2"
                          data-bs-toggle="collapse"
                          data-bs-target={`#${main_cat_name}`}
                          aria-expanded="false"
                          aria-controls={main_cat_name}
                        >
                          <span>{value.name}</span>
                          <span>
                            <i className="fa-solid fa-plus"></i>
                          </span>
                        </p>
                        <div>
                          {sub_categories.map((val, ind) => {
                            const { categories } = val;
                            return (
                              <div key={ind}>
                                {categories.map((val, ind) => (
                                  <div
                                    id={main_cat_name}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="panelsStayOpen-heading4"
                                    key={ind}
                                  >
                                    <ul>
                                      <NavLink
                                        to={`/product-category/${main_cat_name}/${val.slug}`}
                                        state={{
                                          id: val.id,
                                          name: `${main_cat_name} ${val.name}`,
                                        }}
                                      >
                                        <li onClick={mobnav_handler}>{val.name}</li>
                                      </NavLink>
                                    </ul>
                                  </div>
                                ))}
                              </div>
                            );
                          })}
                        </div>
                      </li>
                    );
                  }
                })}
              <NavLink to="/product-category/house-hold">
              <li>
                <p className="d-flex justify-content-between mb-2">
                  <span>House Hold</span>
                </p>
              </li>
              </NavLink>

              {/* <li>
                        <p className="d-flex justify-content-between mb-2">
                          <span>House Hold</span>
                        </p>
                      </li> */}
            </ul>
          </div>
          <h4 className="mb-2">Price</h4>
          <MinimumDistanceSlider />

          <h4 className="mb-2">Status</h4>
          <div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="radio-group"
          id="radio1"
          value="on-sale"
          checked={selected === "on-sale"}
          onChange={handleRadioChange}
        />
        <label className="form-check-label" htmlFor="radio1">
          On Sale
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="radio-group"
          id="radio2"
          value="featured"
          checked={selected === "featured"}
          onChange={handleRadioChange}
        />
        <label className="form-check-label" htmlFor="radio2">
          Featured
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="radio-group"
          id="radio3"
          value="in-stock"
          checked={selected === "in-stock"}
          onChange={handleRadioChange}
        />
        <label className="form-check-label" htmlFor="radio3">
          In Stock
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="radio-group"
          id="radio3"
          value="on-backorder"
          checked={selected === "on-backorder"}
          onChange={handleRadioChange}
        />
        <label className="form-check-label" htmlFor="radio3">
          On Backorder
        </label>
      </div>
    </div>

        </div>
          </div>
        </div>
        <div onClick={mobnav_handler} className={overlayS}></div>
      </section>
    </>
  );
};

export default FilterSliderMob;
