import React, { useState } from "react";
import Logo from "../assets/images/logo.jpg";
import Bori from "../assets/images/bori.jpg";
import { NavLink } from "react-router-dom";


const Shippng = () => {
  const [shippingDiv, setshippingDiv] = useState(false)
  const [coupenDiv, setcoupenDiv] = useState(false)



  return (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <div className="container check_out_left">
                <img height={60} width={85} src={Logo} alt="" />
                <div className="d-flex align-items-center flex-wrap py-4">
                  <NavLink to="/shopping-cart"><p className="check_nav_items">CART</p></NavLink>
                  <p className="check_nav_items_arrow">
                    <i className="fa-solid fa-chevron-right"></i>
                  </p>
                  <p className="check_nav_items"><NavLink to="/checkout">INFORMATION</NavLink></p>
                  <p className="check_nav_items_arrow">
                    <i className="fa-solid fa-chevron-right"></i>
                  </p>
                  <p className="check_nav_items">SHIPPING</p>
                  <p className="check_nav_items_arrow">
                    <i className="fa-solid fa-chevron-right"></i>
                  </p>
                  <p className="check_nav_items">PAYMENT</p>
                </div>
                <div>
                <div className="contact_super">
                  <div className="d-flex justify-content-between contact__maiN">
                    <div>
                      <span className="pe-4">Contact</span>
                      <span className="fw-bold">ammar@yopmail.com</span>
                    </div>
                    <div className="change__btn">Change</div>
                  </div>
                  <div className="d-flex justify-content-between contact__maiN">
                    <div>
                      <span className="pe-4">Ship to</span>
                      <span className="fw-bold">xyz address, Dubai</span>
                    </div>
                    <div className="change__btn">Change</div>
                  </div>
                  <div className="d-flex justify-content-between contact__maiN contact__maiN_bb">
                    <div>
                      <span className="pe-4">Method</span>
                      <span className="fw-bold">Delivery within UAE Charges per Bundle: AED 15.00</span>
                    </div>
                    <div className="change__btn">Change</div>
                  </div>
                </div>
                <div>
                  <h5 className="pt-4">Shipping Methods</h5>
                  <div className="contact_super">
                  <div className="d-flex justify-content-between contact__maiN">
                  <div className="form-check">
        <input className="form-check-input" type="radio" name="flexRadioDefault" id="delivery" />
        <label className="form-check-label" htmlFor="delivery">
        Delivery within UAE Charges per Bundle: AED 15.00
        </label>
      </div>
                  </div>
                  <div className="d-flex justify-content-between contact__maiN contact__maiN_bb">
                  <div className="form-check">
        <input className="form-check-input" type="radio" name="flexRadioDefault" id="collect" />
        <label className="form-check-label" htmlFor="collect">
        Click & Collect from Warehouse
        </label>
      </div>
                  </div>
                </div>
                </div>
                <div className="mt-4">
                <div className="mb-3">
        <label htmlFor="exampleFormControlTextarea1" className="form-label">Order notes (optional)</label>
        <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} defaultValue={""} placeholder="Notes about your order, e.g. special notes for delivery." />
      </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                        <p className="return__to__p"><i className="fa-solid fa-chevron-left return__to__"></i> RETURN TO INFORMATION</p>
                        <button className="ContinueToShipping">CONTINUE TO PAYMENT</button>
                      </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 p-0">
              <div className="check_out_right">
                <div className="d-flex align-items-center justify-content-between checoutCart__item">
                  <div className="d-flex align-items-center position-relative">
                  <img className="checoutCart__img" src={Bori} />
                  <div className="item_quantity__check">1</div>
                  <p className="ms-2 fw-bold">Ladies Night Pajama</p>
                  </div>
                  <div>
                    <p className="fw-bold">AED 325.00</p>
                  </div>
                </div>
                <div className="checoutCart__coupen">
                  <p className="mb-2 coupon_txt" onClick={()=>setcoupenDiv(!coupenDiv)}>Have a coupon code?</p>
                  <div className={`coupen__app_main ${coupenDiv?"d-flex":"d-none"}`}>
                  <input type="text" className="form-control" placeholder="Coupen Code"/>
                  <button className="coupen_apply_btn">APPLY</button>
                  </div>
                </div>
                <div className="cart_subtotal_main">
                  <table className="table cart_subtotal">
                    <tr>
                      <th>Subtotal</th>
                      <td>
                        <span className="fw-bold ">AED 325.00</span>
                        </td>
                    </tr>
                    <tr>
                      <th>Shipping</th>
                      <td>
                        <p>Delivery within UAE <br/>
                        Charges per Bundle:
                        <br/><span className="fw-bold">AED 15.00</span></p>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <p className="p-0 working__">
                        UAE ExpectedDelivery 1 – 3 Working Days, (Local Delivery Charges)
                        </p>
                        </th>
                      <td>
                        <span className="fw-bold">AED 0.00</span>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="total__main">
                  <p>TOTAL INCL. VAT</p>
                  <p>AED 190.00</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Shippng;
