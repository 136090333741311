import React, { useEffect, useState } from "react";
import LoginModal from "./LoginModal";
import Logo from "./../assets/images/logo.jpg";
import USLogo from "./../assets/images//us.png";
import axios from "axios";
import { baseURL, imageUrl } from "../config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/features/auth/authSlice";
import Bori from "../assets/images/bori.jpg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  clearCartAll,
  getTotals,
} from "../redux/features/slices/cartSlice";
import { getCurrency } from "../redux/features/currency/currencySlice";
import {
  clearCompare,
  clearCompareAll,
  getTotalsCompare,
} from "../redux/features/compare/compareProductSlice";
import {
  clearWhislist,
  clearWhislistAll,
  getTotalsWishlist,
} from "../redux/features/wishlist/wishlistSlice";
import CartToggle from "./CartToggle";
import CompareToggle from "./CompareToggle";
import WishlistToggle from "./WishlistToggle";
import SearchToggle from "./SearchToggle";
import MobNavSlider from "./MobNavSlider";
import { getCategories } from "../redux/features/categories/categoriesSlice";
const Header = () => {
  const { token, name } = useSelector((state) => state.auth);
  // const [catalog, setCatalog] = useState([]);
  const [CurrencyOptions, setCurrencyOptions] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [cartShow, setcartShow] = useState("sidebar__act");
  const [closeBtnStyle, setcloseBtnStyle] = useState("close_btn_tra");
  const [overlayS, setoverlayS] = useState("overlay_slider_a");

  const [mobNavShow, setmobNavShow] = useState("sidebar__act__navMob");
  const [closebtnmobNav, setclosebtnmobNav] = useState("close_btn_MobNav_tra");


  const [wishListOpen, setwishListOpen] = useState("sidebar__act");
  const [closeBtnwStyle, setcloseBtnwStyle] = useState("close_btn_w_tra");
  const [compareShow, setcompareShow] = useState("compare_main_div");
  const [searchShow, setsearchShow] = useState("search_main_div");
  const [selectedCurrency, setSelectedCurrency] = useState();

  // const compareHandle = () =>{
  //   if(compareShow===true){
  //     setcompareClassActive("compare_main_div_active")
  //   }else{
  //     setcompareClassActive("")
  //   }
  // }

  const cartOpen = () => {
    if (cartShow === "sidebar__act") {
      setcartShow("sidebar__");
      setcloseBtnStyle("close_btn");
      setoverlayS("overlay_slider");
    } else {
      setcartShow("sidebar__act");
      setcloseBtnStyle("close_btn_tra");
      setoverlayS("overlay_slider_a");
    }
  };
  const mobnav_handler = () => {
    if (mobNavShow === "sidebar__act__navMob") {
      setmobNavShow("sidebar__navMob");
      setclosebtnmobNav("close_btn_MobNav");
      setoverlayS("overlay_slider");
    } else {
      setmobNavShow("sidebar__act__navMob");
      setclosebtnmobNav("close_btn_MobNav_tra");
      setoverlayS("overlay_slider_a");
    }
  };

  const comparehandle = () => {
    if (compareShow === "compare_main_div") {
      setcompareShow("compare_main_div_act");
      setoverlayS("overlay_slider");
    } else {
      setcompareShow("compare_main_div");
      setoverlayS("overlay_slider_a");
    }
  };
  const searchhandle = () => {
    if (searchShow === "search_main_div") {
      setsearchShow("search_main_div_act");
      setoverlayS("overlay_slider");
    } else {
      setsearchShow("search_main_div");
      setoverlayS("overlay_slider_a");
    }
  };

  const wishlistOpen = () => {
    if (wishListOpen === "sidebar__act") {
      setwishListOpen("sidebar__");
      setcloseBtnwStyle("close_btn_w");
      setoverlayS("overlay_slider");
    } else {
      setwishListOpen("sidebar__act");
      setcloseBtnwStyle("close_btn_w_tra");
      setoverlayS("overlay_slider_a");
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(baseURL + "/allCategories");
      const { data } = response.data;
      dispatch(getCategories(data));
      // setCatalog(data);
    } catch (error) {}
  };

  const fetchCurrency = async () => {
    try {
      const response = await axios.get(baseURL + "/allCurrency");
      const { data } = response.data;
      setCurrencyOptions(data);
      dispatch(getCurrency(data[0]));
      setSelectedCurrency(data[0]);
    } catch (error) {}
  };
  useEffect(() => {
    fetchCurrency();
    fetchCategories();
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const { compareItems, compareItemsTotalQuantity } = useSelector(
    (state) => state.compareProduct
  );
  const { cartItems, cartTotalQuantity } = useSelector((state) => state.cart);
  const { categories } = useSelector((state) => state.category);
  const { wishItems, wisthTotalQuantity } = useSelector(
    (state) => state.wishlist
  );
  const cart = useSelector((state) => state.cart);

  useEffect(() => {
    dispatch(getTotals());
    dispatch(getTotalsCompare());
    dispatch(getTotalsWishlist());
  }, [cart, compareItems, wishItems, dispatch]);




  const handleCurrencySelect = (val) => {
    setSelectedCurrency(val);
    const item = {
      id: parseInt(val.id),
      abbreviation: val.abbreviation,
    };
    dispatch(getCurrency(item));
    dispatch(clearCompareAll());
    dispatch(clearCartAll());
    dispatch(clearWhislistAll());
    navigate("/");
  };
  return (
    <>
      <section className="container">
        <div style={{marginBottom:"40px"}} className="top__bar pt-2">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="position-relative currenCy_main">
                <img
                  className="me-2"
                  src={
                    imageUrl + `/${selectedCurrency && selectedCurrency.image}`
                  }
                  height={15}
                  width={25}
                  alt=""
                />
                <span>
                  {selectedCurrency && selectedCurrency.name}{" "}
                  {selectedCurrency && selectedCurrency.abbreviation}
                </span>
                <i className="fa-solid fa-chevron-down ms-2"></i>
                <div className="currency_hoverr_content">
                  {CurrencyOptions.map((val, ind) => (
                    <div
                      key={ind}
                      className="currency_Sub_content"
                      onClick={() => handleCurrencySelect(val)}
                    >
                      <img
                        className="me-2"
                        src={imageUrl + `/${val.image}`}
                        height={15}
                        width={25}
                        alt=""
                      />
                      <span>{val.name}</span>
                      <span>({val.abbreviation})</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {!token && (
              <div className="position-relative login__Regs">
                <i className="fa-regular fa-circle-user" />
                <span data-bs-toggle="modal" data-bs-target="#exampleModal">
                  &nbsp; Login / Register
                </span>
              </div>
            )}
            {token && (
              <div className="position-relative login__Regs">
                <i className="fa-regular fa-circle-user" />
                <span>&nbsp; My Account</span>
                <div className="login_content">
                  <div>
                    <ul>
                      <li>
                        <h6>Hello, {name}!</h6>
                      </li>
                      <li>
                        <Link to="/my-account">Dashboard</Link>
                      </li>
                      <li>
                        <Link to="/orders">Orders</Link>
                      </li>
                      <li>
                        <Link to="/downloads">Downloads</Link>
                      </li>
                      <li>
                        <Link to="/addresses">Addresses</Link>
                      </li>
                      <li className="border-bottom">
                        <Link to="/edit-account">Account details</Link>
                      </li>
                      <li className="border-bottom">
                        <Link to="/reset-password">Reset Password</Link>
                      </li>
                      <li className="py-2">
                        <span onClick={handleLogout}>
                          <i className="fa-solid fa-right-from-bracket"></i>{" "}
                          Logout
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <header id="header"  className="container p-2 mt-3 d-block d-lg-none">
        <section className="d-flex align-items-center justify-content-between">
          <div>
            <i onClick={mobnav_handler} className="fa-solid fa-bars mob_nav_icons"></i>
            <i onClick={searchhandle} className="fa-solid fa-magnifying-glass mob_nav_icons ms-2"></i>
            </div>
          <div>
          <Link to="/">
              <img src={Logo} alt="logo" height={40} width={50} />
            </Link>
          </div>
          <div>
            {!token &&    
          <i data-bs-toggle="modal" data-bs-target="#exampleModal" className="fa-regular fa-circle-user mob_nav_icons me-3"></i>
            }
            {token &&    
            <Link to="/my-account"><i className="fa-regular fa-circle-user mob_nav_icons me-3"></i></Link>
            }
                      <span className="position-relative">
              {cartItems.length > 0 && (
                <span className="quan_num">{cartTotalQuantity}</span>
              )}
              <i
                onClick={cartOpen}
                className="fa-solid fa-bag-shopping fs-4 i_hover_curs me-3"
              />
            </span>
          {/* <i className="fa-solid fa-bag-shopping mob_nav_icons"></i> */}
          </div>
        </section>
      </header>
      {/* ======= Header ======= */}
      <header id="header" className="d-none d-lg-block">
        <div className="container d-flex align-items-center justify-content-between position-relative">
          <h1 className="logo me-sm-auto me-md-0">
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </h1>
          {/* Uncomment below if you prefer to use an image logo */}
          {/* <a href="index.html" className="logo me-auto"><img src="assets/img/logo.png" alt="" className="img-fluid"></a>*/}
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <NavLink to="/" className="nav-link scrollto">
                  <p className="position-relative nav_bottom">Home</p>
                </NavLink>
              </li>

              {categories &&
                categories.map((value, index) => {
                  const { sub_categories } = value;
                  const main_cat_name = value.slug;
                  if (sub_categories.length > 0) {
                    // Check if sub_categories array is not empty
                    return (
                      <li className="dropdown" key={index}>
                        <a>
                        <p className="position-relative nav_bottom">
                          <span>{value.name}</span>{" "}
                          </p>
                          <i className="fa-solid fa-chevron-down" />
                          
                        </a>
                        <ul className="head_nav_drop">
                          <div>
                            <div className="row">
                              {sub_categories.map((val, index) => {
                                const { categories } = val;
                                return (
                                  <div className="col-md-4" key={index}>
                                    <Link
                                      to={`/product-category/${main_cat_name}`}
                                      state={{ id: value.id, name: val.name }}
                                    >
                                      <h6>{val.name}</h6>
                                    </Link>
                                    {categories.map((value, index) => (
                                      <NavLink
                                        to={`/product-category/${main_cat_name}/${value.slug}`}
                                        state={{
                                          id: value.id,
                                          name: `${main_cat_name} ${value.name}`,
                                        }}
                                        key={index}
                                      >
                                        <span className="mb-2">
                                          {value.name}
                                        </span>
                                      </NavLink>
                                    ))}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </ul>
                      </li>
                    );
                  } else {
                    // Render category without dropdown
                    return (
                      <li key={index}>
                        <NavLink
                          to={`/product-category/${main_cat_name}`}
                          className="nav-link scrollto"
                        >
                          {value.name}
                        </NavLink>
                      </li>
                    );
                  }
                })}

              <li>
                <NavLink
                  to={`/product-category/house-hold`}
                  className="nav-link scrollto"
                >
                  <p className="position-relative nav_bottom">
                  House Hold
                  </p>
                </NavLink>
              </li>
              <li>
                <NavLink to="/contact-us" className="nav-link scrollto">
                <p className="position-relative nav_bottom">
                  Contact Us
                  </p>
                </NavLink>
              </li>
            </ul>
            {/* <div>
      <i className="fa-solid fa-cart-shopping"></i>
    </div> */}
            <i className="fa-solid fa-bars mobile-nav-toggle" />
          </nav>
          {/* .navbar */}
          <div className="d-flex">
            <span className="position-relative">
              {cartItems.length > 0 && (
                <span className="quan_num">{cartTotalQuantity}</span>
              )}
              <i
                onClick={cartOpen}
                className="fa-solid fa-bag-shopping fs-4 i_hover_curs me-3"
              />
            </span>

            <span className="position-relative">
              {wishItems.length > 0 && (
                <span className="quan_num">{wisthTotalQuantity}</span>
              )}
              <i
                onClick={wishlistOpen}
                className="fa-regular fa-heart fs-4 i_hover_curs me-3"
              />
            </span>
            <span className="position-relative">
              {compareItems.length > 0 && (
                <span className="quan_num">{compareItemsTotalQuantity}</span>
              )}
              <i
                onClick={comparehandle}
                className="fa-solid fa-arrows-rotate fs-4 i_hover_curs me-3"
              />
            </span>
            <i onClick={searchhandle} className="fa-solid fa-magnifying-glass fs-4 i_hover_curs" />
          </div>
        </div>
      </header>
      {/* End Header */}
      <LoginModal />
      {/* -------Card_Slider--Start---- */}
      <CartToggle
        cartShow={cartShow}
        cartOpen={cartOpen}
        closeBtnStyle={closeBtnStyle}
        overlayS={overlayS}
      />
      {/* -------Card_Slider--end---- */}

            {/* -------Mob-Nav--Start---- */}
            <MobNavSlider
        mobNavShow={mobNavShow}
        mobnav_handler={mobnav_handler}
        closebtnmobNav={closebtnmobNav}
        overlayS={overlayS}
        catalog={categories}
      />
      {/* -------Mob-Nav--end---- */}

      {/* -------Compare_Slider--Start---- */}
      <CompareToggle
        comparehandle={comparehandle}
        compareShow={compareShow}
        overlayS={overlayS}
      />
      {/* -------Compare_Slider--end---- */}

      {/* -------Wish-List--Start---- */}
      <WishlistToggle
        wishlistOpen={wishlistOpen}
        overlayS={overlayS}
        wishListOpen={wishListOpen}
        closeBtnwStyle={closeBtnwStyle}

        // wishlistOpen={wishlistOpen}
      />
      {/* -------Wish-List--end---- */}
      {/* --------Search-Slider-Start-------    */}
      <SearchToggle
            searchhandle={searchhandle}
            searchShow={searchShow}
            overlayS={overlayS}
      />
      {/* --------Search-Slider-End-------    */}
    </>
  );
};

export default Header;
