import React from 'react'
import { addToCart, decreaseCart, removeFromCart } from '../redux/features/slices/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { imageUrl } from '../config/apiUrl';
import { Link } from 'react-router-dom';
import Bori from "../assets/images/bori.jpg";


const CartToggle = ({cartShow,cartOpen,closeBtnStyle,overlayS}) => {

  const { cartItems, cartTotalAmount } = useSelector((state) => state.cart);
  const { currency_name } = useSelector((state) => state.currency);

  const dispatch = useDispatch();

    const handleincreaseCart = (product) => {
        dispatch(addToCart(product));
      };
      const handleDecreaseCart = (product) => {
        dispatch(decreaseCart(product));
      };
      const handleRemoveFromCart = (product) => {
        dispatch(removeFromCart(product));
      };
  return (
    <>
    <section>
        <div className={cartShow}>
          <div className="container">
            <div className={closeBtnStyle} onClick={cartOpen}>
              <i className="fa-solid fa-chevron-right"></i>
            </div>
            <h3 className="text-center mt-4">My Cart</h3>
            <div className="px-4 ">
              <div className="cart__main_scr">
                {cartItems.length === 0 && (
                  <div className="text-center mt-5">
                    <i className="fa-solid fa-bag-shopping no_cart_bag" />
                    <p className="no_pro__p my-3">No products in the cart.</p>
                    <button className="check_out_btn" onClick={cartOpen}>
                      RETURN TO SHOP
                    </button>
                  </div>
                )}
                {cartItems.map((value, index) => {
                  const amount =
                    value.discount_price === ""
                      ? parseFloat(value.price).toFixed(2)
                      : parseFloat(value.discount_price).toFixed(2);
                  return (
                    <div
                      className="cart_data__ mb-3 d-flex justify-content-around"
                      key={index}
                    >
                      <img src={imageUrl + `/${value.image[0].image}`} />
                      <span className="shop_del_item shop__del_two" onClick={() => handleRemoveFromCart(value)}>
                        <i
                          className="fa-solid fa-xmark"
                        ></i>
                      </span>
                      <div>
                        <p className="ms-2 mb-2">{value.name}</p>
                        <div className="d-flex align-items-center">
                          <div className="quantity cart_quan">
                            <input
                              disabled
                              value={value.cartQuantity}
                              type="text"
                            />
                            <a
                              className="plus"
                              onClick={() => handleincreaseCart(value)}
                            >
                              +
                            </a>
                            <a
                              className="minus"
                              onClick={() => handleDecreaseCart(value)}
                            >
                              -
                            </a>
                          </div>
                          <div>
                            x{" "}
                            <span className="fw-lighter">
                              {currency_name} {amount}
                            </span>
                          </div>
                          <div className="ms-3 fw-bold">
                            {currency_name} {amount * value.cartQuantity}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {cartItems.length > 0 && (
                <div>
                  <div className="d-flex justify-content-between sub_tot">
                    <p>Subtotal</p>
                    <p>
                      {currency_name} {cartTotalAmount.toFixed(2)}
                    </p>
                    {/* <p>AED 780.00</p> */}
                  </div>
                  <Link to="/shopping-cart">
                    <button className="view_cart_" onClick={cartOpen}>VIEW CART</button>
                  </Link>
                  <Link to="/checkout">
                    {" "}
                    <button className="check_out_btn">CHECKOUT</button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
        <div onClick={cartOpen} className={overlayS}></div>
      </section>
    </>
  )
}

export default CartToggle