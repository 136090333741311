import { createSlice } from "@reduxjs/toolkit";
import { act } from "@testing-library/react";
import { toast } from "react-toastify";

const initialState = {
  compareItems:  [],
  compareItemsTotalQuantity: 1,
  cartTotalAmount: 0,
};

const compareProductSlice = createSlice({
  name: "compareCart",
  initialState,
  reducers: {
    addToCompareCart(state, action) {
      const existingIndex = state.compareItems.findIndex(
        (item) => item.id === action.payload.id
      );
    
      if (existingIndex >= 0) {
        state.compareItems[existingIndex] = {
          ...state.compareItems[existingIndex],
          // cartQuantity: state.compareItems[existingIndex].cartQuantity + 1,
        };
        toast.info("Product Already Added Into Compare", {
          position: "bottom-right",
        });
      } else {
        if (state.compareItems.length >= 4) {
          toast.error("Only 4 products can be compared at a time", {
            position: "bottom-right",
          });
          return; // stop execution
        }
    
        let tempProductItem = {
          ...action.payload,
          cartQuantity: action.payload.num || +1,
        };
        state.compareItems.push(tempProductItem);
        toast.success("Product Added to Compare", {
          position: "bottom-right",
        });
      }
    },

    removeFromCompareCart(state, action) {
      state.compareItems.map((compareItems) => {
        if (compareItems.id === action.payload.id) {
          const nextcompareItems = state.compareItems.filter(
            (item) => item.id !== compareItems.id
          );

          state.compareItems = nextcompareItems;

          toast.error("Product Remove From Compare", {
            position: "bottom-right",
          });
        }
        return state;
      });
    },
    getTotalsCompare(state, action) {

      let { total, quantity } = state.compareItems.reduce(
        (cartTotal, compareItems) => {
          const { price, cartQuantity } = compareItems;

        const itemTotal = parseInt(price) * cartQuantity;

        cartTotal.total += itemTotal;
        cartTotal.quantity += cartQuantity;

        return cartTotal;
      },
      {
        total: 0,
        quantity: 0,
      }
    );

    total = parseFloat(total.toFixed(2));
    state.compareItemsTotalQuantity = quantity;
    state.cartTotalAmount = total;
  },

  clearCompare(state, action) {
    state.compareItems = [];
    // localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    toast.error("Remove all products", { position: "bottom-right" });
  },
  clearCompareAll(state, action) {
    state.compareItems = [];
  },

  },
});

export const { addToCompareCart,  removeFromCompareCart,getTotalsCompare,clearCompare,clearCompareAll } =
  compareProductSlice.actions;

export default compareProductSlice.reducer;

