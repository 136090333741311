import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import axios from 'axios';
import { baseURL } from '../config/apiUrl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function valuetext(value) {
  return `${value}°C`;
}

const minDistance = 10;

export default function MinimumDistanceSlider() {
  const [value, setValue] = React.useState([0, 1000]);
  const { currency_id,currency_name } = useSelector((state) => state.currency);

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 1000 - minDistance);
        setValue([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], 0 + minDistance);
        setValue([clamped - minDistance, clamped]);
      }
    } else {
      setValue(newValue);
    }
  };

  const navigate = useNavigate()

  const handleCommittedChange =  (event, newValue) => {
    const FormData = require('form-data');
    let data = new FormData();
    data.append('min', newValue[0]);
    data.append('max', newValue[1]);
    
    let config = {
      method: 'post',
      url: baseURL + `/getProductsByPriceRange/${currency_id}`,
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      const { data } = response.data;
      navigate(`/product-category/?min_price=${newValue[0]}&max_price=${newValue[1]}` , {state:{product:data}})
    })
    .catch((error) => {
      console.log(error);
    });
    

  };

  return (
    <Box sx={{ width: 250 }}>
    <Slider
      getAriaLabel={() => 'Minimum distance shift'}
      value={value}
      onChange={handleChange}
      onChangeCommitted={handleCommittedChange}
      valueLabelDisplay="auto"
      getAriaValueText={valuetext}
      disableSwap
      min={0}
      max={1000}
    />
    <p className='mb-5'>Price: {currency_name} {value[0]} - {currency_name} {value[1]}</p>
  </Box>
  );
}