import React, { useMemo, useState } from 'react'
import { clearCompare } from '../redux/features/compare/compareProductSlice';
import { useDispatch, useSelector } from 'react-redux';
import compare_Empty from "../assets/images/compare_empty_img.png";
import { Link, useNavigate } from 'react-router-dom';
import { baseURL, imageUrl } from '../config/apiUrl';
import axios from 'axios';
import Bori from "../assets/images/bori.jpg";

const SearchToggle = ({searchShow,searchhandle,overlayS}) => {
  const [product, setProduct] = useState([]);
  const [value, setValue] = useState();
  const { currency_id,currency_name } = useSelector((state) => state.currency);

const navigate = useNavigate()
  
  
  useMemo(() => {
    const handleSearch = async () => {
      // setLoading(true)
      try {
        var FormData = require("form-data");
        var searchValue = new FormData();
        searchValue.append("keyword",value);
        const response = await axios.post(baseURL + `/fullSearch/${currency_id}`,searchValue);
        const { data } = response.data;
        setProduct(data);
        // setLoading(false)
      } catch (error) {
        // setLoading(false)
      }
    };
  
    if (value !== undefined && String(value).length >= 3) {
      handleSearch();
    }
  }, [value]);

  const handleClick = async () => {
    // setLoading(true)
    try {
      var FormData = require("form-data");
      var searchValue = new FormData();
      searchValue.append("keyword",value);
      const response = await axios.post(baseURL + `/fullSearch/${currency_id}`,searchValue);
      const { data } = response.data;
      // setProduct(data);
      navigate(`/product-category/search`, {
        state: 
         { data:data,
           value:value
          }})
          searchhandle()
      // setLoading(false)
    } catch (error) {
      // setLoading(false)
    }
  };



  const handleViewAllProduct = () => {
    navigate(`/product-category/search`, {
      state: 
       { data:product,
         value:value
        }})
        searchhandle()
  }

  const goToProduct = (value) =>{
    navigate(`/shop/${value.main_cat_slug}/${value.cat_slug}/${value.slug}`, {
      state: 
       { data: value
        }})
        searchhandle()
  }
  return (
    <>
     <section>
        <div className={searchShow}>
          <div className=" position-relative">
            <div onClick={searchhandle} className="close_compare">
              <i className="fa-solid fa-xmark"></i>
            </div>


          </div>
          <div className='search_sub_div'>
            <input type="email" placeholder="I'm shopping for ..." className="search_input" onChange={(e)=>setValue(e.target.value)}/>
            <i className="fa-solid fa-magnifying-glass"  onClick={handleClick}/>
            </div>
            <div className='search_scrollss'>
            <div className='container'>
              <div className='row'>
                {value !=="" && product.length===0 && 
                <div className='text-center'>Sorry, No result match your search.</div>}
                {product && product.slice(0, 4).map((value,index)=>{
                  // const discount = ((value.price - value.discount_price) / value.price) * 100;
                  const amount =
                    value.discount_price === ""
                      ? parseFloat(value.price).toFixed(2)
                      : parseFloat(value.discount_price).toFixed(2);
                  return(
                    <div className='col-md-3 col-6 search_div' key={index} onClick={()=>goToProduct(value)}>
                    <div>
                      <div>
                      <img
                        // src={imageUrl + `/${value.image[0].image}`}
                        src={Bori}
                        height={230}
                        width="100%"
                        alt="product-image"
                        style={{objectFit:"contain"}}
                      />
                      </div>
  
                      <div>
                        <p className='fw-bold'>{value.name}</p>
                        <p className='fw-lighter'>{currency_name} {amount}</p>
                      </div>
                    </div>
                    </div>
                  )
                })}
               
  
              </div>
              <div className='text-center my-5'>
                <button className='search_view_all' onClick={handleViewAllProduct}>View ALL</button>
              </div>
            </div>
            </div>

            
        </div>
        <div className={overlayS}></div>
      </section>
    </>
  )
}

export default SearchToggle