import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <section>
        <div className="container mt-3">
          <div style={{ fontSize: "14px" }}>
            <h3 className="mb-4">Terms of Use Policy</h3>
            <h3 className="mb-3">
              Who is responsible for managing my information?
            </h3>
            <p className="mb-3">
              This Privacy Policy describes how we collect and uses your
              Personal Information when you visit the site. We are responsible
              for collecting and adequately managing any personal information
              you submit. We will keep your details secure and use the
              information you provide consistently with applicable privacy and
              data protection laws and the terms of this Privacy Policy.
              Throughout the site, the terms “we,” “us,” and “our” refer to Haji
              Ukay Ukay.
            </p>
            <h3 className="mb-3">
              What information about customers do you collect?
            </h3>
            <p className="mb-3">
              We collect information to provide better services to all those
              with whom we deal, such as;
            </p>
            <ul>
              <li>
                Personal information, including name, mobile or phone numbers,
                and email address
              </li>
              <li>
                Demographic information, including age, gender, and location
              </li>
              <li>
                Banking information of your credit card or debit card as payment
                mode to process your orders.
              </li>
            </ul>

            <h3 className="mb-3">How do we use your information?</h3>
            <p className="mb-3">
              The information you give can be used in many ways, such as:
            </p>
            <ul>
              <li>
                Complete a payment transaction or return initiated by you.
              </li>
              <li>Send an e-mail receipt or text message.</li>
              <li>To personalize repeat visits to our website</li>
              <li>
                Maintaining records of customers’ purchase history and activity
              </li>
              <li>Manage our loyalty or rewards program.</li>
              <li>Fraud Check and Risk Management. </li>
              <li>
                To let you know about other products and services you may be
                interested in.{" "}
              </li>
              <li>Handles client service requests.</li>
            </ul>
            <h3 className="mb-3">
              How do you store and protect the information you have collected?
            </h3>
            <p className="mb-3">
              We work hard to safeguard our site and any information we hold
              against unauthorized access or modification, disclosure or
              destruction. Particularly in reviewing our information collection,
              storage and handling practices, including physical security
              measures, to protect against unauthorized access to systems.
              However, we cannot guarantee the safety of your Information
              submitted to our site and any transmission is at your own risk.
            </p>
            <h3 className="mb-3">
              How you can access and update your information?
            </h3>
            <p className="mb-3">
              You can view and modify your personal, demographic and payment
              information through our site. You can also access your previous
              confirmed or cancelled orders with ease.
            </p>

            <h3 className="mb-3">How do we use cookies?</h3>
            <p className="mb-3">
              A cookie is a small file that asks permission to be placed on your
              computer’s hard drive. Once you agree, the file is added, and the
              cookie helps analyse web traffic or lets you know when you visit a
              particular site. Cookies allow web applications to respond to you
              as an individual. The web application can tailor its operations to
              your needs, likes, and dislikes by gathering and remembering
              information about your preferences.
            </p>
            <p className="mb-3">
              We use traffic log cookies to identify which pages are being used.
              It helps us analyse data about web page traffic and improve our
              website to tailor it to customer needs. We only use this
              information for statistical analysis purposes, and then the data
              is removed from the system.
            </p>
            <p className="mb-3">
              Overall, cookies help us provide you with a better website by
              enabling us to monitor which pages you find useful and which you
              do not. A cookie in no way gives us access to your computer or any
              information about you other than the data you choose to share with
              us. You can choose to accept or decline cookies. Most web browsers
              automatically accept cookies, but you can usually modify your
              browser setting to decline cookies if you prefer. It may prevent
              you from taking full advantage of the website.
            </p>
            <h3 className="mb-3">Who has an access to your information?</h3>
            <p className="mb-3">
              We share your personal information to our employees, group of
              companies, legal and professional advisors on the basis of our
              instructions and in accordance with our Privacy Policy and any
              other appropriate confidentiality and security measures.
            </p>
            <p className="mb-3">
              Our website may include links to other websites. However, once you
              have used those links to leave our site, you should note that we
              have no control over that other site. Therefore, we cannot be
              responsible for the protection and confidentiality of the
              information you provide when you visit such sites and such sites
              are not covered by this Privacy Statement.
            </p>
            <h3 className="mb-3">How do we update this privacy policy?</h3>
            <p className="mb-3">
              Our Privacy Policy may change from time to time. We will post any
              changes to the Privacy Policy on this page, and if required, we
              will provide a more visible notice.
            </p>
            <h3 className="mb-3">
              Whom can you contact if you have queries about this privacy
              policy?
            </h3>
            <p className="mb-3">
              Please submit any questions, concerns, or comments about this
              Privacy Policy or requests concerning your personal information by
              email to support@Hajiukayukay.com.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
