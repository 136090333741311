import React, { useEffect, useState } from 'react'
import { baseURL, imageUrl } from '../config/apiUrl';
import axios from 'axios';
import { useSelector } from 'react-redux';
import ProductCard from './cards/ProductCard';

const FeaturedItems = () => {
  const { currency_id } = useSelector((state) => state.currency);
  const [products, setProducts] = useState([]);

  const fetchFeatureProducts = async () => {
    try {
      const response = await axios.get(baseURL + `/getFeaturedProducts/${currency_id}`);
      const { data } = response.data;
      setProducts(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if(currency_id){
      fetchFeatureProducts();
    }
  }, [currency_id]);

  return (
    <>
      <section>
        <div className="container my-5">
          <section className="panel panel-default">
            <div className="panel-heading">
              <h3 className="home_heading">Featured Items</h3>
            </div>
            <div className="panel-body">

              <div className="row">
                <ProductCard
                  products={products}
                />
              </div>

            </div>
          </section>
        </div>
      </section>
    </>
  )
}

export default FeaturedItems