import React from "react";

const TermCondition = () => {
  return (
    <>
      <section>
        <div className="container mt-3">
          <div style={{ fontSize: "15px" }}>
            <h4 className="mb-4">Terms & Conditions</h4>
            <h3 className="mb-3">Introduction</h3>
            <p className="mb-3">
              This website is owned by Al Khat Al Abyadh General Trading LLC,
              registered in the United Arab Emirates, located in Industrial Area
              # 12, Sharjah, with a license number of 53937. Throughout the
              site, the terms “we,” “us,” “site,” and “our” refer to Haji ukay
              ukay.
            </p>
            <p className="mb-3">
              By visiting our site and/or purchasing something from us, you
              engage in our “Service” and accept to be bound by the following
              terms and conditions (“Terms of Service,” “Terms”), including such
              additional terms and conditions and policies. If you do not agree
              to all the terms and conditions of this agreement, then you may
              not access the website or use any services.
            </p>
            <p className="mb-3">
              Please read these Terms of Service carefully before accessing or
              using our website. You agree to be bound by these Terms of Service
              by accessing or using any part of the site. If you do not agree to
              all the terms and conditions of this agreement, then you may not
              access the website or use any services. If these Terms of Service
              are considered an offer, acceptance is expressly limited to these
              Terms of Service.
            </p>
            <h3 className="mb-3">Registration</h3>
            <p className="mb-3">
              You must be of legal age and required to enter personal
              information to proceed with registration. Incomplete details are
              subject to declination without notice.
            </p>

            <h3 className="mb-3">Privacy & Security</h3>
            <p className="mb-3">
              You are entirely responsible for maintaining the confidentiality
              of your password and account. Furthermore, you are fully
              responsible for any activities under your account. You agree to
              notify us immediately of any unauthorized use of your account or
              any other security breach.
            </p>
            <p className="mb-3">
              We cannot guarantee that unauthorized third parties will never be
              able to defeat the security measures or use for improper purposes
              any information which relates to you and allows you to be
              identified (“Personal Information”). You acknowledge that you
              provide your Personal Information at your own risk. Please refer
              to our Privacy Policy.
            </p>
            <h3 className="mb-3">Order Placement</h3>
            <p className="mb-3">
              Once your items have been placed, the customer will be notified
              through email, then subject to your rights to cancel the product
              before the collection schedule of the courier and are subject to
              return or exchange only according to our Return Policy.
              International customers must transfer money within seven (7)
              business days, otherwise the transaction will be immediately
              cancelled without prior notice.
            </p>
            <p className="mb-3">
              The products in your cart are not reserved before being placed. We
              do not guarantee that the product will be available within a short
              period. The production takes a week, and the item’s fulfillment
              depends on the raw materials.
            </p>

            <h3 className="mb-3">Delivery Terms</h3>
            <p className="mb-3">
              We will provide the arrangement of the delivery of the product.
              The delivery fee will be calculated once the delivery address is
              added and displayed upon checkout.
            </p>
            <p className="mb-3">
              The expected delivery date or time is shown on our website is
              based on an estimate or inaccurate; this is due to a third-party
              factor that may cause delays.
            </p>
            <p className="mb-3">
              Regarding return items, once your return request has been
              confirmed, the courier will pick up the items within five working
              days, free of charge. You must comply with the requirements for
              approval.
            </p>

            <h3 className="mb-3">Payment Terms</h3>
            <p className="mb-3">
              We currently accept the following forms of payment:
            </p>
            <ul>
              <li>Mastercard</li>
              <li>Visa (Debit and Credit)</li>
              <li>
                Direct Bank Transfer
                <p>-Local Transfer: Emirates NBD</p>
                <p>-International Transfer: Habib Bank Zurich</p>
              </li>
              <li>Cash on delivery (UAE-only)</li>
              <li>Pay By Link</li>
            </ul>
            <p className="mb-3">
              Payment can be made using only the methods above, and the complete
              order amount will be taken immediately except for the cash on
              delivery. You will be billed for and pay all fees. You shall pay
              all applicable taxes relating to the use of the site through your
              account. We are not liable for any extra bank fees deducted from
              you.
            </p>
            <p className="mb-3">
              For a direct bank transfer, please submit a copy of proof of
              receipt to any of the following via email sales@hajiukayukay.com
              or WhatsApp at +971 6 546 1180 for us to track your payment.
              International wire transfer often takes up to three days for the
              funds to become available in our bank, only then can we start
              processing your order.
            </p>
            <p className="mb-3">
              Keep your contact information, billing information, and
              credit/debit card information up-to-date. All payment obligations
              are non-cancellable, and all amounts paid are non-refundable,
              except as specified in the Returns and Refund Policy. The return
              is only approved upon providing the Return Request via email, and
              the refund applies only after checking the returned item. These
              options are available for a refund; credit/debit card, bank
              transfer, and cash collection from our office.
            </p>
            <h3 className="mb-3">Invoice</h3>
            <p className="mb-3">
              A tax invoice will be generated upon completion of the order.
              Also, be sent to the registered email address of the customer.
            </p>

            <h3 className="mb-3">Promotion/Voucher</h3>
            <p className="mb-3">
              The promotion’s validity will be flashed through our website. When
              adding a coupon or promotional code, it will directly calculate if
              it is valid; else, it will display an error message if it has
              expired.
            </p>

            <h3 className="mb-3">Billing Information Terms</h3>
            <p className="mb-3">
              In our sole discretion, we may limit or cancel the quantities
              purchased per person, household, or order, including orders placed
              by or under the same customer account, the same credit card, or
              orders using the same billing or shipping address.
            </p>

            <h3 className="mb-3">Product Information</h3>
            <p className="mb-3">
              The product shown on our website may have a limited quantity. We
              will display according to the availability and indicate “sold out”
              if the item is no longer in stock. All descriptions of products or
              product pricing are subject to change without notice, at the sole
              discretion of us.
            </p>
            <p className="mb-3">
              There may be information on our website that contains
              typographical errors and inaccuracies that may refer to product
              descriptions, prices, promotions, deals, product delivery costs,
              delivery times, and availability. We reserve the right to correct
              errors, modify or update information, or discontinue any product.
            </p>

            <h3 className="mb-3">Liability Limitations</h3>
            <p className="mb-3">
              You acknowledge and agree that you assume the entire risk arising
              from your access or use of the site, including licit and illicit
              services. To the fullest extent permitted by applicable law, in no
              event shall Haji Ukay Ukay, its affiliates, directors, employees,
              agents, licensees, successors, and assigns be liable for any
              special, direct, indirect, or consequential damages or any other
              damages of any kind, including but not limited to loss of use,
              loss of profits or loss of data, whether in action in contract,
              tort, or otherwise, arising out of or in any way connected with
              the use of or inability to use the site, including any damages
              caused by or resulting from reliance by a User on any information
              obtained from the site, or that result from mistakes, omissions,
              interruptions, deletion of files or email, errors, defects,
              viruses, delays in operation or transmission or any failure of
              performance.
            </p>

            <h3 className="mb-3">Disclaimers</h3>
            <p className="mb-3">
              This is provided to you “as is” without any warranties. To the
              fullest extent permissible under applicable law, Haji Ukay Ukay
              disclaims all such warranties, express or implied, including
              warranties of merchantability, fitness for a particular purpose,
              non-infringement, accuracy, freedom from errors, suitability of
              the content, or availability. We may update all the information on
              this site without prior notice. While we do everything to ensure
              that the information presented on the site is complete and
              accurate. Although, we cannot guarantee that such information is
              free from errors, omissions, or inaccuracies. We make no warranty
              regarding the quality of any products, services, or content
              purchased or obtained through the Platform.
            </p>

            <h3 className="mb-3">Third-Party Links</h3>
            <p className="mb-3">
              You may have the ability to use and/or access content provided by
              third parties. Please review the third-party’s policies and
              practices carefully and ensure you understand them before engaging
              in any transaction. We disclaim any responsibility or liability
              related to your access or use of any third-party content.
            </p>

            <h3 className="mb-3">Governing Law</h3>
            <p className="mb-3">
              This agreement shall be governed by and construed under the laws
              of the United Arab Emirates, without its conflict of law rules.
            </p>

            <h3 className="mb-3">Changes to terms of service</h3>
            <p className="mb-3">
              We reserve the right to update, change, or replace any part of
              these Terms of Service by posting updates and changes to our
              website at our discretion. It is your duty to periodically check
              our website to see if there are any changes. We are not
              responsible for you or a third party for any modification,
              suspension, or interruption of the service. You can review this
              page’s most current version of the Terms of Service.
            </p>

            <h3 className="mb-3">Contact Information</h3>
            <p className="mb-3">
              Questions about the Terms of Service should be sent to us at
              support@hajiukayukay.com.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermCondition;
