import React from 'react'
import Logo from './../assets/images/logo.jpg'
import foot_ban from './../assets/images/foot_ban.png'
import { Link } from 'react-router-dom'




const Footer = () => {
  return (
    <>
  {/* ======= Footer ======= */}
  <section>
    <div className="container">
      <img src={foot_ban} className="w-100" alt="" />
    </div>
  </section>
  <footer>
    <div className="footer-content">
      <img src={Logo} alt="" />
      <p>Clothing Store</p>
      <ul className="socials">
        <li>
          <a href="#">
            <i className="fa-brands fa-facebook" />
          </a>
        </li>
        <li>
          <a href="#">
            <i className="fa-brands fa-instagram" />
          </a>
        </li>
        <li>
          <a href="#">
            <i className="fa-brands fa-youtube" />
          </a>
        </li>
        <li>
          <a href="#">
            <i className="fa-brands fa-tiktok" />
          </a>
        </li>
        <li>
          <a href="#">
            <i className="fa-brands fa-whatsapp" />
          </a>
        </li>
      </ul>
    </div>
    <div className="footer-bottom">
      <div className="footer-menu">
        <ul className="f-menu">
          <li>
            <a href="">Order Tracking</a>
          </li>
          <li>
            <a href="">Blog</a>
          </li>
          <li>
          <Link to="/privacy-policy"> Privacy Policy</Link>
          </li>
          <li>
            <Link to="/refund-and-returns">Refund and Returns</Link>
          </li>
          <li>
            <Link to="/term-condition"> Term &amp; Condition</Link>
          </li>
          <li>
            <Link to="/about-us">About Us</Link>
          </li>
          <li>
            <Link to="/contact-us">Contact Us</Link>
          </li>
        </ul>
      </div>
      <div>
        <p>
          © 2022 - All Right reserved! <span id="year" /> <a href="#" />{" "}
        </p>
      </div>
    </div>
  </footer>
  {/* <div id="sy-whatshelp">
    <div className="sywh-services">
      <a
        href=""
        className="messenger"
        data-tooltip="Livechat"
        data-placement="left"
        target="_blank"
      >
        <i className="fa fa-comments" />
      </a>
      <a
        href=""
        className="whatsapp"
        data-tooltip="WhatsApp"
        data-placement="left"
        target="_blank"
      >
        <i className="fa fa-whatsapp" />
      </a>
      <a href="" className="call" data-tooltip="Call" data-placement="left">
        <i className="fa fa-phone" />
      </a>
    </div>
    <a
      className="sywh-open-services"
      data-tooltip="Contact Us"
      data-placement="left"
    >
      <i className="fa-solid fa-message" />
      <i className="fa fa-times" />
    </a>
  </div> */}
          <div className="chat_super_main">
            

            <input className="chat-menu hidden" id="offchat-menu" type="checkbox" />
            <div className="sticky-button" id="sticky-button">
                <label htmlFor="offchat-menu">
                    <p className="chat-icon" viewBox="0 0 24 24">
                    <i className="fa-solid fa-message"></i>
                    </p>
                    <p className="close-icon" viewBox="0 0 24 24">
                        <i className="fa-solid fa-xmark"></i>
                        </p>
                </label>
            </div>
            <div className="sticky-chat">
                <div className="chat-content">
                    <div className="whatsapp_div">
                        <i className="fa-brands fa-whatsapp"></i>
                    </div>
                    <div className="messanger_div">
                        <i className="fa-brands fa-facebook-messenger"></i>
                    </div>
            </div>
        </div>
    </div>
  {/* End Footer */}


    </>
  )
}

export default Footer