import React, { useEffect } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import Bori from "../assets/images/bori.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  decreaseCart,
  getTotals,
  removeFromCart,
} from "../redux/features/slices/cartSlice";
// import { imageUrl } from "../config/apiUrl";
import { Link } from "react-router-dom";
import { imageUrl } from "../config/apiUrl";

const ShoppingCart = () => {
  const { cartItems, cartTotalAmount } = useSelector((state) => state.cart);
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const { currency_name } = useSelector((state) => state.currency);

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const handleincreaseCart = (product) => {
    dispatch(addToCart(product));
  };
  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };
  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCart(product));
  };

  return (
    <>
      <section className="container-fluid products_main_banner">
        <div className="container">
          <div className="banner_content">
            <h4>Shopping Cart</h4>
            <div>
              <Breadcrumb>
                <BreadcrumbItem>
                  <a href="#">Home</a>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </section>
      <section className="order__steps_mob">
        <div className="container-fluid">
          <div className="px-2 px-md-5">
            <div className="row">
            <p className="steps__heading text-center">SHOPPING CART</p>

            </div>
          </div>
        </div>
      </section>
      <section className="order__steps">
        <div className="container-fluid">
          <div className="px-2 px-md-5">
            <div className="row">
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <div>
                    <p className="steps_count__">01</p>
                  </div>
                  <div className="ms-2">
                    <p className="steps__heading">SHOPPING CART</p>
                    <p className="steps__subheading">Manage Your Items List</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <Link to="/checkout">
                  <div className="d-flex align-items-center order_sub_steps">
                    <div>
                      <p className="steps_count__">02</p>
                    </div>
                    <div className="ms-2">
                      <p className="steps__heading">CHECKOUT DETAILS</p>
                      <p className="steps__subheading">
                        Checkout Your Items List
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center order_sub_steps">
                  <div>
                    <p className="steps_count__">03</p>
                  </div>
                  <div className="ms-2">
                    <p className="steps__heading">ORDER COMPLETE</p>
                    <p className="steps__subheading">Review Your Order</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {cartItems.length > 0 && (
        <section className="cart__super_main">
          <div className="container-fluid">
            <div className="px-2 px-md-5">
              <div className="row">
                <div className="col-md-8">
                  <div className="table-responsive">
                    <table className="table custom__table_style">
                      <thead>
                        <tr>
                          <th scope="col" colspan="3">
                            PRODUCT
                          </th>
                          <th scope="col">PRICE</th>
                          <th scope="col">QUANTITY</th>
                          <th scope="col">TOTAL</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartItems.map((value, index) => {
                          const amount =
                            value.discount_price === ""
                              ? parseFloat(value.price).toFixed(2)
                              : parseFloat(value.discount_price).toFixed(2);
                          return (
                            <tr key={index}>
                              <td>
                                <span className="shop_del_item">
                                  <i
                                    className="fa-solid fa-xmark"
                                    onClick={() => handleRemoveFromCart(value)}
                                  ></i>
                                </span>
                              </td>
                              <td>
                                <img
                                 src={imageUrl + `/${value.image[0].image}`}
                                  // src={Bori}
                                  className=""
                                  alt="bori"
                                  height={80}
                                  width={60}
                                />
                              </td>
                              <td><p style={{width:"150px"}}>{value.name}</p></td>
                              <td>
                                {currency_name} {amount}
                              </td>
                              <td>
                                <div className="quantity">
                                  <input
                                    disabled
                                    value={value.cartQuantity}
                                    type="text"
                                  />
                                  <a
                                    className="plus"
                                    onClick={() => handleincreaseCart(value)}
                                  >
                                    +
                                  </a>
                                  <a
                                    className="minus"
                                    onClick={() => handleDecreaseCart(value)}
                                  >
                                    -
                                  </a>
                                </div>
                              </td>
                              <td>
                                <span className="shopp_total_amount">
                                  {currency_name} {(amount * value.cartQuantity).toFixed(2)}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                        {/* <tr>
                        <td>
                          <span className="shop_del_item">
                            <i className="fa-solid fa-xmark"></i>
                          </span>
                        </td>
                        <td>
                          <img
                            src={Bori}
                            className=""
                            alt="bori"
                            height={80}
                            width={60}
                          />
                        </td>
                        <td>Ladies Night Pajama</td>
                        <td>AED 325.00</td>
                        <td>
                          <div className="quantity">
                            <input disabled value="1" type="text" />
                            <a className="plus">+</a>
                            <a className="minus">-</a>
                          </div>
                        </td>
                        <td>
                          <span className="shopp_total_amount">AED 325.00</span>
                        </td>
                      </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="cart__totals">
                    <h5 className="cart_tota_heading">CART TOTALS</h5>
                    <div className="d-flex justify-content-between sub_tot_maIn">
                      <p>Subtotal</p>
                      <p>
                        {currency_name} {cartTotalAmount.toFixed(2)}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between sub_tot_maIn align-items-baseline">
                      <p>Shipping</p>
                      <p className="delivery__content">
                        Delivery within UAE Charges per Bundle: AED 15.00
                        <br />
                        <p className="delivery__content mt-3">
                          Shipping to{" "}
                          <span className="fw-bold">Abu Dhabi.</span>
                        </p>
                        {/* <div>
                          <div>
                            <div className="" id="accordionExample">
                              <div className="accordion-item">
                                <div
                                  className="change_address_head"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="false"
                                  aria-controls="collapseOne"
                                >
                                  Change Address
                                </div>

                                <div
                                  id="collapseOne"
                                  className="accordion-collapse collapse "
                                  aria-labelledby="headingOne"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="accordion-body">
                                    <div>
                                      <select
                                        className="form-select mb-2"
                                        aria-label="Default select example"
                                      >
                                        <option selected>
                                          Select a country / region…
                                        </option>
                                        <option value={1}>Bahrain</option>
                                        <option value={2}>Oman</option>
                                        <option value={3}>Philippines</option>
                                        <option value={4}>Qatar</option>
                                        <option value={5}>
                                          United Arab Emirates
                                        </option>
                                      </select>
                                      <select
                                        className="form-select mb-2"
                                        aria-label="Default select example"
                                      >
                                        <option selected>Fujairah</option>
                                        <option value={1}>Sharjah</option>
                                        <option value={2}>Dubai</option>
                                        <option value={3}>
                                          Ras Al Khaimah
                                        </option>
                                      </select>
                                      <input
                                        type="text"
                                        className="form-control mb-2"
                                        placeholder="City*"
                                      />
                                      <button className="change__address_btn">
                                        Update
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-baseline tot_maIn">
                      <h5>Total</h5>
                      <h4 className="tot__amOunt">
                        {currency_name} {cartTotalAmount.toFixed(2)}
                      </h4>
                    </div>
                    <div>
                      <Link to="/checkout">
                        <button className="proceed_to_cheCK">
                          Proceed to checkout
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {cartItems.length === 0 && (
        <div className="text-center mt-5 mb-4">
          <i className="fa-solid fa-bag-shopping no_cart_bag" />
          <p className="no_pro__pp my-3">Your cart is currently empty.</p>
          <p className="my-2">
            Before proceed to checkout you must add some products to shopping
            cart.<br/>
            You will find a lot of interesting products on our "Shop"
            page.
          </p>
          <Link to="/"><button className="return_to_Shop">RETURN TO SHOP</button></Link>
        </div>
      )}
    </>
  );
};

export default ShoppingCart;
