import { Button, Tooltip } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Bori from '../assets/images/bori.jpg'
import { baseURL, imageUrl } from '../config/apiUrl';
import { addToCart, getTotals } from '../redux/features/slices/cartSlice';
import ProductCards from './cards/RecomProductCards';
import RecomProductCards from './cards/RecomProductCards';
import { addToCompareCart } from '../redux/features/compare/compareProductSlice';
import { addToWishlist } from '../redux/features/wishlist/wishlistSlice';
import ProductLoader from './ProductLoader';
import CartToggle from "./CartToggle";

const Recommended = () => {
  const dispatch = useDispatch();
  const {currency_id,currency_name} = useSelector((state) => state.currency);
  const [loading, setLoading] = useState(false);

  const [products, setProducts] = useState([]);

  const [closeBtnStyle, setcloseBtnStyle] = useState("close_btn_tra");
  const [overlayS, setoverlayS] = useState("overlay_slider_a");

  const [cartShowToggle, setcartShowToggle] = useState("sidebar__act");

  const cartOpen = () => {
    if (cartShowToggle === "sidebar__act") {
      setcartShowToggle("sidebar__");
      setcloseBtnStyle("close_btn");
      setoverlayS("overlay_slider");
    } else {
      setcartShowToggle("sidebar__act");
      setcloseBtnStyle("close_btn_tra");
      setoverlayS("overlay_slider_a");
    }
  };

  const fetchProducts = async () => {
    setLoading(true)
    try {
      const response = await axios.get(baseURL + `/getProducts/${currency_id}`);
      const { data } = response.data;
      setProducts(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };


  let handleAddToCart = (product) => {
    let data = {
      ...product,
    };
    dispatch(addToCart(data));
    dispatch(getTotals());
    cartOpen()
  };
  const [numToShow, setNumToShow] = useState(8);

  const handleLoadMore = () => {
    setNumToShow(numToShow + 8);
  }
  let handleAddToCompare = (product) => {
    let data = {
      ...product,
    };
    dispatch(addToCompareCart(data));
  };

  let handleAddToWishlist = (product) => {
    let data = {
      ...product,
    };
    dispatch(addToWishlist(data));
  };

  const handleChangeProduct =async (name) =>{
    setLoading(true)
    if (name==="NEW-ITEMS") {
      try {
        const response = await axios.get(baseURL + `/getNewItems/${currency_id}`);
        const { data } = response.data;
        setProducts(data);
        setNumToShow(8)
      setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }else if (name==="SALE") {
      
      try {
        const response = await axios.get(baseURL + `/getDiscountedItem/${currency_id}`);
        const { data } = response.data;
        setProducts(data);
        setNumToShow(8)
      setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    
    else{
      try {
        const response = await axios.get(baseURL + `/getproductbyrecommendation/${name}/${currency_id}`);
        const { data } = response.data;
        setProducts(data);
        setNumToShow(8)
       setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
   
  }

  useEffect(() => {
    if(currency_id){
      fetchProducts();
    }
  }, [currency_id]);

  return (
    <>

    <div>

<section className="container my-4">
{/* <Tooltip title="Add" placement="left-end">
            <Button>left-end</Button>
          </Tooltip> */}
    <div>
      <h1 className="home_heading mb-3">Recommend For You</h1>
      <div>
        <ul
          className="nav nav-pills mb-4 d-flex justify-content-center"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={()=>handleChangeProduct("BEST-SELLER")}
            >
              BEST SELLER
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              onClick={()=>handleChangeProduct("MEN")}
            >
              MEN
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-contact-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-contact"
              type="button"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
              onClick={()=>handleChangeProduct("WOMEN")}
            >
              WOMEN
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-contact-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-sale"
              type="button"
              role="tab"
              aria-controls="pills-sale"
              aria-selected="false"
              onClick={()=>handleChangeProduct("SALE")}
            >
              SALE
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-contact-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-contact"
              type="button"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
              onClick={()=>handleChangeProduct("NEW-ITEMS")}
            >
              NEW ITEMS
            </button>
          </li>
        </ul>
        <div>
          <div>
            <section className="panel panel-default">
              <div className="panel-body">
                {/* products prodbox */}
                <div className="row ">
              {loading && <ProductLoader/>}
              {!loading && products.length === 0 && (
                    <div className="text-center mt-5">
                      <i className="fa-solid fa-bag-shopping no_cart_bag" />
                      <p className="no_pro__p my-3">
                        No products found.
                      </p>
                    </div>
                  )}
              {!loading &&
                  <RecomProductCards
                  products={products}
                  numToShow={numToShow}
                  handleAddToCart={handleAddToCart}
                  handleAddToCompare={handleAddToCompare}
                  handleAddToWishlist={handleAddToWishlist}
                  />
              }

                </div>
            
              </div>
              {numToShow < products.length &&
          <div className="load_more_btn text-center">
            <button onClick={handleLoadMore}>LOAD MORE ...</button>
          </div>
        }
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
    </div>
            {/* -------Card_Slider--Start---- */}
            <CartToggle
        cartShow={cartShowToggle}
        cartOpen={cartOpen}
        closeBtnStyle={closeBtnStyle}
        overlayS={overlayS}
      />
      {/* -------Card_Slider--end---- */}
    </>
  )
}

export default Recommended