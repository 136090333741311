import moment from "moment/moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { clearCartAll } from "../redux/features/slices/cartSlice";

const OrderReceived = () => {
  const {currency_name } = useSelector((state) => state.currency);
  const {state} =useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const backHome = () => {
    dispatch(clearCartAll());
    navigate("/");
  };

 
  useEffect(() => {
    const handleDisableBackButton = () => {
      window.history.pushState(null, "", window.location.href);
      backHome();
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleDisableBackButton);

    return () => {
      window.removeEventListener("popstate", handleDisableBackButton);
    };
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    dispatch(clearCartAll())
  }, [state])
  
  return (
    <>
      <section>
        <section className="container-fluid products_main_banner">
          <div className="container">
            <div className="banner_content">
              <h4>Order Received</h4>
              <div>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>

              </Breadcrumb>
            </div>
            </div>
          </div>
        </section>
        <div className="mt-4 mb-5">
          <div className="container">
            <div className="main__order_rec">
              <p className="order_thank_p">
                Thank you. Your order has been received.
              </p>
              <div>
                <ul className="order__ul_">
                  <li>
                    <p>Order Number</p>
                    <p className="orderthank_next">{state.orderID}</p>
                  </li>
                  <li>
                    <p>Date</p>
                    <p className="orderthank_next">{ moment().format('MMMM DD, YYYY')}</p>
                  </li>
                  <li>
                    <p>Total</p>
                    <p className="orderthank_next">{currency_name} {(state.amount).toFixed(2)}</p>
                  </li>
                  <li>
                    <p>Payment Method</p>
                    <p className="orderthank_next">
                      {state.paymentType ==="COD" ? "COD / COC" : state.paymentType }
                    </p>
                  </li>
                </ul>
              </div>
              <div className="mt-5">
                <h3 className="text-center">Order Details</h3>
                <div className="mt-3">
                  <table className="table table_main_bor">
                    <tbody>
                      {state.cartData.map((value,index)=>{
                        const amount =
                        value.discount_price === ""
                          ? parseFloat(value.price).toFixed(2)
                          : parseFloat(value.discount_price).toFixed(2);
                        return(
                        <tr key={index}>
                        <th>
                          <p className="order__item_pad">
                            {value.name} × {value.cartQuantity}
                          </p>
                        </th>
                        <th>
                          <p className="order__item_pad order_detail_sec_th">
                            AED {amount}
                          </p>
                        </th>
                      </tr>
                        )
                      })}
                      
                    </tbody>
                    <tfoot className="t_foo_bg">
                      <tr>
                        <th className="td_border_n">
                          <p className="order_det_amounts_D pt-2">Subtotal:</p>
                        </th>
                        <th className="td_border_n">
                          <p className="order_detail_sec_th pt-2">AED {(state.amount - parseInt(state.shipping_charges)).toFixed(2)}</p>
                        </th>
                      </tr>
                      <tr>
                        <th className="td_border_n">
                          <p className="order_det_amounts_D">Shipping:</p>
                        </th>
                        <th className="td_border_n">
                          <p className="order_detail_sec_th">
                            {currency_name} {state.shipping_charges}
                            <span className="via_del_fs">
                              via Delivery Charges per Bundle
                            </span>
                          </p>
                        </th>
                      </tr>
                      <tr>
                        <th>
                          <p className="order_det_amounts_D mb-2">
                            Payment method:
                          </p>
                        </th>
                        <th>
                          <p className="order_detail_sec_th mb-2">
                            {state.paymentType ==="COD" ? "COD / COC" : state.paymentType}
                          </p>
                        </th>
                      </tr>
                      <tr>
                        <th className="td_border_n">
                          <p className="tot_Order_head">TOTAL:</p>
                        </th>
                        <th className="td_border_n">
                          <p className="order_detail_sec_th tot_Order_head total_am_color">
                            {currency_name} {(state.amount).toFixed(2)}
                          </p>
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderReceived;









// import React from "react";

// const OrderReceived = () => {
//   return (
//     <>
//       <section>
//         <section className="container-fluid products_main_banner">
//           <div className="container">
//             <div className="banner_content">
//               <h4>Order Received</h4>
//               {/* <div>
//               <Breadcrumb>
//                 <BreadcrumbItem>
//                   <a href="#">Home</a>
//                 </BreadcrumbItem>
//                 <BreadcrumbItem>
//                   <a href="#">Shop</a>
//                 </BreadcrumbItem>
//                 <BreadcrumbItem>
//                   <a href="#">Women</a>
//                 </BreadcrumbItem>
//                 <BreadcrumbItem active>Women Bottoms</BreadcrumbItem>
//               </Breadcrumb>
//             </div> */}
//             </div>
//           </div>
//         </section>
//         <div className="mt-4 mb-5">
//           <div className="container">
//             <div className="main__order_rec">
//               <p className="order_thank_p">
//                 Thank you. Your order has been received.
//               </p>
//               <div>
//                 <ul className="order__ul_">
//                   <li>
//                     <p>Order Number</p>
//                     <p className="orderthank_next">8942</p>
//                   </li>
//                   <li>
//                     <p>Date</p>
//                     <p className="orderthank_next">April 7, 2023</p>
//                   </li>
//                   <li>
//                     <p>Total</p>
//                     <p className="orderthank_next">AED 340.00</p>
//                   </li>
//                   <li>
//                     <p>Payment Method</p>
//                     <p className="orderthank_next">
//                       Direct bank transfer (Credited within 2-3 Working Days)
//                     </p>
//                   </li>
//                 </ul>
//               </div>
//               <div>
//                 <h4 className="mt-4 mb-2">For Local Transfer (Within U.A.E)</h4>
//                 <div className="bank_content_">
//                   <p>
//                     <span className="fw-bold">BANK:</span> EMIRATES NBD SAMNAN
//                     BRANCH U.A.E
//                   </p>
//                   <p>
//                     <span className="fw-bold">ACCOUNT #:</span> 1014089891901
//                   </p>
//                   <p>
//                     <span className="fw-bold">IBAN #:</span>{" "}
//                     AE540260001014089891901
//                   </p>
//                   <p>
//                     <p>
//                       <span className="fw-bold">AMOUNT:</span> DHS /-
//                     </p>
//                     <span className="fw-bold">ACCOUNT NAME:</span> AL KHAT AL
//                     ABYADH GENERAL TRADING CO L.L.C
//                   </p>
//                 </div>
//                 <h4 className="mt-5 mb-2">For International Wire Transfer</h4>
//                 <div className="bank_content_">
//                   <p>
//                     <span className="fw-bold">BANK:</span> HABIB BANK AG ZURICH
//                     SHARJAH BRANCH
//                   </p>
//                   <p>
//                     <span className="fw-bold">SWIFT CODE:</span> HBZUAEADXXX
//                   </p>
//                   <p>
//                     <span className="fw-bold">IBAN #:</span> AE52 0290 8203 1133
//                     3523 521
//                   </p>
//                   <p>
//                     <span className="fw-bold">ACCOUNT NAME:</span> AL KHAT AL
//                     ABYADH GENERAL TRADING CO L.L.C
//                   </p>
//                   <p>
//                     <span className="fw-bold">Purpose of Transaction:</span>{" "}
//                     Purchase of Goods
//                   </p>
//                   <p className="fw-bold">Proof of Payment</p>
//                   <p>
//                     For us to follow up on your payment transaction, please
//                     submit a copy of the receipt to any of the following:
//                   </p>
//                   <p>
//                     <span className="fw-bold">Email:</span>{" "}
//                     sales@hajiukayukay.com
//                   </p>
//                   <p>
//                     <span className="fw-bold">WhatsApp:</span> +971 6 546 1180
//                   </p>
//                 </div>
//                 <div className="bank_note my-3">
//                   <p>
//                     *All bank charges (including Sender’s Bank Fees or Teller
//                     Fees and Corresponding Bank Fees) must be paid by the
//                     sender. The amount shall not be deducted from the
//                     beneficiary.
//                   </p>
//                   <p>
//                     *For international wire transfer often taking up to three
//                     days for the funds to become available in our bank.
//                   </p>
//                 </div>
//               </div>
//               <h2 className="mb-3 our_bank_d_head">Our bank details</h2>
//               <div>
//                 <h3 className="bank_name_head">
//                   AL KHAT AL ABYADH GENERAL TRADING CO L.L.C:
//                 </h3>
//                 <div>
//                   <ul className="order__ul_">
//                     <li>
//                       <p>Bank:</p>
//                       <p className="orderthank_next">
//                         EMIRATES NBD SAMNAN BRANCH U.A.E
//                       </p>
//                     </li>
//                     <li>
//                       <p>Account number:</p>
//                       <p className="orderthank_next">1014089891901</p>
//                     </li>
//                     <li>
//                       <p>IBAN:</p>
//                       <p className="orderthank_next">AE540260001014089891901</p>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//               <div className="mt-5">
//                 <h3 className="bank_name_head">
//                   AL KHAT AL ABYADH GENERAL TRADING CO L.L.C:
//                 </h3>
//                 <div>
//                   <ul className="order__ul_">
//                     <li>
//                       <p>Bank:</p>
//                       <p className="orderthank_next">
//                         HABIB BANK AG ZURICH SHARJAH BRANCH
//                       </p>
//                     </li>
//                     <li>
//                       <p>Account number:</p>
//                       <p className="orderthank_next">1014089891901</p>
//                     </li>
//                     <li>
//                       <p>IBAN:</p>
//                       <p className="orderthank_next">
//                         AE52 0290 8203 1133 3523 521
//                       </p>
//                     </li>
//                     <li>
//                       <p>BIC:</p>
//                       <p className="orderthank_next">HBZUAEADXXX</p>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//               <div className="mt-5">
//                 <h3 className="text-center">Order Details</h3>
//                 <div className="mt-3">
//                   <table className="table table_main_bor">
//                     <tbody>
//                       <tr>
//                         <th>
//                           <p className="order__item_pad">
//                             Ladies Night Pajama × 1
//                           </p>
//                         </th>
//                         <th>
//                           <p className="order__item_pad order_detail_sec_th">
//                             AED 325.00
//                           </p>
//                         </th>
//                       </tr>
//                     </tbody>
//                     <tfoot className="t_foo_bg">
//                       <tr>
//                         <th className="td_border_n">
//                           <p className="order_det_amounts_D pt-2">Subtotal:</p>
//                         </th>
//                         <th className="td_border_n">
//                           <p className="order_detail_sec_th pt-2">AED 325.00</p>
//                         </th>
//                       </tr>
//                       <tr>
//                         <th className="td_border_n">
//                           <p className="order_det_amounts_D">Shipping:</p>
//                         </th>
//                         <th className="td_border_n">
//                           <p className="order_detail_sec_th">
//                             AED 15.00{" "}
//                             <span className="via_del_fs">
//                               via Delivery within UAE Charges per Bundle
//                             </span>
//                           </p>
//                         </th>
//                       </tr>
//                       <tr>
//                         <th>
//                           <p className="order_det_amounts_D mb-2">
//                             Payment method:
//                           </p>
//                         </th>
//                         <th>
//                           <p className="order_detail_sec_th mb-2">
//                             Direct bank transfer (Credited within 2-3 Working
//                             Days)
//                           </p>
//                         </th>
//                       </tr>
//                       <tr>
//                         <th className="td_border_n">
//                           <p className="tot_Order_head">TOTAL:</p>
//                         </th>
//                         <th className="td_border_n">
//                           <p className="order_detail_sec_th tot_Order_head total_am_color">
//                             AED 340.00
//                           </p>
//                         </th>
//                       </tr>
//                     </tfoot>
//                   </table>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default OrderReceived;
