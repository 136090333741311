import React, { useEffect, useState } from "react";
import BlogCards from "../components/cards/BlogCards";
import FeatureCategories from "../components/cards/FeatureCategories";
import Collections from "../components/Collections";
import FeaturedItems from "../components/FeaturedItems";
import Footer from "../components/Footer";
import Recommended from "../components/Recommended";
import Slider from "../components/Slider";
import Loader from "../components/Loader";
import axios from "axios";
import { baseURL } from "../config/apiUrl";

const Home = () => {

  const [banner, setBanner] = useState([]);
  const [featureCatalog, setFeatureCatalog] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchBanner = async () => {
    setLoading(true)
    try {
      const response = await axios.get(baseURL + "/allbanners");
      const { data } = response.data;
      setBanner(data);
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  };

  const fetchFeatureCat = async () => {
    setLoading(true)
    try {
      const response = await axios.get(baseURL + "/featuredcat");
      const { data } = response.data;
      setFeatureCatalog(data);
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchFeatureCat();
    fetchBanner();
  }, []);

  if(loading) return <Loader/>;

  return (
    <>
      <Slider banner={banner} />
      <FeatureCategories featureCatalog={featureCatalog} />
      <Recommended />
      <Collections />
      <FeaturedItems />
      <section>
        <div className="container my-4">
          <div className="row text-center">
            <h3 className="pb-3 home_heading">
              What makes second-hand used clothing a profitable business?
            </h3>
            <p>
              The second-hand used clothing industry is a large and profitable.
              It may surprise you that consumers buy used for a variety of
              reasons. Some have no means to purchase new clothing every time or
              want to save money by buying only second-hand clothing. This
              business can make money. It is simple to start and easy to run.
              Second-hand clothing is one of those businesses that can be
              started with little investment and still earn high profits. But
              it’s not just about buying low and selling high; you also have to
              consider selling the item as fast as possible so you can go on to
              purchase more. The profit depends on various factors such as the
              store’s location and marketing strategy – today’s era is all about
              technology, and many business owners divert it through online
              marketing and the quality of the item you are selling. However,
              once you start making sales and familiarizing yourself with the
              market, it will be easy to move through these initial challenges
              as long as you have enough capital to keep running your business
              smoothly until it becomes successful. Another way to increase
              profit is finding suppliers willing to give better deals because
              they have more volume than other suppliers.Haji Ukay Ukay is one
              of the oldest suppliers of second-hand used clothing in the UAE
              that provides a wide variety of clothing. So, if you have
              considered starting this business, we encourage you to start
              today!
            </p>
          </div>
        </div>
      </section>
      <BlogCards />
      <Footer />
      <div className="chat_super_main">
            

            <input className="chat-menu hidden" id="offchat-menu" type="checkbox" />
            <div className="sticky-button" id="sticky-button">
                <label for="offchat-menu">
                    <p className="chat-icon" viewBox="0 0 24 24">
                    <i className="fa-solid fa-message"></i>
                    </p>
                    <p className="close-icon" viewBox="0 0 24 24">
                        <i className="fa-solid fa-xmark"></i>
                        </p>

                </label>
            </div>
            <div className="sticky-chat">
                <div className="chat-content">
                    <div className="whatsapp_div">
                        <i className="fa-brands fa-whatsapp"></i>
                    </div>
                    <div className="messanger_div">
                        <i className="fa-brands fa-facebook-messenger"></i>
                    </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default Home;
