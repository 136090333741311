import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import authReducer from '../features/auth/authSlice.js';
import storage from "redux-persist/lib/storage";
import cartReducer from "../features/slices/cartSlice";
import currencyReducer from "../features/currency/currencySlice.js";
import compareReducer from "../features/compare/compareProductSlice.js";
import wishlistReducer from "../features/wishlist/wishlistSlice.js";
import quickViewReducer from "../features/detailView/detailViewSlice.js";
import categoryReducer from "../features/categories/categoriesSlice.js";

const persistConfig = {
  key: "root",
  // version: 1,
  storage,
};

const reducer = combineReducers({
    auth: authReducer,
    cart: cartReducer,
    currency: currencyReducer,
    compareProduct: compareReducer,
    wishlist: wishlistReducer,
    quickView: quickViewReducer,
    category: categoryReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export default store;



// import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';

// export const store = configureStore({
//   reducer: {
//     counter: counterReducer,
//   },
// });











// import {
//   combineReducers,
//   configureStore,
//   getDefaultMiddleware,
// } from '@reduxjs/toolkit';
// import {
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from 'redux-persist';
// import authReducer from '../features/auth/authSlice';
// import storage from "redux-persist/lib/storage";


// const persistConfig = {
//   key: "root",
//   // version: 1,
//   storage,
// };

// const reducer = combineReducers({
//     auth: authReducer,
// });

// const persistedReducer = persistReducer(persistConfig, reducer);

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: getDefaultMiddleware({
//     serializableCheck: {
//       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//     },
//   }),
// });

// export default store;