import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link,  useLocation } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import { baseURL } from "../config/apiUrl";
import { useSelector } from "react-redux";
import LeftSideCategory from "../components/LeftSideCategory";
import ProductCard from "../components/cards/ProductCard";
import ProductLoader from "../components/ProductLoader";
import FilterSliderMob from "../components/FilterSliderMob";

const Product = (props) => {
  const { currency_id } = useSelector((state) => state.currency);
  const { categories } = useSelector((state) => state.category);

  const [filDisplay, setfilDisplay] = useState("block");
  const [rowStyle, setrowStyle] = useState("9");
  const [filterIcon, setfilterIcon] = useState("minus");
  // const [open, setOpen] = useState("1");
  const [loading, setLoading] = useState(false);

  const [mobNavShow, setmobNavShow] = useState("sidebar__act__navMob");
  const [closebtnmobNav, setclosebtnmobNav] = useState("close_btn_MobNav_tra");
  const [overlayS, setoverlayS] = useState("overlay_slider_a");

  const { state } = useLocation();
  const [products, setProducts] = useState([]);

  const mobnav_handler = () => {
    if (mobNavShow === "sidebar__act__navMob") {
      setmobNavShow("sidebar__navMob");
      setclosebtnmobNav("close_btn_MobNav");
      setoverlayS("overlay_slider");
    } else {
      setmobNavShow("sidebar__act__navMob");
      setclosebtnmobNav("close_btn_MobNav_tra");
      setoverlayS("overlay_slider_a");
    }
  };

  // const toggle = (id) => {
  //   if (open === id) {
  //     setOpen();
  //   } else {
  //     setOpen(id);
  //   }
  // };

  const filt = () => {
    if (filDisplay === "none") {
      setfilDisplay("block");
      setrowStyle("9");
      setfilterIcon("minus");
    } else {
      setfilDisplay("none");
      setrowStyle("12");
      setfilterIcon("plus");
    }
  };

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        baseURL + `/getProductsByCatId/${state.id}/${currency_id}`
      );
      const { data } = response.data;
      setProducts(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchProducts();
  }, [state, currency_id]);


  return (
    <>
      <div>
        <section className="container-fluid products_main_banner">
          <div className="container">
            <div className="banner_content">
              <h4 className="text-capitalize">{state && state.name}</h4>
              <div>
                <Breadcrumb>
                <BreadcrumbItem>
                    <Link to="/">Home</Link>
                  </BreadcrumbItem>
                  {/* <BreadcrumbItem active>Men</BreadcrumbItem> */}
                </Breadcrumb>
              </div>
            </div>
          </div>
        </section>
        <section className="container mt-3">
          <div className="row align-items-center">
            <div className="col-md-4">
              <div>
              <span onClick={mobnav_handler} className="filters_mob">
              <i className="fa-solid fa-filter"></i> &nbsp;Filters 
                </span>
                <span onClick={filt} className="filters">
                  Filters &nbsp;<i className={`fa-solid fa-${filterIcon}`}></i>
                </span>
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="sorting__filters">
                <p>Sort by: </p>
                <select
                  style={{ border: "none" }}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Default sorting</option>
                  <option value={1}>Popularity</option>
                  <option value={2}>Average rating</option>
                  <option value={3}>Price: Ascending</option>
                  <option value={4}>Price: Decrease</option>
                </select>
              </div>
            </div> */}
          </div>
        </section>
        <section className="container mt-3">
          <div>
            <div className="row">
              <LeftSideCategory filDisplay={filDisplay} catalog={categories} />

              <div className={`col-md-${rowStyle}`}>
                <div className="row hgh">
                  {loading && <ProductLoader />}
                  {!loading && products.length === 0 && (
                    <div className="text-center mt-5">
                      <i className="fa-solid fa-bag-shopping no_cart_bag" />
                      <p className="no_pro__p my-3">
                        No products were found matching your selection.
                      </p>
                    </div>
                  )}
                  <ProductCard products={products} />
                </div>
              </div>
            </div>
          </div>
        </section>
                    {/* -------Mob-Nav--Start---- */}
                    <FilterSliderMob
        mobNavShow={mobNavShow}
        mobnav_handler={mobnav_handler}
        closebtnmobNav={closebtnmobNav}
        overlayS={overlayS}
        catalog={categories}
      />
      {/* -------Mob-Nav--end---- */}
      </div>
    </>
  );
};

export default Product;
