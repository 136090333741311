import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  detailcartItems: [],
};

const detailViewSlice = createSlice({
  name: "quickView",
  initialState,
  reducers: {
    addToView(state, action) {
      const existingIndex = state.detailcartItems.findIndex(
        (item) => item.id === action.payload.id
      );

      if (existingIndex >= 0) {
        state.detailcartItems[existingIndex] = {
          ...state.detailcartItems[existingIndex],
        };
      } else {
        let tempProductItem = {
          ...action.payload,
        };
        state.detailcartItems = []; 
        state.detailcartItems.push(tempProductItem);
      }
    },
  },
});

export const { addToView } = detailViewSlice.actions;

export default detailViewSlice.reducer;
