import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import Bori from "../assets/images/bori.jpg";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, getTotals } from "../redux/features/slices/cartSlice";
import { removeFromCompareCart } from "../redux/features/compare/compareProductSlice";
import { imageUrl } from "../config/apiUrl";

const Compare = () => {
  const { compareItems } = useSelector((state) => state.compareProduct);
  const { currency_name } = useSelector((state) => state.currency);

  const dispatch = useDispatch();
  const handleRemoveFromCart = (product) => {
    dispatch(removeFromCompareCart(product));
  };
  let handleAddToCart = (product) => {
    let data = {
      ...product,
    };
    dispatch(addToCart(data));
    dispatch(getTotals());
  };
  return (
    <>
      <section className="container-fluid products_main_banner">
        <div className="container">
          <div className="banner_content">
            <h4>Compare</h4>
            <div>
              <Breadcrumb>
                <BreadcrumbItem active>Home</BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-4">
          <div className="compare_main">
            {compareItems.length>0?
            <div className="table-responsive">
                          <table className="table table-bordered">
              <thead>
                <tr className="img_tr">
                  <th scope="col">Product</th>
                  {compareItems.map((value, index) => {
                    return (
                      <td key={index}>
                        <div className="text-center p-2">
                          <img
                            className="mb-2"
                            height={154}
                            width={220}
                            // src={Bori}
                            src={imageUrl + `/${value.image[0].image}`}
                            alt=""
                            style={{ objectFit: "cover" }}
                          />
                          <h5 className="prodUct_Name_h5">{value.name}</h5>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="col">Price</th>
                  {compareItems.map((value, index) => {
                    const amount =
                      value.discount_price === ""
                        ? parseInt(value.price).toFixed(2)
                        : parseInt(value.discount_price).toFixed(2);
                    return (
                      <td className="product_price" key={index}>
                        <span>
                          {currency_name} {amount}
                        </span>
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th scope="col">Add to cart</th>
                  {compareItems.map((value, index) => (
                    <td className="add_to_card_td" key={index}>
                      <button className="add_toCArt_btn" onClick={() => handleAddToCart(value)}>ADD TO CART</button>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th scope="col">Description</th>
                  {compareItems.map((value, index) => (
                    <td className="descrip_td" key={index}>
                      <div className="descrip_line_h">
                        <p>
                          <span className="fw-bold">QUALITY: </span>
                          {value.quality}
                        </p>
                        <p>
                          <span className="fw-bold">WEIGHT: </span>
                          {value.weight}
                        </p>
                        <p>
                          <span className="fw-bold">SIZE: </span>
                          {value.size}
                        </p>
                        <p>
                          <span className="fw-bold">STICKER NAME: </span>
                          {value.sticker_name}
                        </p>
                        <p className="mt-2">
                          APPROX {value.pieces} PCS INSIDE THE BUNDLE
                        </p>
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th scope="col">Sku</th>
                  {compareItems.map((value, index) => (
                    <td className="sku" key={index}>
                      <span>{value.sku}</span>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th scope="col">Availability</th>
                  {compareItems.map((value, index) => (
                    <td className="availability_td" key={index}>
                      <span>{value.stock} in stock</span>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th scope="col">Weight</th>
                  {compareItems.map((value, index) => (
                    <td className="sku" key={index}>
                      <span>{value.weight}</span>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th scope="col">Dimensions</th>
                  {compareItems.map((value, index) => (
                    <td className="sku" key={index}>
                      <span>N/A</span>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th scope="col">Price</th>
                  {compareItems.map((value, index) => {
                    const amount =
                      value.discount_price === ""
                        ? parseInt(value.price).toFixed(2)
                        : parseInt(value.discount_price).toFixed(2);
                    return (
                      <td className="product_price" key={index}>
                        <span>
                          {currency_name} {amount}
                        </span>
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th scope="col"></th>
                  {compareItems.map((value, index) => (
                    <td className="remove_td" key={index}>
                      <span className="remove_btn"  onClick={() => handleRemoveFromCart(value)}>Remove</span>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
            </div>

            :
            "No product added to compare !"
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default Compare;
