import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {

  const handleClick = (event, pageNumber) => {
    event.preventDefault();
    onPageChange(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink href="#" onClick={(event) => handleClick(event, i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return pageNumbers;
  };

  return (
    <Pagination aria-label="Page navigation example">
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink first href="#" onClick={(event) => handleClick(event, 1)} />
      </PaginationItem>
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink previous href="#" onClick={(event) => handleClick(event, currentPage - 1)} />
      </PaginationItem>
      {renderPageNumbers()}
      <PaginationItem disabled={currentPage === totalPages}>
        <PaginationLink next href="#" onClick={(event) => handleClick(event, currentPage + 1)} />
      </PaginationItem>
      <PaginationItem disabled={currentPage === totalPages}>
        <PaginationLink last href="#" onClick={(event) => handleClick(event, totalPages)} />
      </PaginationItem>
    </Pagination>
  );
};

export default CustomPagination;
