import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseURL } from "../config/apiUrl";
import MinimumDistanceSlider from "../components/MinimumDistanceSlider";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "./LoginModal";
import { logout } from "../redux/features/auth/authSlice";

const MobNavSlider = ({
  mobNavShow,
  mobnav_handler,
  closebtnmobNav,
  overlayS,
  catalog
}) => {

  // const [catalog, setCatalog] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  // const fetchCategories = async () => {
  //   try {
  //     const response = await axios.get(baseURL + "/allCategories");
  //     const { data } = response.data;
  //     setCatalog(data);
  //   } catch (error) {}
  // };
  // useEffect(() => {
  //   fetchCategories();
  // }, []);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
    mobnav_handler()
  };

  return (
    <>
      <section>
        <div className={mobNavShow}>
          <div className="">
            <div className={closebtnmobNav} onClick={mobnav_handler}>
              <i className="fa-solid fa-chevron-left"></i>
            </div>
            <h5 className="mt-4 pb-4 mob_naV_linK"><i className="fa-solid fa-bars me-2"></i>NAVIGATION</h5>
            <div className="filterMain_height">
              <div>
                <div className="categories__Main">
                  <ul className="ul_style">
                    <NavLink to="/">
                      <li  onClick={mobnav_handler}>
                        <p className="d-flex justify-content-between mb-2 mob_naV_linK">
                          <span className="active_link">Home</span>
                        </p>
                      </li>
                    </NavLink>

                    {catalog &&
                      catalog.map((value, index) => {
                        const { sub_categories } = value;
                        const main_cat_name = value.slug;
                        if (sub_categories.length === 0) {
                          return (
                            <li key={index}  onClick={mobnav_handler}>
                              <NavLink
                                to={`/product-category/${main_cat_name}`}
                                state={{
                                  id: value.id,
                                  name: value.name,
                                }}
                              >
                                <p className="d-flex justify-content-between mb-2 ">
                                  <span>{value.name}</span>
                                </p>
                              </NavLink>
                            </li>
                          );
                        } else {
                          // if there are sub-categories, render the collapse element and sub-categories
                          return (
                            <li key={index}>
                              <p
                                className="collapsed d-flex justify-content-between mb-2 mob_naV_linK"
                                data-bs-toggle="collapse"
                                data-bs-target={`#${main_cat_name}`}
                                aria-expanded="false"
                                aria-controls={main_cat_name}
                              >
                                <span>{value.name}</span>
                                <span>
                                  <i className="fa-solid fa-plus"></i>
                                </span>
                              </p>
                              <div>
                                {sub_categories.map((val, ind) => {
                                  const { categories } = val;
                                  return (
                                    <div key={ind}>
                                      {categories.map((val, ind) => (
                                        <div
                                          id={main_cat_name}
                                          className="accordion-collapse collapse"
                                          aria-labelledby="panelsStayOpen-heading4"
                                          key={ind}
                                        >
                                          <ul>
                                            <NavLink
                                              to={`/product-category/${main_cat_name}/${val.slug}`}
                                              state={{
                                                id: val.id,
                                                name: `${main_cat_name} ${val.name}`,
                                              }}
                                            >
                                              <li onClick={mobnav_handler}>{val.name}</li>
                                            </NavLink>
                                          </ul>
                                        </div>
                                      ))}
                                    </div>
                                  );
                                })}
                              </div>
                            </li>
                          );
                        }
                      })}
                    <NavLink
                      to="/product-category/house-hold"
                      onClick={mobnav_handler}
                    >
                      <li  onClick={mobnav_handler}>
                        <p className="d-flex justify-content-between mb-2 mob_naV_linK">
                          <span>House Hold</span>
                        </p>
                      </li>
                    </NavLink>
                    <NavLink to="/contact-us">
                      <li  onClick={mobnav_handler}>
                        <p className="d-flex justify-content-between mb-2 mob_naV_linK">
                          <span>Contact Us</span>
                        </p>
                      </li>
                    </NavLink>

                      {token?
                    <li className="mob_naV_linK">
                      <p
                        className="collapsed d-flex justify-content-between mb-2 "
                        data-bs-toggle="collapse"
                        data-bs-target={`#account`}
                        aria-expanded="false"
                        aria-controls="account"
                      >
                        <span>My Account</span>
                        <span>
                          <i className="fa-solid fa-plus"></i>
                        </span>
                      </p>
                      <div>
                        <div>
                          <div
                            id="account"
                            className="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-heading4"
                          >
                            <ul>
                              <NavLink to="/my-account">
                                <li  onClick={mobnav_handler}>Dashboard</li>
                              </NavLink>
                              <NavLink to="/orders">
                                <li  onClick={mobnav_handler}>Orders</li>
                              </NavLink>
                              <NavLink to="/downloads">
                                <li  onClick={mobnav_handler}>Downloads</li>
                              </NavLink>
                              <NavLink to="/addresses">
                                <li  onClick={mobnav_handler}>Addresses</li>
                              </NavLink>
                              <NavLink to="/edit-account">
                                <li  onClick={mobnav_handler}>Account details</li>
                              </NavLink>
                              <NavLink to="/reset-password">
                                <li  onClick={mobnav_handler}>Reset Password</li>
                              </NavLink>
                                
                                <li>
                                  <span onClick={handleLogout}>
                          <i className="fa-solid fa-right-from-bracket"></i>{" "}
                          Logout
                        </span></li>
                              
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                      :
                      <li className="mob_naV_linK">
                     <i className="fa-regular fa-circle-user" />
                <span data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={mobnav_handler}>
                  &nbsp; Login / Register
                </span>
                    </li>
                      }

                  </ul>
                </div>

                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div onClick={mobnav_handler} className={overlayS}></div>
      </section>
      <LoginModal />

    </>
  );
};

export default MobNavSlider;
