import React from 'react'

const Collections = () => {
  return (
    <>
      <section>
    <div className="container my-5">
      <div className="row">
        <div className="col-md-4 mb-2">
          <div className="summer_collection_wrapper">
            <div className="w-50">
              <h3>Summer Collection</h3>
              <button>SHOP NOW</button>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-2">
          <div className="summer_collection_wrapper summer_collection_wrapper_1">
            <div className="w-50">
              <h3>Winter Collection</h3>
              <button>SHOP NOW</button>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-2">
          <div className="summer_collection_wrapper summer_collection_wrapper_2">
            <div className="w-50">
              <h3>Exclusive Offer</h3>
              <button>SHOP NOW</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Collections