import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { baseURL, imageUrl } from "../config/apiUrl";
import axios from "axios";
import { checkoutSchema } from "../schemas";
import { toast } from "react-toastify";
import CheckoutNav from "../components/CheckoutNav";
// import Bori from "../assets/images/bori.jpg";

const CheckoutNew = () => {
  // const [shippingDiv, setshippingDiv] = useState(false);
  // const [coupenDiv, setcoupenDiv] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const { currency_id, currency_name } = useSelector((state) => state.currency);
  const [CurrencyOptions, setCurrencyOptions] = useState([]);
  const [bundlePrices, setBundlePrice] = useState();
  const [getState, setGetState] = useState([]);
  const { token  } = useSelector((state) => state.auth);

  const { cartItems, cartTotalQuantity, cartTotalAmount } = useSelector((state) => state.cart );
  const cart = useSelector((state) => state.cart);

  const navigate = useNavigate();

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.id);
  };
  const fetchBundlePrice = async () => {
    try {
      const response = await axios.get(
        baseURL +
          `/getunauthbundleprice/${values.country}/${values.state}/${cartTotalQuantity}`
      );
      const { data } = response.data;
      setBundlePrice(data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCurrency = async () => {
    try {
      const response = await axios.get(baseURL + "/allCurrency");
      const { data } = response.data;
      setCurrencyOptions(data);
    } catch (error) {}
  };

  const fetchState = async () => {
    try {
      const response = await axios.get(
        baseURL + `/getStatesByCurrencyId/${values.country}`
      );
      const { data } = response.data;
      setGetState(data);
    } catch (error) {
      console.log(error);
    }
  };

  const initialValues = {
    address: "",
    appartment: "",
    country: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    state: "",
    notes: "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: checkoutSchema,
    onSubmit: async (values, action) => {
      handleNext();
    },
  });

  useEffect(() => {
    if (values.state) {
      fetchBundlePrice();
    }
  }, [values.state]);

  useEffect(() => {
    if (values.country) {
      fetchState();
    }
  }, [values.country]);

  useEffect(() => {
    fetchCurrency();
  }, []);
  
  let finalAmount =  bundlePrices && bundlePrices !== {} ? 
  (parseInt(bundlePrices.price) +parseInt(cartTotalAmount)).toFixed(2):
  cartTotalAmount.toFixed(2)

  const handleDetailSubmitb = async (e) => {
    e.preventDefault()
    if(selectedOption===null){
      toast.error("Please Select Payment Method")
    }else{
    var data = new FormData();
    data.append("address", values.address);
    data.append("appartment", values.appartment);
    data.append("country", values.country);
    data.append("email", values.email);
    data.append("first_name", values.first_name);
    data.append("last_name", values.last_name);
    data.append("notes", values.notes);
    data.append("phone_number", values.phone_number);
    data.append("order_items", JSON.stringify(cart.cartItems));
    data.append("payment_type", selectedOption);
    data.append("status", selectedOption === "COD" ? "pending" : "paid");
    data.append("total_price", finalAmount);
    data.append("currency_id", currency_id);
    data.append("shipping_charges", bundlePrices && bundlePrices !== {}? bundlePrices.price : "0.00");

    var config = {
      method: "post",
      url: baseURL + "/storeOrder",
      headers: { 
        Authorization: token ? `Bearer ${token}` : '',
      },
      data: data,
    };
    setLoading(true);
    await axios(config)
      .then(function(response) {
        toast.success(response.data.message);
         const {data}= response.data
         if(selectedOption==="COD"){
          setTimeout(() => {
            navigate(`/checkout/order-received/${data}`, {
              state: 
               { orderID: data ,
                 orderData: values,
                 paymentType:selectedOption,
                 cartData:cart.cartItems,
                 amount:cartTotalAmount,
                 shipping_charges:bundlePrices && bundlePrices !== {}? bundlePrices.price : "0.00",
                },
            });
            setLoading(false);
          }, 2000);
         }else{
          const link = data.order_paypage_url ;
        window.open(link, '_blank');
          setTimeout(() => {
            navigate(`/checkout/order-received/${data.order_id}`, {
              state: 
               { orderID: data.order_id ,
                 orderData: values,
                 paymentType:selectedOption,
                 cartData:cart.cartItems,
                 amount:cartTotalAmount,
                 shipping_charges:bundlePrices && bundlePrices !== {}? bundlePrices.price : "0.00",
                },
            });
            setLoading(false);
          }, 2000);
         }

      })
      .catch(function(error) {
        toast.error(error.response.data.message);
        setLoading(false);
      });
    }
  };

  
  const renderStep1 = () => (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <div className="container check_out_left">
                <img height={60} width={85} src={Logo} alt="Haji Ukay" />
                <CheckoutNav currentStep={currentStep}/>
                <div className="billing_shipping_main">
                  <h5>Billing details</h5>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="name@example.com"
                            id="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label htmlFor="email">Email Address*</label>
                          {errors.email && touched.email ? (
                            <p className="form-error">{errors.email}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="name@example.com"
                            id="first_name"
                            name="first_name"
                            value={values.first_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label htmlFor="first_name">First Name*</label>
                          {errors.first_name && touched.first_name ? (
                            <p className="form-error">{errors.first_name}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="name@example.com"
                            id="last_name"
                            name="last_name"
                            value={values.last_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label htmlFor="last_name">Last Name*</label>
                          {errors.last_name && touched.last_name ? (
                            <p className="form-error">{errors.last_name}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="phone"
                            id="phone_number"
                            name="phone_number"
                            value={values.phone_number}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label htmlFor="phone_number">Phone*</label>
                          {errors.phone_number && touched.phone_number ? (
                            <p className="form-error">{errors.phone_number}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-floating mb-3">
                          <select
                            className="form-select"
                            aria-label="Floating label select example"
                            id="country"
                            name="country"
                            value={values.country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value={0} selected>
                              Choose Country
                            </option>
                            {CurrencyOptions.map((val) => (
                              <option key={val.id} value={val.id}>{val.country}</option>
                            ))}
                            {/* <option value="1">Bahrain</option>
                            <option value="2">Oman</option>
                            <option value="3">Philipines</option> */}
                          </select>
                          <label htmlFor="country">Country/Region*</label>
                          {errors.country && touched.country ? (
                            <p className="form-error">{errors.country}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            id="address"
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label htmlFor="address">Street Address*</label>
                          {errors.address && touched.address ? (
                            <p className="form-error">{errors.address}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="appartment"
                            id="appartment"
                            name="appartment"
                            value={values.appartment}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <label htmlFor="appartment">
                            Apartment, suite, unit, etc. (optional)*
                          </label>
                          {errors.appartment && touched.appartment ? (
                            <p className="form-error">{errors.appartment}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-floating mb-3">
                          <select
                            className="form-select"
                            aria-label="Floating label select example"
                            id="state"
                            name="state"
                            value={values.state}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <option value={0} selected>
                              choose state
                            </option>
                            {getState.map((val) => (
                              <option key={val.id} value={val.id}>{val.name}</option>
                            ))}
                            {/* <option selected>Dubai</option>
                            <option value="1">Dubai</option>
                            <option value="2">Sharjah</option>
                            <option value="3">Dubai</option> */}
                          </select>
                          <label htmlFor="state">State / County</label>
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                        <div
                          className="mb-3 form-check"
                          style={{ display: "inline-block" }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                            onChange={() => setshippingDiv(!shippingDiv)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck1"
                          >
                            Ship to a different address?
                          </label>
                        </div>
                      </div> */}
                      {/* <button type="submit">submit</button> */}
                    </div>
                    {/* <div className={shippingDiv ? "d-block" : "d-none"}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="firstName"
                              placeholder="name@example.com"
                            />
                            <label for="firstName">First Name*</label>
                            {errors.email && touched.email ? (
                              <p className="form-error">{errors.email}</p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="lastName"
                              placeholder="name@example.com"
                            />
                            <label for="lastName">Last Name*</label>
                            {errors.email && touched.email ? (
                              <p className="form-error">{errors.email}</p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="phone"
                              placeholder="phone"
                            />
                            <label htmlFor="phone">Phone*</label>
                            {errors.email && touched.email ? (
                              <p className="form-error">{errors.email}</p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-floating mb-3">
                            <select
                              className="form-select"
                              id="floatingSelect"
                              aria-label="Floating label select example"
                            >
                              <option selected>United Arab Emirates</option>
                              <option value="1">Bahrain</option>
                              <option value="2">Oman</option>
                              <option value="3">Philipines</option>
                            </select>
                            <label for="floatingSelect">Country/Region*</label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              placeholder="Address"
                            />
                            <label for="address">Street Address*</label>
                            {errors.email && touched.email ? (
                              <p className="form-error">{errors.email}</p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="apartment"
                              placeholder="Apartment"
                            />
                            <label htmlFor="apartment">
                              Apartment, suite, unit, etc. (optional)*
                            </label>
                            {errors.email && touched.email ? (
                              <p className="form-error">{errors.email}</p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control"
                              id="town"
                              placeholder="town"
                            />
                            <label for="town">Town / City *</label>
                            {errors.email && touched.email ? (
                              <p className="form-error">{errors.email}</p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-floating mb-3">
                            <select
                              className="form-select"
                              id="state"
                              aria-label="Floating label select example"
                            >
                              <option selected>Dubai</option>
                              <option value="1">Dubai</option>
                              <option value="2">Sharjah</option>
                              <option value="3">Dubai</option>
                            </select>
                            <label htmlFor="state">State / County</label>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-md-12">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="return__to__p">
                          <i className="fa-solid fa-chevron-left return__to__"></i>{" "}
                          RETURN TO CART
                        </p>
                        <button className="ContinueToShipping" type="submit">
                          CONTINUE TO SHIPPING
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-5 p-0">
              <div className="check_out_right">
                {cartItems.length === 0 && <h5>No Items</h5>}
                {cartItems.map((value, index) => {
                  const amount =
                    value.discount_price === ""
                      ? parseFloat(value.price).toFixed(2)
                      : parseFloat(value.discount_price).toFixed(2);
                  return (
                    <div
                      className="d-flex align-items-center justify-content-between checoutCart__item"
                      key={index}
                    >
                      <div className="d-flex align-items-center position-relative">
                        <img
                          className="checoutCart__img"
                          src={imageUrl + `/${value.image[0].image}`}
                          // src={Bori}
                          alt="Product"
                        />
                        <div className="item_quantity__check">
                          {value.cartQuantity}
                        </div>
                        <p className="ms-2 fw-bold">{value.name}</p>
                      </div>
                      <div>
                        <p className="fw-bold">
                          {currency_name} {amount}
                        </p>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="checoutCart__coupen">
                  <p
                    className="mb-2 coupon_txt"
                    onClick={() => setcoupenDiv(!coupenDiv)}
                  >
                    Have a coupon code?
                  </p>
                  <div
                    className={`coupen__app_main ${
                      coupenDiv ? "d-flex" : "d-none"
                    }`}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Coupen Code"
                    />
                    <button className="coupen_apply_btn">APPLY</button>
                  </div>
                </div> */}
                <div className="cart_subtotal_main">
                  <table className="table cart_subtotal">
                    <tr>
                      <th>Subtotal</th>
                      <td>
                        <span className="fw-bold ">
                          {currency_name} {cartTotalAmount.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Shipping</th>
                      <td>
                        <p>
                          {/* Delivery within UAE <br /> */}
                          {/* Charges per Bundle: */}
                          Bundle Rate:
                          <br />
                          <span className="fw-bold">
                            {currency_name}{" "}
                            {bundlePrices && bundlePrices !== {}
                              ? bundlePrices.price
                              : "0.00"}
                          </span>
                        </p>
                      </td>
                    </tr>
                    {/* <tr>
                      <th>
                        <p className="p-0 working__">
                          UAE ExpectedDelivery 1 – 3 Working Days, (Local
                          Delivery Charges)
                        </p>
                      </th>
                      <td>
                        <span className="fw-bold">AED 0.00</span>
                      </td>
                    </tr> */}
                  </table>
                </div>
                <div className="total__main">
                  <p>TOTAL INCL. VAT</p>
                  <p>
                    {currency_name} {bundlePrices && bundlePrices !== {} ? 
                    (parseInt(bundlePrices.price) +parseInt(cartTotalAmount)).toFixed(2):
                    cartTotalAmount.toFixed(2)
                    } 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

  const renderStep2 = () => (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <div className="container check_out_left">
                <img height={60} width={85} src={Logo} alt="Haji Ukay" />
                <CheckoutNav currentStep={currentStep} handleBack={handleBack}/>

                <div>
                  <div className="contact_super">
                    <div className="d-flex justify-content-between flex-wrap contact__maiN">
                      <div>
                        <span className="pe-4">Contact</span>
                        <span className="fw-bold">{values.email}</span>
                      </div>
                      <div className="change__btn" onClick={handleBack}>
                        Change
                      </div>
                    </div>
                    <div className="d-flex justify-content-between flex-wrap contact__maiN">
                      <div>
                        <span className="pe-4">Ship to</span>
                        <span className="fw-bold">
                          {values.address}, {values.appartment}
                          {/* {values.country} */}
                        </span>
                      </div>
                      <div className="change__btn" onClick={handleBack}>
                        Change
                      </div>
                    </div>
                    {/* <div className="d-flex justify-content-between contact__maiN contact__maiN_bb">
                      <div>
                        <span className="pe-4">Method</span>
                        <span className="fw-bold">Delivery within UAE Charges per Bundle: AED 15.00</span>
                      </div>
                      <div className="change__btn" onClick={handleBack}>Change</div>
                    </div> */}
                  </div>
                  <div>
                    <h5 className="pt-4">Shipping Methods</h5>
                    <div className="contact_super">
                      <div className="d-flex justify-content-between flex-wrap contact__maiN">
                        <div className="form-check">
                          {/* <input className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="delivery"
                          value="delivery"
                          checked={selectedOption === "delivery"}
                          onChange={handleOptionChange}
                          /> */}
                          <label
                            className="form-check-label"
                            htmlFor="delivery"
                          >
                            {/* Delivery within UAE Charges per Bundle: AED 15.00 */}
                            Charges: {currency_name}{" "}
                            {bundlePrices && bundlePrices !== {}
                              ? bundlePrices.price
                              : "0.00"}
                          </label>
                        </div>
                      </div>
                      {/* <div className="d-flex justify-content-between contact__maiN contact__maiN_bb">
                        <div className="form-check">
                          <input className="form-check-input"
                           type="radio" 
                           name="flexRadioDefault" 
                           id="collect"
                           value="collect"
                          checked={selectedOption === "collect"}
                          onChange={handleOptionChange}
                           />
                          <label className="form-check-label" htmlFor="collect">
                            Click & Collect from Warehouse
                          </label>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="form-label"
                      >
                        Order notes (optional)
                      </label>
                      <textarea
                        className="form-control"
                        id="notes"
                        name="notes"
                        value={values.notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        rows={3}
                        defaultValue={""}
                        placeholder="Notes about your order, e.g. special notes for delivery."
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="return__to__p" onClick={handleBack}>
                      <i className="fa-solid fa-chevron-left return__to__"></i>{" "}
                      RETURN TO INFORMATION
                    </p>
                    <button className="ContinueToShipping" onClick={handleNext}>
                      CONTINUE TO PAYMENT
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 p-0">
              <div className="check_out_right">
                {cartItems.length === 0 && <h5>No Items</h5>}
                {cartItems.map((value, index) => {
                  const amount =
                    value.discount_price === ""
                      ? parseFloat(value.price).toFixed(2)
                      : parseFloat(value.discount_price).toFixed(2);
                  return (
                    <div
                      className="d-flex align-items-center justify-content-between checoutCart__item"
                      key={index}
                    >
                      <div className="d-flex align-items-center position-relative">
                        <img
                          className="checoutCart__img"
                          src={imageUrl + `/${value.image[0].image}`}
                          // src={Bori}
                          alt="Product"
                        />
                        <div className="item_quantity__check">
                          {value.cartQuantity}
                        </div>
                        <p className="ms-2 fw-bold">{value.name}</p>
                      </div>
                      <div>
                        <p className="fw-bold">
                          {currency_name} {amount}
                        </p>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="checoutCart__coupen">
                  <p
                    className="mb-2 coupon_txt"
                    onClick={() => setcoupenDiv(!coupenDiv)}
                  >
                    Have a coupon code?
                  </p>
                  <div
                    className={`coupen__app_main ${
                      coupenDiv ? "d-flex" : "d-none"
                    }`}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Coupen Code"
                    />
                    <button className="coupen_apply_btn">APPLY</button>
                  </div>
                </div> */}
                <div className="cart_subtotal_main">
                  <table className="table cart_subtotal">
                    <tr>
                      <th>Subtotal</th>
                      <td>
                        <span className="fw-bold ">
                          {currency_name} {cartTotalAmount.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Shipping</th>
                      <td>
                        <p>
                          {/* Delivery within UAE <br /> */}
                          {/* Charges per Bundle: */}
                          Bundle Rate:
                          <br />
                          <span className="fw-bold">
                            {currency_name}{" "}
                            {bundlePrices && bundlePrices !== {}
                              ? bundlePrices.price
                              : "0.00"}
                          </span>
                        </p>
                      </td>
                    </tr>
                    {/* <tr>
                      <th>
                        <p className="p-0 working__">
                          UAE ExpectedDelivery 1 – 3 Working Days, (Local
                          Delivery Charges)
                        </p>
                      </th>
                      <td>
                        <span className="fw-bold">AED 0.00</span>
                      </td>
                    </tr> */}
                  </table>
                </div>
                <div className="total__main">
                  <p>TOTAL INCL. VAT</p>
                  <p>
                  {currency_name} {bundlePrices && bundlePrices !== {} ? 
                    (parseInt(bundlePrices.price) +parseInt(cartTotalAmount)).toFixed(2):
                    cartTotalAmount.toFixed(2)
                    } 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

  const renderStep3 = () => (
    <>
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <div className="container check_out_left">
                <img height={60} width={85} src={Logo} alt="Haji Ukay" />
                <CheckoutNav currentStep={currentStep} handleBack={handleBack}/>
                <div>
                  <div className="contact_super">
                    <div className="d-flex justify-content-between flex-wrap contact__maiN">
                      <div>
                        <span className="pe-4">Contact</span>
                        <span className="fw-bold">{values.email}</span>
                      </div>
                      {/* <div className="change__btn">Change</div> */}
                    </div>
                    <div className="d-flex justify-content-between flex-wrap contact__maiN">
                      <div>
                        <span className="pe-4">Ship to</span>
                        <span className="fw-bold">{values.address}, {values.appartment}
                          {/* {values.country} */}</span>
                      </div>
                      {/* <div className="change__btn">Change</div> */}
                    </div>
                    {/* <div className="d-flex justify-content-between contact__maiN contact__maiN_bb">
                      <div>
                        <span className="pe-4">Method</span>
                        <span className="fw-bold">
                          Delivery within UAE Charges per Bundle: AED 15.00
                        </span>
                      </div>
                      <div className="change__btn">Change</div>
                    </div> */}
                  </div>
                  <div>
                    <h5 className="pt-4">Payment Methods</h5>
                    <p className="mb-3">
                      All transactions are secure and encrypted.
                    </p>
                    <div className="contact_super" id="accordionFlushExample">
                      {/* <div> */}
                      <div className="contact__maiN">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="COD"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="COD"
                              onChange={handleOptionChange}
                              checked={selectedOption === "COD"}
                            />
                            COD / COC
                          </label>
                        </div>
                        <div
                          id="flush-collapseOne"
                          className="payment_box accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          Pay with cash upon delivery.
                        </div>
                      </div>
                      {/* <div className="contact__maiN">
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="collect"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="collect"
                              onChange={handleOptionChange}
                              checked={selectedOption === "collect"}
                            />
                            Direct bank transfer (Credited within 2-3 Working
                            Days)
                          </label>
                        </div>
                        <div
                          id="flush-collapseTwo"
                          className="payment_box accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          Make your payment directly into our bank account.
                          Please use your Order ID as the payment reference.
                          Your order will not be shipped until the funds have
                          cleared in our account.
                        </div>
                      </div> */}
                      {/* </div> */}
                      <div className="contact__maiN contact__maiN_bb">
                        <div className="form-check">
                        
                          <label
                            className="form-check-label"
                            htmlFor="payment"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="payment"
                              onChange={handleOptionChange}
                              checked={selectedOption === "payment"}
                            />
                            CREDIT/DEBIT CARD
                          </label>
                        </div>
                        <div
                          id="flush-collapseThree"
                          className="payment_box accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          You will be redirected to payment gateway.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="privacy_policy_sub">
                      <p>
                        Your personal data will be used to process your order,
                        support your experience throughout this website, and for
                        other purposes described in our{" "}
                        <Link to="/privacy-policy">
                        <span className="terms__con">privacy policy.</span>
                        </Link>
                      </p>
                    </div>
                    <form onSubmit={handleDetailSubmitb}>
                    <div className="term_sub">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                          required
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleCheck1"
                        >
                          <span className="i_have">
                            I have read and agree to the website
                          </span>{" "}
                          <Link to="/term-condition">
                          <span className="terms__con">
                            terms and conditions
                          </span>
                          </Link>
                        </label>
                      </div>
                    </div>
                    <div>
                    <button
                        className="place_order"
                        // onClick={() => handleDetailSubmitb()}
                        disabled={loading ? true : false}
                      >
                        {loading ? (
                          <div
                            className="spinner-border text-secondary"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Place Order"
                        )}
                      </button>
                      {/* <button
                        className="place_order"
                        onClick={() => handleDetailSubmitb()}
                      >
                        Place Order
                      </button> */}
                    </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 p-0">
            <div className="check_out_right">
                {cartItems.length === 0 && <h5>No Items</h5>}
                {cartItems.map((value, index) => {
                  const amount =
                    value.discount_price === ""
                      ? parseFloat(value.price).toFixed(2)
                      : parseFloat(value.discount_price).toFixed(2);
                  return (
                    <div
                      className="d-flex align-items-center justify-content-between checoutCart__item"
                      key={index}
                    >
                      <div className="d-flex align-items-center position-relative">
                        <img
                          className="checoutCart__img"
                          src={imageUrl + `/${value.image[0].image}`}
                          // src={Bori}
                          alt="Product"
                        />
                        <div className="item_quantity__check">
                          {value.cartQuantity}
                        </div>
                        <p className="ms-2 fw-bold">{value.name}</p>
                      </div>
                      <div>
                        <p className="fw-bold">
                          {currency_name} {amount}
                        </p>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="checoutCart__coupen">
                  <p
                    className="mb-2 coupon_txt"
                    onClick={() => setcoupenDiv(!coupenDiv)}
                  >
                    Have a coupon code?
                  </p>
                  <div
                    className={`coupen__app_main ${
                      coupenDiv ? "d-flex" : "d-none"
                    }`}
                  >
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Coupen Code"
                    />
                    <button className="coupen_apply_btn">APPLY</button>
                  </div>
                </div> */}
                <div className="cart_subtotal_main">
                  <table className="table cart_subtotal">
                    <tr>
                      <th>Subtotal</th>
                      <td>
                        <span className="fw-bold ">
                          {currency_name} {cartTotalAmount.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Shipping</th>
                      <td>
                        <p>
                          {/* Delivery within UAE <br /> */}
                          {/* Charges per Bundle: */}
                          Bundle Rate:
                          <br />
                          <span className="fw-bold">
                            {currency_name}{" "}
                            {bundlePrices && bundlePrices !== {}
                              ? bundlePrices.price
                              : "0.00"}
                          </span>
                        </p>
                      </td>
                    </tr>
                    {/* <tr>
                      <th>
                        <p className="p-0 working__">
                          UAE ExpectedDelivery 1 – 3 Working Days, (Local
                          Delivery Charges)
                        </p>
                      </th>
                      <td>
                        <span className="fw-bold">AED 0.00</span>
                      </td>
                    </tr> */}
                  </table>
                </div>
                <div className="total__main">
                  <p>TOTAL INCL. VAT</p>
                  <p>
                  {currency_name} {bundlePrices && bundlePrices !== {} ? 
                    (parseInt(bundlePrices.price) +parseInt(cartTotalAmount)).toFixed(2):
                    cartTotalAmount.toFixed(2)
                    } 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

  return (
    <div>
      {currentStep === 1 && renderStep1()}
      {currentStep === 2 && renderStep2()}
      {currentStep === 3 && renderStep3()}
    </div>
  );
};

export default CheckoutNew;
