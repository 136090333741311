import React, { useEffect, useState } from "react";
import DashbordSidebar from "../../components/DashbordSidebar";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../config/apiUrl";

const Addresses = () => {
  const { token , user_id } = useSelector((state) => state.auth);

  const [shippingAddress, setShippingAddress] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchBilling = async () => {
    try {
      var config = {
        method: "get",
        url: baseURL + `/getbillingaddress/${user_id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);
      await axios(config).then(function(response) {
        const { data } = response.data;
        setBillingAddress(data);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };
  const fetchShipping = async () => {
    try {
      var config = {
        method: "get",
        url: baseURL + `/getshippingaddress/${user_id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);
      await axios(config).then(function(response) {
        const { data } = response.data;
        setShippingAddress(data);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchBilling();
    fetchShipping();
  }, []);
  return (
    <section>
      <section className="container-fluid products_main_banner">
        <div className="container">
          <div className="banner_content">
            <h4>Addresses</h4>
            <div>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/my-account">My account</Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-4 mb-4">
        <div className="container">
          <div className="row">
            <DashbordSidebar />
            <div className="col-md-9">
              <div>
                <p>
                  The following addresses will be used on the checkout page by
                  default.
                </p>
                <div className="row mt-4">
                  <div className="col-md-6">
                    <div>
                      <h4>Billing Address</h4>
                      {billingAddress && billingAddress.firstname === null && (
                        <div>
                          <Link to="/addresses/edit-address/billing">
                            <p className="terms__con d-inline-block my-2">
                              Add
                            </p>
                          </Link>
                          <p>You have not set up this type of address yet. </p>
                        </div>
                      )}
                      {billingAddress && billingAddress.firstname !== null && (
                        <div>
                          <Link to="/addresses/edit-address/billing">
                            <p className="terms__con d-inline-block my-2">
                              Edit
                            </p>
                          </Link>
                          <p>
                            {billingAddress.firstname} {billingAddress.lastname}
                            <br />
                            {billingAddress.street_address}
                            <br />
                            {billingAddress.apartment}
                            <br />
                            {billingAddress.state_name}
                            <br />
                            {billingAddress.country_name}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <h4>Shipping Address</h4>
                      {shippingAddress && shippingAddress.firstname === null && (
                        <div>
                          <Link to="/addresses/edit-address/shipping">
                            <p className="terms__con d-inline-block my-2">
                              Add
                            </p>
                          </Link>
                          <p>You have not set up this type of address yet. </p>
                        </div>
                      )}

                      {shippingAddress && shippingAddress.firstname !== null && (
                        <div>
                          <Link to="/addresses/edit-address/shipping">
                            <p className="terms__con d-inline-block my-2">
                              Edit
                            </p>
                          </Link>
                          <p>
                          {shippingAddress.firstname} {shippingAddress.lastname}
                            <br />
                            {shippingAddress.street_address}
                            <br />
                            {shippingAddress.apartment}
                            <br />
                            {shippingAddress.state_name}
                            <br />
                            {shippingAddress.country_name}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Addresses;
